import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Apollo, QueryRef } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import { tap } from 'rxjs/operators/tap';
import { map } from 'rxjs/operators/map';
import {
  State,
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from '@progress/kendo-data-query';

import { User, InputItem } from 'src/app/services/graphql/models';
import { GridDataResult } from '@progress/kendo-angular-grid';
import gql from 'graphql-tag';
import { update_item, add_item } from '../queries';


export const allItems = gql`
query allItems($state:State) {
    allItems(state: $state) {
        count
        rows {
          item_idx
          title
          description
          item_sell_price
          item_buy_price
          item_origin_price
          item_delivery_fee
          platform_fee
          item_profit_share_price
          total_stock
          regist_datetime
          update_datetime
          manager_user_idx
          manager_user {
            user_name
          }
          create_user_idx
          create_user {
            user_name
          }
          update_user_idx
          update_user {
            user_name
          }


          company {
            company_idx
            company_name
            company_type
          }
          delivery_company {
            company_idx
            company_name
            company_type
          }
          main_item_resource {
            item_resource_idx
            item_resource_url
            width
            height
          }
          item_option_list {
            item_option_idx
            item_option_code
            item_option_barcode
            item_option_name
            item_option_stock
            item_option_plus_sell_price
            item_option_plus_buy_price
            item_option_sales_channel_list {
              sales_channel_idx
              item_option_sales_channel_name
              item_option_sales_channel_status
              item_option_sales_channel_plus_price
              sales_channel {
                sales_channel_name

              }
            }
          }
          item_sales_channel_list{
            sales_channel_idx
            item_sales_channel_fee
            item_sales_channel_plus_price
            sales_channel{
              sales_channel_name
            }
          }
          item_company_list {
            item_company_type
            company {
              company_idx
              company_name
            }
          }
          item_resource_list {
            item_resource_link_idx
            item_resource {
              item_resource_url
              width
              height
            }
          }
          item_post_list {
            post_idx
            post {
              title
              preview_img_url
            }
          }
        }
    }
  }`;


// export const add_item = gql`
// mutation add_item($data: InputItem!){
//     add_item(data: $data){
//           item_idx
//       }
// }`;


// export const update_item = gql`
// mutation update_item($data: InputItem!){
//     update_item(data: $data) {
//         item_idx
//     }
// }`;


// export const delete_item = gql`
// mutation delete_item($item_idx:Int!){
//     delete_item(item_idx: $item_idx)
// }`;




@Injectable()
export class ItemGraphqlService extends BehaviorSubject<GridDataResult> {


  state: State;

  constructor(private apollo: Apollo) {
    super(null);
  }

  private query: QueryRef<any>;

  public allItems(state: State) {
    this.state = state;

    if (this.query) {
      this.query.refetch({ state: this.state })
      return;
    }

    this.query = this.apollo.watchQuery({
      query: allItems,
      variables: { state: this.state }
    });
    this.query
      .valueChanges
      .pipe(
        map((changes: any) => <GridDataResult>{ data: changes.data.allItems.rows, total: changes.data.allItems.count }),
      )
      .subscribe(data => super.next(data));
  }


  //no paging
  public async queryAllItems(state: State) {
    const result: any = await this.apollo.query({
      query: allItems,
      variables: { state: state }
    }).toPromise();
    return { data: result.data.allItems.rows, total: result.data.allItems.count };
  }




  public release() {
    if (this.query && this.query.valueChanges) {
      this.query.valueChanges.subscribe().unsubscribe();
    }
  }

  public async add_item(data: InputItem) {
    const result = await this.apollo.mutate({
      mutation: add_item,
      variables: { data },
      refetchQueries: [{
        query: allItems,
        variables: { state: this.state }
      }]
    }).toPromise();
    return result;
  }

  public async update_item(data: InputItem) {
    const result = await this.apollo.mutate({
      mutation: update_item,
      variables: {
        data
      },
      refetchQueries: [{
        query: allItems,
        variables: { state: this.state }
      }]
    }).toPromise();
    return result;
  }



}
