export const environment = {
  channel_code: 'fumi',
  package_app_version: require('../../package.json').build_time + '-oper',
  production: true,
  app_version: require('../../package.json').build_time + '-oper',
  api_server_url: 'https://api.fumi.co.kr',
  user_endpoint: 'https://admin.fumi.co.kr',
  fumi_user_endpoint: 'https://fumi.co.kr',
  kakao_javascript_key: '5554402ffd337790c4ecc0f6899f4b19',

  innopay: {
    mid: 'pgefluvi1m',
    payUrl: 'http://15.165.185.219:8092',
    MerchantKey: 'yLCXXx4lLqfUiOZbjcCcYJn9b/RufASwMz1swgNKWoOlPe89Dcl5ddJXYeaMkgVBftzU4Ni/LnjXGgHWjSxaGg==',
    actionUrl: 'https://pg.innopay.co.kr/pay/interfaceURL.jsp'
  },
  delivery_url: 'https://delivery.fumi.co.kr',
  firebase: {
    apiKey: 'AIzaSyDiXL8AJFpAYdCVl4TG7eyFjtC8M7GX28k',
    authDomain: 'fumi-efluvi.firebaseapp.com',
    databaseURL: 'https://fumi-efluvi.firebaseio.com',
    projectId: 'fumi-efluvi',
    storageBucket: 'fumi-efluvi.appspot.com',
    messagingSenderId: '611509259962',
    appId: '1:611509259962:web:7e8a6a8fdcea08c23a7c83',
    measurementId: 'G-V514LM9CXZ'
  }
};
