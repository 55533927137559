import { Injectable } from '@angular/core';
import { CacheService } from './cache.service';
import { RequestService } from './api/request.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private cache: CacheService,
    private service: RequestService,
  ) {}

  isLogin() {
    let token = window.localStorage.getItem('token');
    if (token) {
      return true;
    } else {
      return false;
    }
  }

  async login(token) {
    console.log('save token = ' + token);
    window.localStorage.setItem('token', token);
    // const temp = await this.user.fetch();
    // return temp;
  }

  async isAdmin() {
    if (this.isLogin()) {
      await this.fetch();
      const temp = await this.currentUser();
      if (!temp) return false;

      const role = temp.role;
      return role.includes('admin');
    } else {
      return false;
    }
  }

  async isManager() {
    if (this.isLogin()) {
      await this.fetch();
      const temp = await this.currentUser();
      if (!temp) return false;

      const role = temp.role;
      return role.includes('manager') || role.includes('admin');
    } else {
      return false;
    }
  }

  async isAgency() {
    if (this.isLogin()) {
      await this.fetch();
      const temp = await this.currentUser();
      if (!temp) return false;

      const role = temp.role;
      return (
        role.includes('manager') ||
        role.includes('admin') ||
        role.includes('agency')
      );
    } else {
      return false;
    }
  }
  async isReviewer() {
    if (this.isLogin()) {
      await this.fetch();
      const temp = await this.currentUser();
      if (!temp) return false;
      const role = temp.role;
      return role.includes('reviewer');
    } else {
      return false;
    }
  }

  async currentUser() {
    if (this.isLogin()) {
      //로컬에서 데이터 검색
      if (!this.cache.user) {
        const temp = window.localStorage.getItem('user');
        if (temp) {
          this.cache.user = JSON.parse(temp);
        }
      }
      //서버에서 패치
      if (!this.cache.user) {
        await this.fetch();
      }
      return this.cache.user;
    } else {
      return null;
    }
  }

  async fetch() {
    await this.service.user_fetch(); //
  }

  async logout() {
    // await this.localStorageService.remove('token');
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');

    this.cache.user = null;
  }
}
