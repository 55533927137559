import { Injectable } from '@angular/core';
import { CanActivate, Router, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';


@Injectable({
  providedIn: 'root'
})
export class AgencyGuard implements CanActivate, CanActivateChild  {

  constructor(public app: AppService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const redirect = state.url.replace('/', '');
    return this.canActiveCheck(redirect);
  }


  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const redirect = state.url.replace('/', '');
    return this.canActiveCheck(redirect);
  }

  async canActiveCheck(redirect) {
    if (await this.app.user.isAgency()) {
      return true;
    }
    else {
      console.log('will save redirect =' + redirect);
      this.app.cache.setRedirect(redirect);
      this.app.go('/public/request-manager');
      return false;
    }
  }
  
}
