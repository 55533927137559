import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CacheService } from '../cache.service';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  constructor(private rest: ApiService, private cache: CacheService) {}

  async kakao_for_sdk({ user_info, access_token }) {
    return await this.rest
      .post('/auth/kakao_for_sdk', {
        user_info,
        access_token
      })
      .toPromise();
  }

  async unlink_kakao() {
    return await this.rest.post('/api/user/unlink_kakao', {}).toPromise();
  }

  async user_fetch() {
    try {
      const result = await this.select_my();
      if (!result) {
        throw 'no user_data';
      }
      window.localStorage.setItem('user', JSON.stringify(result));
      this.cache.user = result;
      return this.cache.user;
    } catch (error) {
      this.cache.user = null;
      window.localStorage.removeItem('user');
      console.error(error);
      return null;
    }
  }
  //자기 정보 가져오기
  async select_my() {
    return await this.rest.post('/api/user/select_my', {}).toPromise();
  }

  async select_unknown_sales_code() {
    return await this.rest.post('/api/logistics/select_unknown_sales_code', {}).toPromise();
  }

  async select_temp_stock_list(data) {
    return await this.rest.post('/api/logistics/select_temp_stock_list', data).toPromise();
  }

  async select_temp_order_list(data) {
    return await this.rest.post('/api/logistics/select_temp_order_list', data).toPromise();
  }

  async select_main_data() {
    return await this.rest.post('/api/request/select_main_data', {}).toPromise();
  }

  async select_my_request_list(status, offset, limit) {
    return await this.rest.post('/api/request/select_my_request_list', { status: status, offset: offset, limit: limit }).toPromise();
  }

  async select_public_request_list(user_idx, offset, limit) {
    return await this.rest.post('/api/request/select_public_request_list', { user_idx: user_idx, offset: offset, limit: limit }).toPromise();
  }

  async select_online_user_meta() {
    return await this.rest.post('/api/user/select_online_user_meta', {}).toPromise();
  }

  async select_all_user(data) {
    return await this.rest.post('/api/user/select_all_user', data).toPromise();
  }

  async update_admin(is_admin, user_idx) {
    return await this.rest.post('/api/user/update_admin', { is_admin: is_admin, user_idx: user_idx }).toPromise();
  }

  async select_my_request_detail(request_idx) {
    return await this.rest.post('/api/request/select_my_request_detail', { request_idx: request_idx }).toPromise();
  }

  async select_request_public(request_idx) {
    return await this.rest.post('/api/request/select_request_public', { request_idx: request_idx }).toPromise();
  }

  async select_is_like(request_idx) {
    return await this.rest.post('/api/request/select_is_like', { request_idx: request_idx }).toPromise();
  }

  async select_admin_request_list(status, offset, limit) {
    return await this.rest.post('/api/request/select_admin_request_list', { status: status, offset: offset, limit: limit }).toPromise();
  }

  async select_psql_post(user_id, offset, limit) {
    return await this.rest.post('/api/request/select_psql_post', { user_id: user_id, offset: offset, limit: limit }).toPromise();
  }

  async select_psql_post_one(psql_post) {
    return await this.rest.post('/api/request/select_psql_post_one', { psql_post: psql_post }).toPromise();
  }

  async update_user_request(request_idx, data) {
    return await this.rest.post('/api/request/update_user_request', { request_idx: request_idx, data: data }).toPromise();
  }

  async update_user_data(user_idx, data) {
    return await this.rest.post('/api/user/update_user_data', { user_idx: user_idx, data: data }).toPromise();
  }

  async upsert_mobile_catalog(mobile_catalog_idx, data) {
    return await this.rest.post('/api/mobile-catalog/upsert_mobile_catalog', { mobile_catalog_idx: mobile_catalog_idx, data: data }).toPromise();
  }

  async select_mobile_catalog(mobile_catalog_idx) {
    return await this.rest.post('/api/mobile-catalog/select_mobile_catalog', { mobile_catalog_idx: mobile_catalog_idx }).toPromise();
  }

  async delete_mobile_catalog(mobile_catalog_idx) {
    return await this.rest.post('/api/mobile-catalog/delete_mobile_catalog', { mobile_catalog_idx: mobile_catalog_idx }).toPromise();
  }

  async select_mobile_catalog_list(offset, limit) {
    return await this.rest.post('/api/mobile-catalog/select_mobile_catalog_list', { offset: offset, limit: limit }).toPromise();
  }
  async mobile_catalog_open_list(offset, limit) {
    return await this.rest.post('/api/mobile-catalog/mobile_catalog_open_list', { offset: offset, limit: limit }).toPromise();
  }

  async delete_user_request(request_idx) {
    return await this.rest.post('/api/request/delete_user_request', { request_idx: request_idx }).toPromise();
  }
  async update_admin_request(request_idx, data) {
    return await this.rest.post('/api/request/update_admin_request', { request_idx: request_idx, data: data }).toPromise();
  }

  async delete_admin_request(request_idx) {
    return await this.rest.post('/api/request/delete_admin_request', { request_idx: request_idx }).toPromise();
  }

  async insert_request(data) {
    return await this.rest.post('/api/request/insert_request', data).toPromise();
  }

  async insert_user_rate(data) {
    return await this.rest.post('/api/request/insert_user_rate', data).toPromise();
  }

  async select_user_rate(request_idx) {
    return await this.rest.post('/api/request/select_user_rate', { request_idx: request_idx }).toPromise();
  }

  async search_user(search) {
    return await this.rest.post('/api/user/search_user', { search: search }).toPromise();
  }

  async insert_reply_admin(data) {
    return await this.rest.post('/api/request/insert_reply', data).toPromise();
  }

  async insert_like(request_idx) {
    return await this.rest.post('/api/request/insert_like', { request_idx }).toPromise();
  }

  async delete_like(request_idx, like_idx) {
    return await this.rest.post('/api/request/delete_like', { request_idx, like_idx }).toPromise();
  }

  async select_reply_list(request_idx, offset, limit) {
    return await this.rest.post('/api/request/select_reply_list', { request_idx: request_idx, offset: offset, limit: limit }).toPromise();
  }

  async select_push_alarm_list(offset, limit) {
    return await this.rest.post('/api/user/select_push_alarm_list', { offset: offset, limit: limit }).toPromise();
  }

  async update_user_push(push_idx, data) {
    return await this.rest.post('/api/user/update_user_push', { push_idx: push_idx, data: data }).toPromise();
  }

  async read_user_push(push_idx) {
    return await this.rest.post('/api/user/read_user_push', { push_idx: push_idx }).toPromise();
  }

  async read_user_push_read_all() {
    return await this.rest.post('/api/user/read_user_push_read_all', {}).toPromise();
  }

  async delete_reply(request_idx, reply_idx) {
    return await this.rest.post('/api/request/delete_reply', { request_idx: request_idx, reply_idx: reply_idx }).toPromise();
  }

  async upload_file(file, container) {
    const result = await this.upload_file_list([file], container);
    return {
      upload_file_url: result.upload_file_url[0]
    };
  }

  async upload_file_list(files, container) {
    return await this.rest.uploadFile('/api/file/upload_file', files, { container: container });
  }

  async upload_image_file_list(files, container) {
    return await this.rest.uploadFile('/api/file/upload_image_file', files, { container: container });
  }

  async upload_logistics(data) {
    return await this.rest.post('/api/logistics/upload_logistics', data).toPromise();
  }

  async insert_temp_order_to_order(data) {
    return await this.rest.post('/api/logistics/insert_temp_order_to_order', data).toPromise();
  }

  async upload_stock(data) {
    return await this.rest.post('/api/logistics/upload_stock', data).toPromise();
  }

  async select_unknown_stock_barcode() {
    return await this.rest.post('/api/logistics/select_unknown_stock_barcode', {}).toPromise();
  }

  async select_differ_stock_barcode() {
    return await this.rest.post('/api/logistics/select_differ_stock_barcode', {}).toPromise();
  }

  async cancel_temp_order() {
    return await this.rest.post('/api/logistics/cancel_temp_order', {}).toPromise();
  }

  async download_unknown_stock_barcode(filename) {
    return await this.rest.download('/api/logistics/download_unknown_stock_barcode', filename);
  }

  async download_file(url, filename) {
    return await this.rest.download(url, filename);
  }

  async fix_unknown_stock_barcode() {
    return await this.rest.post('/api/logistics/fix_unknown_stock_barcode', {}).toPromise();
  }

  async update_temp_stock_to_real_stock() {
    return await this.rest.post('/api/logistics/update_temp_stock_to_real_stock', {}).toPromise();
  }

  async select_unknown_stock_code() {
    return await this.rest.post('/api/logistics/select_unknown_stock_code', {}).toPromise();
  }
  async select_delivery_list() {
    return await this.rest.post('/api/logistics/select_delivery_list', {}).toPromise();
  }

  async make_delivery_ready_requested() {
    return await this.rest.post('/api/logistics/make_delivery_ready_requested', {}).toPromise();
  }

  async migration_go() {
    return await this.rest.post('/api/logistics/migration_go', {}).toPromise();
  }

  async logout() {
    return await this.rest.post('/api/user/logout', {}).toPromise();
  }

  async delete_user() {
    return await this.rest.post('/api/user/delete_user', {}).toPromise();
  }

  async get_config_service(data) {
    return await this.rest.post('/api/config_service/get_config_service', data).toPromise();
  }

  async get_all_config_service() {
    return await this.rest.post('/api/config_service/get_all_config_service', {}).toPromise();
  }

  async insert_verify_phone(data) {
    return await this.rest.post('/api/user/insert_verify_phone', data).toPromise();
  }

  async verify_phone(data) {
    return await this.rest.post('/api/user/verify_phone', data).toPromise();
  }

  async select_request_manager_one() {
    return await this.rest.post('/api/user/select_request_manager_one', {}).toPromise();
  }

  async insert_request_manager(data) {
    return await this.rest.post('/api/user/insert_request_manager', data).toPromise();
  }

  async select_request_manager() {
    return await this.rest.post('/api/user/select_request_manager', {}).toPromise();
  }

  async select_user_list(data) {
    return await this.rest.post('/api/user/select_user_list', data).toPromise();
  }
  async select_order_list(data) {
    return await this.rest.post('/api/order/select_order_list', data).toPromise();
  }

  async insert_role(data) {
    return await this.rest.post('/api/user/insert_role', data).toPromise();
  }

  async select_manager_list(data) {
    return await this.rest.post('/api/user/select_manager_list', data).toPromise();
  }

  async select_item_list(data) {
    return await this.rest.post('/api/item/select_item_list', data).toPromise();
  }

  async select_item_option(data) {
    return await this.rest.post('/api/item/select_item_option', data).toPromise();
  }
  async select_item_option_list(data) {
    return await this.rest.post('/api/item/select_item_option_list', data).toPromise();
  }
  async insert_item_option(data) {
    return await this.rest.post('/api/item/insert_item_option', data).toPromise();
  }

  async insert_sales_channel_code(data) {
    return await this.rest.post('/api/item/insert_sales_channel_code', data).toPromise();
  }

  async insert_sms(data) {
    return await this.rest.post('/api/order/insert_sms', data).toPromise();
  }

  async loginNativeSocial(kind, access_token) {
    const temp = await this.rest
      .post('/auth/native-login', {
        kind: kind,
        access_token: access_token
      })
      .toPromise();
    // alert(JSON.stringify(temp))
    console.log('temp', temp);
    return temp;
  }
}
