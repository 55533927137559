import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GraphQLModule } from './graphql.module';
// import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ClipboardModule } from 'ngx-clipboard';
import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor.service';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

import { IntlModule } from '@progress/kendo-angular-intl';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { MenuModule } from '@progress/kendo-angular-menu';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule, FloatingLabelModule } from '@progress/kendo-angular-label';
import { WindowModule, DialogsModule } from '@progress/kendo-angular-dialog';
import { Apollo } from 'apollo-angular';

import { LayoutModule } from '@progress/kendo-angular-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { OrderGraphqlService } from './services/graphql/query/order.graphql.service';
import { CompanyGraphqlService } from './services/graphql/query/company.graphql.service';
import { ItemGraphqlService } from './services/graphql/query/item.graphql.service';
import { HammerModule } from 'ngx-hammer';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { DataProviderService } from './services/graphql/data-provider.service';
import { AppService } from './services/app.service';
import { SafePipe } from './services/pipe/safe.pipe';
import { PostGraphqlService } from './services/graphql/query/post.graphql.service';
import { UploadModule } from '@progress/kendo-angular-upload';
import { PagerModule } from '@progress/kendo-angular-pager';
import { AddAddressComponent } from './add-address/add-address.component';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { environment } from 'src/environments/environment';
import { ChatModule } from '@progress/kendo-angular-conversational-ui';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import { provideFirestore, getFirestore, enableIndexedDbPersistence } from '@angular/fire/firestore';
import { provideFunctions, getFunctions, connectFunctionsEmulator } from '@angular/fire/functions';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';

@NgModule({
  declarations: [AppComponent, AddAddressComponent],
  imports: [
    HammerModule,
    BrowserModule,
    BrowserAnimationsModule,
    GridModule,
    ButtonsModule,
    DialogsModule,
    AppRoutingModule,
    WindowModule,
    GraphQLModule,
    HttpClientModule,
    CommonModule,
    LabelModule,
    FloatingLabelModule,
    MenuModule,
    DropDownsModule,
    DateInputsModule,
    LayoutModule,
    ExcelModule,
    InputsModule,
    ReactiveFormsModule,
    FormsModule,
    ExcelExportModule,
    NotificationModule,
    ToolBarModule,
    TooltipModule,
    ChartsModule,
    UploadModule,
    ListViewModule,
    ScrollViewModule,
    ClipboardModule,
    ChatModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideFirestore(() => {
      const firestore = getFirestore();
      // enableIndexedDbPersistence(firestore, { forceOwnership: true });
      return firestore;
    }),
    provideFunctions(() => {
      const functions = getFunctions(getApp(), 'asia-northeast3');

      //개발중일 때만 에뮬레이터 사용
      if (!environment.production) {
        connectFunctionsEmulator(functions, 'localhost', 5001);
      }

      return functions;
    }),
    provideStorage(() => getStorage())
  ],

  providers: [
    Apollo,
    {
      provide: COMPOSITION_BUFFER_MODE,
      useValue: false
    },
    AppService,
    DataProviderService,
    {
      deps: [Apollo],
      provide: OrderGraphqlService,
      useFactory: (apollo: Apollo) => () => new OrderGraphqlService(apollo)
    },
    {
      deps: [Apollo],
      provide: CompanyGraphqlService,
      useFactory: (apollo: Apollo) => () => new CompanyGraphqlService(apollo)
    },
    {
      deps: [Apollo],
      provide: ItemGraphqlService,
      useFactory: (apollo: Apollo) => () => new ItemGraphqlService(apollo)
    },
    {
      deps: [Apollo],
      provide: PostGraphqlService,
      useFactory: (apollo: Apollo) => () => new PostGraphqlService(apollo)
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    DataProviderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
