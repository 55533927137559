import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { tap } from 'rxjs/operators/tap';
import { map } from 'rxjs/operators/map';
import { State, SortDescriptor } from '@progress/kendo-data-query';

import { GridDataResult } from '@progress/kendo-angular-grid';
import {
  allOrderTransactions,
  allOrderItems,
  select_order_transaction_list,
  delete_order_transaction,
  request_excel_download,
} from '../queries';

@Injectable()
export class OrderGraphqlService extends BehaviorSubject<
  GridDataResult
> {
  public state: State;
  public loading: boolean;

  constructor(private apollo: Apollo) {
    super(null);
  }

  private query: QueryRef<any>;
  private watchQuery;

  public allOrderTransactions(state: State) {
    this.state = state;

    if (!state.sort || state.sort.length == 0) {
      console.log('no sort');
      const sort: SortDescriptor = {
        field: 'order_transaction_idx',
        dir: 'desc',
      };
      state.sort = [sort];
    }
    console.log('will query state111 =', state);
    this.loading = true;
    if (this.query) {
      this.query.refetch({ state: this.state });
      return;
    }

    this.query = this.apollo.watchQuery({
      query: allOrderTransactions,
      variables: { state: this.state },
    });
    this.watchQuery = this.query.valueChanges.pipe(
      map(
        (changes: any) =>
          <GridDataResult>{
            data: changes.data.allOrderTransactions.rows,
            total: changes.data.allOrderTransactions.count,
          },
      ),
      tap(() => (this.loading = false)),
    );

    this.watchQuery.subscribe((data) => super.next(data));
  }

  public allOrderItems(state: State) {
    this.state = state;
    this.loading = true;
    if (this.query) {
      this.query.refetch({ state: this.state });
      return;
    }

    this.query = this.apollo.watchQuery({
      query: allOrderItems,
      variables: { state: this.state },
    });
    this.watchQuery = this.query.valueChanges.pipe(
      map(
        (changes: any) =>
          <GridDataResult>{
            data: changes.data.allOrderItems.rows,
            total: changes.data.allOrderItems.count,
          },
      ),
      tap(() => (this.loading = false)),
    );
    this.watchQuery.subscribe((data) => super.next(data));
  }

  public async queryAllOrderTransactions(state: State) {
    const result: any = await this.apollo
      .query({
        query: allOrderTransactions,
        variables: { state: state },
      })
      .toPromise();
    const excel_data = [];
    result.data.allOrderTransactions.rows.forEach(
      (order_transaction) => {
        order_transaction.order_item_code_list = '';
        order_transaction.order_item_option_name_list = '';
        order_transaction.order_item_list.forEach((order_item, i) => {
          if (i > 0) {
            order_transaction.order_item_code_list += ',';
            order_transaction.order_item_option_name_list += ',';
          }
          order_transaction.order_item_code_list +=
            order_item.item_option.item_option_code;
          order_transaction.order_item_option_name_list +=
            order_item.item_option.item_option_name;
          // order_item 만들기
          console.log(order_transaction);
          const excel_order_item: any = Object.assign({}, order_item);
          excel_order_item.order_transaction_idx =
            order_transaction.order_transaction_idx;
          excel_order_item.total_count = order_item.order_count;
          excel_order_item.receiver_name =
            order_transaction.receiver_name;
          excel_order_item.receiver_phone = order_transaction.receiver_phone.replace(
            /\D/g,
            '',
          );
          excel_order_item.title_internal =
            order_item.item.title_internal;
          excel_order_item.item_option_name =
            order_item.item_option.item_option_name;
          excel_order_item.address_all =
            order_transaction.address +
            ' ' +
            order_transaction.address_detail;
          excel_order_item.request = order_transaction.request;
          excel_order_item.payment_status =
            order_transaction.payment_status;
          excel_order_item.order_status = order_item.order_status;
          excel_order_item.user_pay_price = order_item.user_pay_price;
          excel_order_item.invoice_delivery_company_name = '';
          excel_order_item.invoice_num = '';
          excel_data.push(excel_order_item);
        });
      },
    );
    return { data: excel_data, total: excel_data.length };
  }

  public async queryAllOrderItems(state: State) {
    const result: any = await this.apollo
      .query({
        query: allOrderItems,
        variables: { state: state },
      })
      .toPromise();
    return {
      data: result.data.allOrderItems.rows,
      total: result.data.allOrderItems.count,
    };
  }

  public select_order_transaction_list(state: State) {
    this.state = state;

    if (this.query) {
      this.query.refetch({ state: this.state });
      return;
    }

    this.query = this.apollo.watchQuery({
      query: select_order_transaction_list,
      variables: { state: this.state },
    });
    this.query.valueChanges
      .pipe(
        map(
          (changes: any) =>
            <GridDataResult>{
              data: changes.data.select_order_transaction_list.rows,
              total: changes.data.select_order_transaction_list.count,
            },
        ),
      )
      .subscribe((data) => super.next(data));
  }

  public release() {
    if (this.query && this.query.valueChanges) {
      this.query.valueChanges.subscribe().unsubscribe();
    }
  }

  // public async add_order_transaction(data: InputOrderTransaction) {
  //     const result = await this.apollo.mutate({
  //         mutation: add_order_transaction,
  //         variables: { data },
  //         refetchQueries: [{
  //             query: select_order_transaction_list,
  //             variables: { state: this.state }
  //         }]
  //     }).toPromise();
  //     return result;
  // }

  // public async update_order_transaction(order_transaction_idx: number, data: InputOrderTransaction) {
  //     const result = await this.apollo.mutate({
  //         mutation: update_order_transaction,
  //         variables: {
  //             data, order_transaction_idx
  //         },
  //         refetchQueries: [{
  //             query: select_order_transaction_list,
  //             variables: { state: this.state }
  //         }]
  //     }).toPromise();
  //     return result;
  // }

  public async delete_order_transaction(
    order_transaction_idx: number,
  ) {
    const result = await this.apollo
      .mutate({
        mutation: delete_order_transaction,
        variables: { order_transaction_idx },
        refetchQueries: [
          {
            query: select_order_transaction_list,
            variables: { state: this.state },
          },
        ],
      })
      .toPromise();
    return result;
  }

  public async request_excel_download(state: State) {
    const result: any = await this.apollo
      .mutate({
        mutation: request_excel_download,
        variables: { state },
      })
      .toPromise();
    return result.data.request_excel_download;
  }
}
