import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { WindowRef } from '@progress/kendo-angular-dialog';
import { AppService } from '../services/app.service';
import { postcode } from 'src/assets/js/postcode.js';
import { DataProviderService } from '../services/graphql/data-provider.service';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss']
})
export class AddAddressComponent implements OnInit {

  @ViewChild('daum_popup', { read: ElementRef, static: true }) popup: ElementRef;

  constructor(
    public app: AppService,
    private renderer: Renderer2,
    public window: WindowRef
    ) {

    }

  clickBack() {
    this.window.close(null);
  }


  ngOnInit() {
    this.openDaumPopup();
  }

  openDaumPopup() {
    const self = this;
    postcode(this.renderer, this.app.size.width, this.app.size.height, this.popup.nativeElement, data => {
      console.log(data);
      self.window.close(data);
      self.window.close();
      /*
          {
              address: "대전 서구 둔산로 100"
              addressEnglish: "100, Dunsan-ro, Seo-gu, Daejeon, Korea"
              addressType: "R"
              apartment: "N"
              autoJibunAddress: ""
              autoJibunAddressEnglish: ""
              autoRoadAddress: ""
              autoRoadAddressEnglish: ""
              bcode: "3017011200"
              bname: "둔산동"
              bname1: ""
              bname2: "둔산동"
              buildingCode: "3017011200114200000021946"
              buildingName: "대전광역시청"
              hname: ""
              jibunAddress: "대전 서구 둔산동 1420"
              jibunAddressEnglish: "1420, Dunsan-dong, Seo-gu, Daejeon, Korea"
              noSelected: "N"
              postcode: "302-789"
              postcode1: "302"
              postcode2: "789"
              postcodeSeq: "007"
              query: "대전시청"
              roadAddress: "대전 서구 둔산로 100"
              roadAddressEnglish: "100, Dunsan-ro, Seo-gu, Daejeon, Korea"
              roadname: "둔산로"
              roadnameCode: "3166019"
              sido: "대전"
              sigungu: "서구"
              sigunguCode: "30170"
              userLanguageType: "K"
              userSelectedType: "R"
              zonecode: "35242"
          }
      */
    });
  }

  closeDaumPopup() {
    this.window.close();
  }

}
