import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

import { saveAs } from 'file-saver';

import { throwError } from 'rxjs';
import * as $ from 'jquery';
const httpOptions = {
  // headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept-Encoding': 'gzip' }),
};
const endpoint = environment.api_server_url;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) {}

  private handleError(error) {
    if (error && error.error instanceof ErrorEvent) {
      // alert("서버오류 : " + error.message);
      // A client-side or network error occurred. Handle it accordingly.
    } else {
      // alert("코드: " + error.error_number + ", 메세지: " + error.error_msg);
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log('error number = ' + error.error_number);

      if (error.error_number == '001') {
        console.log('error gogo');
      }
    }
    console.log(error);
    // return an observable with a user-facing error message
    return throwError(error);
  }

  private extractData(res: any) {
    //error
    if (res.status === 0) {
      throw res.error;
    }
    let body = res.data;
    return body || {};
  }

  async regist(param) {
    const url = endpoint + '/regist';
    return this.http.post(url, param, httpOptions).toPromise();
  }

  async login(param) {
    const url = endpoint + '/login';
    return this.http.post(url, param, httpOptions).toPromise();
  }

  async isDuplicatedEmail(param) {
    const url = endpoint + '/isDuplicatedEmail';
    return this.http.post(url, param, httpOptions).toPromise();
  }

  download(uri, saveName) {
    const url = endpoint + uri;
    saveAs(url, saveName);
    return true;
  }

  post(uri, param) {
    const url = endpoint + uri;
    console.log(url);
    return this.http.post(url, param, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }

  uploadFile(uri, files, data) {
    return new Promise<any>((resolve, reject) => {
      // 업로드 api
      const token = localStorage.getItem('token');
      var uploadUrl = endpoint + uri;
      var formData = new FormData();
      console.log('선택한 파일 개수 :' + files.length);
      var fileCount = files.length;

      for (var i = 0; i < fileCount; i++) {
        var _file = files[i];
        formData.append('files', _file, _file.name);
      }

      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i++) {
        formData.append(keys[i], data[keys[i]]);
      }

      $.ajax({
        type: 'POST',
        url: uploadUrl,
        headers: {
          token: token
        },
        data: formData,
        processData: false,
        contentType: false,
        success: function (result) {
          console.log('completed=', result);

          if (result.status === 0) {
            reject(result.error);
          } else {
            let body = result.data;
            resolve(body || {});
          }
        },
        error: function (error) {
          console.log('error', error.responseText);
          reject(error);
        }
      });
    });
  }

  raw_get(url, param) {
    return this.http.get(url).pipe(map(this.extractData), catchError(this.handleError));
  }

  raw_post(url, param) {
    return this.http.post(url, param, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }

  // delivery test
  async select_delivery_info(carrier_id, track_id) {
    const url = environment.delivery_url + `/carriers/${carrier_id}/tracks/${track_id}`;
    // raw_get 쓰기?
    return this.http.get(url, httpOptions).pipe(catchError(this.handleError)).toPromise();
  }
}
