import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { State } from '@progress/kendo-data-query';
import { map, take, share } from 'rxjs/operators';
import {
  search_analytics_list,
  select_sales_channel_list,
  select_login_type,
  select_source_list,
  migration_data,
  add_user,
  update_user,
  delete_user,
  sync_all_item_option_stock,
  sync_item_option_stock,
  sync_all_invoice,
  insert_payment,
  get_order_transaction,
  update_order_payment,
  cancel_pending_order_payment,
  update_payment_status,
  confirm_order_transaction_payment,
  payment_cancel_all_admin,
  insert_admin_order_transaction,
  payment_update_order_transaction_delivery_admin,
  payment_update_order_transaction_item_admin,
  insert_sms,
  select_sms_list_admin,
  search_user_list,
  search_item_list,
  insert_user_card_number,
  search_user_card_number,
  delete_user_card_number,
  request_card_pay,
  payment_pay_billkey_admin,
  payment_select_card_list_admin,
  payment_issue_card_admin,
  payment_delete_card_admin,
  insert_post,
  post_update_post_admin,
  sync_invoice,
  update_invoice_complete_admin,
  search_user_analytics_list,
  select_all_post_list,
  select_reviewer_review_list,
  insert_contest_review,
  delete_post_admin,
  update_user_info,
  insert_contest_review_comment,
  insert_contest_review_point,
  select_review_list,
  file_upload,
  add_notice,
  update_notice,
  select_notice_list,
  select_push_alarm_list_admin,
  insert_push_alarm,
  update_push_alarm,
  select_banner_list_admin,
  update_banner,
  select_item_list_admin,
  select_user_request_manager_list,
  update_user_request,
  drop_user_request,
  insert_role,
  delete_role,
  insert_banner,
  insert_item_resource,
  insert_post_resource,
  get_item_admin,
  select_company_list,
  update_item,
  update_item_resource,
  delete_item_option,
  insert_item_option,
  update_item_option,
  insert_post_item,
  delete_post_item,
  select_order_call_list,
  update_order_call_admin,
  select_reply_list_admin,
  insert_reply_admin,
  update_reply_admin,
  insert_item,
  insert_crawling_item,
  select_sync_item_stock_history_list,
  insert_temp_order,
  select_temp_order_list,
  que_temp_order_list,
  select_sales_channel_item_code_list,
  search_item_option,
  search_item,
  select_salse_channel_item_option_barcode_list,
  insert_sales_channel_item_code,
  update_sales_channel_item_code,
  insert_sales_channel_item_option_barcode,
  update_sales_channel_item_option_barcode,
  delete_sales_channel_item_code,
  delete_sales_channel_item_option_barcode,
  select_order_transaction_list,
  select_delivery_order_list,
  insert_like_admin,
  is_like_admin,
  update_temp_order_status,
  delete_temp_order,
  que_temp_order_insert,
  select_invoice_list,
  insert_invoice_list,
  select_topic_list_admin,
  update_topic_admin,
  add_topic_admin,
  shop_select_shop_section_list_admin,
  select_shop_section_item_list_admin,
  shop_insert_shop_section_admin,
  shop_insert_shop_section_item_admin,
  update_shop_section_item_admin,
  shop_insert_shop_section_item_list_admin,
  select_payment_type_list_admin,
  insert_payment_type,
  update_payment_type,
  admin_select_config_service_list,
  admin_update_config_service,
  admin_insert_sales_channel_item_code_excel,
  delivery_update_delivery_status_admin,
  point_check_left_admin,
  point_select_user_point_list_admin,
  point_create_user_point_admin,
  point_expire_user_point_admin,
  point_select_point_list_admin,
  point_create_bulk_list_admin,
  delete_item_admin,
  delete_item_option_admin,
  merge_item_admin,
  merge_item_option_admin,
  get_item_option_admin,
  delivery_update_order_item_admin,
  select_delivery_order_transaction_list,
  select_delivery_order_list_for_pending,
  update_sales_channel_option,
  insert_sales_channel_option,
  delete_sales_channel_option,
  shop_search_section_list,
  shop_insert_shop_section_group,
  shop_update_shop_section_group,
  shop_select_shop_section_group_admin,
  shop_update_shop_section_group_list_admin,
  post_select_fumi_tv_list_admin,
  post_insert_fumi_tv,
  post_update_fumi_tv,
  shop_delete_shop_section_item_admin,
  post_delete_fumi_tv,
  update_post_item_admin,
  post_select_fumi_tv_admin,
  shop_select_category_list_admin,
  shop_update_category_admin,
  shop_insert_category_admin,
  shop_delete_category_admin,
  post_select_featured_content_admin,
  post_select_featured_content_list_admin,
  post_insert_featured_content_admin,
  post_update_featured_content_admin,
  post_delete_featured_content_admin,
  event_select_event_list_admin,
  event_select_event_admin,
  event_insert_event_admin,
  event_update_event_admin,
  event_delete_event_admin,
  tag_insert_tag_from_post_list_admin,
  login_agency,
  regist_agency,
  insert_request_manager,
  select_request_manager_one,
  content_order_insert_content_order_admin,
  content_order_select_content_order_admin,
  content_order_select_content_order_list_admin,
  content_order_update_content_order_admin,
  content_order_update_content_order_list_admin,
  content_order_delete_content_order_admin,
  notice_dialog_select_notice_dialog_admin,
  notice_dialog_update_notice_dialog_admin,
  notice_dialog_insert_notice_dialog_admin,
  notice_dialog_delete_notice_dialog_admin,
  shop_update_shop_section_list_admin,
  shop_delete_shop_section_admin,
  select_banner_list_open_admin,
  delete_banner_admin,
  shop_delete_shop_section_group_admin,
  insert_dev_order_transaction_admin,
  insert_item_review_admin,
  coupon_select_coupon_rule_list_admin,
  coupon_select_coupon_rule_admin,
  coupon_insert_coupon_rule_admin,
  coupon_update_coupon_rule_admin,
  coupon_select_coupon_rule_group_list_admin,
  coupon_select_coupon_rule_group_admin,
  coupon_insert_coupon_rule_group_admin,
  coupon_update_coupon_rule_group_admin,
  coupon_select_coupon_by_user_idx_admin,
  select_company,
  shop_update_category_idx_from_category_list_admin,
  delivery_update_order_item_list_admin,
  delivery_update_delivery_address_admin,
  search_store_list_admin,
  shop_update_shop_section_admin,
  delete_duplicate_tag_content,
  delete_duplicate_tag,
  select_store_list_for_search,
  select_user_sales_info_list_admin,
  select_user,
  update_item_is_open,
  update_item_status,
  select_admin_log_list,
  select_chat_message,
  insert_chat_message,
  select_chat_join_admin,
  insert_chat_join_heart_beat_admin,
  post_update_fumi_tv_live,
  update_order_item_payment_status_admin,
  get_chat_token,
  insert_chat_room,
  after_insert_chat_message,
  post_full_sync_post_tv_admin,
  full_sync_store_admin,
  post_select_live_tv_list_admin,
  post_save_fumi_tv_live,
  delete_fumi_tv,
  select_onelink_list,
  insert_onelink,
  update_onelink,
  create_shotlink,
  delete_onelink,
  admin_delete_user,
  insert_que,
  item_image_migration,
  search_item_list_admin,
  search_shop_section_list_admin,
  update_onelink_only_meta,
  select_onelink_analytics,
  select_bigquery_analytics,
  insert_onelink_group,
  update_onelink_group_only_meta,
  select_onelink_group_list,
  select_item_section_list_admin,
  select_item_post_admin,
  point_select_point_ready_list_admin,
  search_live_tv_list_admin,
  select_user_link_list,
  select_bank_account_log_list,
  allOrderTransactions,
  update_bank_account_log,
  select_company_group_list,
  request_class_room_incentive_process,
  select_class_room_incentive_list,
  request_class_room_incentive_done,
  confirm_refund,
  insert_admin_order_cancel,
  confirm_cancel_fee,
  update_order_cancel,
  insert_admin_memo,
  delete_admin_memo,
  select_order_cancel_list,
  update_order_item,
  insert_admin_log,
  select_order_transaction_detail,
  select_class_room_point_list,
  insert_fumist_point,
  select_class_room_list,
  select_item_thum_list_admin,
  select_bank_transfer_list,
  update_bank_transfer,
  update_order_transaction,
  get_user,
  reject_order_cancel,
  update_basket_coupon_enable,
  select_user_list_only,
  select_user_list_admin,
  admin_copy_item,
  select_company_manager_list,
  insert_company_manager,
  update_company_manager,
  delete_company_manager,
  copy_class_room_shop_section,
  search_item_list_idx_admin
} from './queries';

import {
  SalesChannel,
  InputUser,
  InputOrder,
  InputPayment,
  InputOrderTransaction,
  InputOrderPayment,
  InputUpdatePaymentStatus,
  InputSms,
  Item,
  InputUserCardNumber,
  InputPayCard,
  InputSubscribeCardInfo,
  User,
  InputPost,
  FumiAnalyticsListReturn,
  InputContestReview,
  SelectContestReviewListReturn,
  InputNotice,
  SelectNoticeListReturn,
  InputPushAlarm,
  SelectPushAlarmListReturn,
  SelectBannerListReturn,
  InputBanner,
  SelectItemListReturn,
  Upload,
  InputPostResource,
  InputItemResource,
  UploadFile,
  SelectCompanyListReturn,
  InputItem,
  SelectOrderCallListReturn,
  InputReply,
  SelectSyncItemStockHistoryListReturn,
  InputTempOrderList,
  SelectSalesChannelListReturn,
  SelectTempOrderListReturn,
  SelectSalesChannelItemCodeListReturn,
  SelectItemOptionListReturn,
  InputSalesChannelItemCode,
  InputSalesChannelItemOptionBarcode,
  SelectOrderTransactionListReturn,
  OrderCall,
  InputOrderCall,
  TempOrder,
  InputInvoiceList,
  SelectInvoiceListReturn,
  InputTopic,
  SelectTopicListReturn,
  InputShopSection,
  InputShopSectionItem,
  InputCopyShopSection,
  SelectPaymentTypeListReturn,
  InputPaymentType,
  InputSalesChannelItemCodeExcelList,
  OrderTransaction,
  SelectPointAllInfoReturn,
  InputPoint,
  Point,
  InputMergeItem,
  InputMergeItemOption,
  ItemOption,
  InputOrderItem,
  InputItemOptionSalesChannel,
  InputItemSalesChannel,
  SelectShopSectionGroupListReturn,
  InputShopSectionGroup,
  SelectCategoryListReturn,
  InputCategory,
  Category,
  PostItem,
  InputPostItem,
  InputIssueBillingKeyInfo,
  InputIamportRequestBill,
  SelectPointListReturn,
  InputPointList,
  InputFeaturedContent,
  InputEvent,
  InputExcelItemList,
  ShopSectionGroup,
  InputContentOrder,
  InputUserRequestManagerData,
  InputNoticeDialog,
  NoticeDialog,
  InputItemOption,
  InputCouponRule,
  InputCouponRuleGroup,
  Company,
  InputItemDelivery,
  DeliveryOrderItem,
  SelectChatJoinListReturn,
  SelectChatMessageListReturn,
  InputChatMessage,
  InputChatJoinHeartBeat,
  InputPostTv,
  InputChatRoom,
  PostTv,
  SelectOnelinkListReturn,
  InputOnelink,
  Onelink,
  InputOnelinkGroup,
  SelectOnelinkGroupListReturn,
  OnelinkGroup,
  SelectPostItemListReturn,
  SelectShopSectionItemListReturn,
  InputBankAccountLog,
  InputOrderCancel,
  InputAdminMemo,
  InputAdminLog,
  InputClassRoomPoint,
  InputBankTransfer,
  InputBasketCouponCompany,
  InputCompanyManager
} from './models';
import { Keys_InputOrderCancel, Keys_OrderCancel, Keys_OrderItem, Keys_OrderTransaction } from './model_keys';

@Injectable({
  providedIn: 'root'
})
export class DataProviderService {
  constructor(private apollo: Apollo) {
    console.log('DataProviderService create');
  }

  public filterKeyData(data: any, keys: string[]) {
    const result = {};
    const temp_keys = Object.keys(data);
    for (let i = 0; i < temp_keys.length; i++) {
      if (keys.indexOf(temp_keys[i]) >= 0) {
        result[temp_keys[i]] = data[temp_keys[i]];
      }
    }
    return result;
  }

  /**
   * 마이그레이션 관련 - 이전 후 삭제해야함
   */
  public async sync_all_item_option_stock() {
    const result = await this.apollo
      .mutate({
        mutation: sync_all_item_option_stock
      })
      .toPromise();
    return result;
  }

  public async sync_item_option_stock(state: State) {
    const result = await this.apollo
      .mutate({
        mutation: sync_item_option_stock,
        variables: { state }
      })
      .toPromise();
    return result;
  }

  public async sync_all_invoice() {
    const result = await this.apollo
      .mutate({
        mutation: sync_all_invoice
      })
      .toPromise();
    return result;
  }

  public async sync_invoice(state: State) {
    const result = await this.apollo
      .mutate({
        mutation: sync_invoice
      })
      .toPromise();
    return result;
  }

  public async update_invoice_complete_admin() {
    const result = await this.apollo
      .mutate({
        mutation: update_invoice_complete_admin
      })
      .toPromise();
    return result;
  }

  //신규회원추가
  public async add_user(data: InputUser) {
    const result: any = await this.apollo
      .mutate({
        mutation: add_user,
        variables: { data }
      })
      .toPromise();
    return result.data.add_user;
  }

  //신규 포스트 추가
  public async insert_post(data: InputPost, post_resource_list: Array<InputPostResource>) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_post,
        variables: { data, post_resource_list }
      })
      .toPromise();
    return result.data.insert_post;
  }

  //포스트 업데이트
  public async post_update_post_admin(data: InputPost, post_resource_list: Array<InputPostResource>) {
    const result: any = await this.apollo
      .mutate({
        mutation: post_update_post_admin,
        variables: { data, post_resource_list }
      })
      .toPromise();
    return result.data.post_update_post_admin;
  }

  public async select_user(user_idx: number): Promise<User> {
    const result: any = await this.apollo
      .query({
        query: select_user,
        variables: { user_idx }
      })
      .toPromise();

    return await result.data.select_user_admin;
  }

  public async get_user(user_idx: number): Promise<User> {
    const result: any = await this.apollo
      .query({
        query: get_user,
        variables: { user_idx }
      })
      .toPromise();

    console.log('');
    return await result.data.get_user;
  }

  //사용자 목록
  public select_user_list_watch(state: State) {
    const query = this.apollo.watchQuery({
      query: select_user_list_admin,
      variables: { state }
    });
    return query;
  }

  //사용자 목록
  public async select_user_list_admin(state: State) {
    const result: any = await this.apollo
      .query({
        query: select_user_list_admin,
        variables: { state: state }
      })
      .toPromise();
    return result.data.select_user_list_admin;
  }

  public async select_user_list_only(state: State) {
    const result: any = await this.apollo
      .query({
        query: select_user_list_only,
        variables: { state: state }
      })
      .toPromise();
    return result.data.select_user_list_only;
  }

  //로그인 타입
  public select_login_type() {
    const query = this.apollo.watchQuery({
      query: select_login_type
    });
    return query.valueChanges.pipe(map((changes: any) => <[string]>changes.data.select_login_type.rows));
  }

  //소스 타입
  public select_source_list() {
    const query = this.apollo.watchQuery({
      query: select_source_list
    });

    return query.valueChanges.pipe(map((changes: any) => <[string]>changes.data.select_source_list.rows));
  }

  //세일즈 채널
  public async select_sales_channel_list(state: State): Promise<SelectSalesChannelListReturn> {
    console.log('select_sales_channel_list');
    if (!state.skip) {
      state.skip = 0;
      state.take = -1;
    }

    const result: any = await this.apollo
      .query({
        query: select_sales_channel_list,
        variables: { state: state }
      })
      .toPromise();
    console.log('select_sales_channel_list result', result);

    return result.data.select_sales_channel_list;
  }

  public search_user_list(state: State) {
    return this.apollo
      .query({
        query: search_user_list,
        variables: { state: state }
      })
      .pipe(
        map((result: any) => {
          result.data.search_user_list.rows.forEach((user) => {
            user.display = `${user.user_name}-${user.user_phone} (${user.source}, ${user.login_type})`;
          });
          return result.data.search_user_list.rows;
        })
      );
  }

  public search_item_list_admin(state: State) {
    return this.apollo
      .query({
        query: search_item_list_admin,
        variables: { state }
      })
      .pipe(
        map((result: any) => {
          const rows = result.data.search_item_list_admin.rows;
          // rows.forEach((item: any) => {
          //   item.title = `(${item.item_idx}) ${item.title}`;
          // });
          return rows;
        })
      );
  }

  public search_item_list(state: State) {
    console.log('search_item_list state =', state);
    return this.apollo
      .query({
        query: search_item_list,
        variables: { state: state }
      })
      .pipe(
        map((result: any) => {
          console.log('search_item_list map -', result);
          result.data.search_item_list.rows.forEach((item: any) => {
            item.display = `[상품ID: ${item.item_idx}] ✭ ${item.title}`;
            item.item_option_list.forEach((item_option: any) => {
              item_option.display = `${item_option.item_option_name}(추가: ${item_option.item_option_plus_sell_price}원)`;
            });

            item.item_sales_channel_list.forEach((item_sales_channel: any) => {
              item_sales_channel.display = `${item_sales_channel.sales_channel.sales_channel_name}(추가: ${item_sales_channel.item_sales_channel_plus_price}원)`;
            });
          });

          return result.data.search_item_list.rows;
        })
      );
  }

  public async search_analytics_list(state: State, analytics_interval: string) {
    const result: any = await this.apollo
      .query({
        query: search_analytics_list,
        variables: { state, analytics_interval }
      })
      .toPromise();
    return result.data.search_analytics_list;
  }

  public async search_user_analytics_list(state: State, analytics_interval: string): Promise<FumiAnalyticsListReturn> {
    const result: any = await this.apollo
      .query({
        query: search_user_analytics_list,
        variables: { state, analytics_interval }
      })
      .toPromise();
    return result.data.search_user_analytics_list;
  }

  public async migration_data(data: string) {
    const result = await this.apollo
      .mutate({
        mutation: migration_data,
        variables: { data }
      })
      .toPromise();
    return result;
  }

  public async insert_admin_order_transaction(data: InputOrder) {
    const result = await this.apollo
      .mutate({
        mutation: insert_admin_order_transaction,
        variables: { data }
      })
      .pipe(
        map((result: any) => {
          return result.data.insert_admin_order_transaction;
        })
      )
      .toPromise();
    return result;
  }

  public async payment_update_order_transaction_delivery_admin(data: InputOrderTransaction) {
    const result: any = await this.apollo
      .mutate({
        mutation: payment_update_order_transaction_delivery_admin,
        variables: { data }
      })
      .toPromise();
    return result.data.payment_update_order_transaction_delivery_admin;
  }

  public async payment_update_order_transaction_item_admin(data: InputOrder) {
    const result = await this.apollo
      .mutate({
        mutation: payment_update_order_transaction_item_admin,
        variables: { data }
      })
      .pipe(
        map((result: any) => {
          return result.data.payment_update_order_transaction_item_admin;
        })
      )
      .toPromise();
    return result;
  }

  public async insert_payment(data: InputPayment) {
    const result = await this.apollo
      .mutate({
        mutation: insert_payment,
        variables: { data }
      })
      .toPromise();
    return result;
  }

  public async insert_user_card_number(data: InputUserCardNumber) {
    const result = await this.apollo
      .mutate({
        mutation: insert_user_card_number,
        variables: { data }
      })
      .toPromise();
    return result;
  }

  public async search_user_card_number(user_idx: number) {
    console.log('search_user_card_number user_idx =', user_idx);
    const result = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: search_user_card_number,
        variables: { user_idx: user_idx }
      })
      .pipe(
        map((result: any) => {
          result.data.search_user_card_number.rows.forEach((item: Item) => {});
          return result.data.search_user_card_number.rows;
        })
      )
      .toPromise();
    return result;

    // return this.apollo.query({
    //   fetchPolicy: 'no-cache',
    //   query: search_user_card_number,
    //   variables: { user_idx: user_idx }
    // }).pipe(map((result: any) => {
    //   console.log('search_item_list map -', result);
    //   result.data.search_user_card_number.rows.forEach((item: Item) => {

    //   });
    //   return result.data.search_user_card_number.rows;
    // }));
  }

  public async delete_user_card_number(user_card_number_idx: number) {
    const result = await this.apollo
      .mutate({
        mutation: delete_user_card_number,
        variables: { user_card_number_idx: user_card_number_idx }
      })
      .toPromise();
    return result;
  }

  public async request_card_pay(data: InputPayCard) {
    const result = await this.apollo
      .mutate({
        mutation: request_card_pay,
        variables: { data }
      })
      .pipe(
        map((result: any) => {
          return result.data.request_card_pay;
        })
      )
      .toPromise();
    return result;
  }

  public async payment_pay_billkey_admin(data: InputIamportRequestBill, user_idx: number) {
    const result: any = await this.apollo
      .mutate({
        mutation: payment_pay_billkey_admin,
        variables: { data, user_idx }
      })
      .toPromise()
      .catch((error) => {
        return error;
      });

    return result;
  }

  public async payment_select_card_list_admin(state: State) {
    const result: any = await this.apollo
      .query({
        query: payment_select_card_list_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.payment_select_card_list_admin;
  }

  public async payment_issue_card_admin(data: InputIssueBillingKeyInfo) {
    const result: any = await this.apollo
      .mutate({
        mutation: payment_issue_card_admin,
        variables: { data }
      })
      .toPromise()
      .catch((error) => {
        return error;
      });

    return result;
  }

  public async payment_delete_card_admin(data: InputSubscribeCardInfo) {
    const result: any = await this.apollo
      .mutate({
        mutation: payment_delete_card_admin,
        variables: { data }
      })
      .toPromise();
    return result.data.payment_delete_card_admin;
  }

  public async update_payment_status(data: InputUpdatePaymentStatus) {
    const result = await this.apollo
      .mutate({
        mutation: update_payment_status,
        variables: { data },
        refetchQueries: [
          {
            query: get_order_transaction,
            variables: {
              order_transaction_idx: data.order_transaction_idx
            }
          }
        ]
      })
      .toPromise();
    return result;
  }

  public async confirm_order_transaction_payment(data: InputUpdatePaymentStatus) {
    const result = await this.apollo
      .mutate({
        mutation: confirm_order_transaction_payment,
        variables: { data },
        refetchQueries: [
          {
            query: get_order_transaction,
            variables: {
              order_transaction_idx: data.order_transaction_idx
            }
          }
        ]
      })
      .toPromise();
    return result;
  }

  //sms 전송
  public async insert_sms(data: InputSms) {
    const result = await this.apollo
      .mutate({
        mutation: insert_sms,
        variables: { data }
      })
      .toPromise();
    return result;
  }

  //sms 리스트
  public async select_sms_list_admin(state: State) {
    const result: any = await this.apollo
      .query({
        query: select_sms_list_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.select_sms_list_admin;
  }

  public async update_order_payment(data: InputOrderPayment) {
    const result = await this.apollo
      .mutate({
        mutation: update_order_payment,
        variables: { data },
        refetchQueries: [
          {
            query: get_order_transaction,
            variables: {
              order_transaction_idx: data.order_transaction_idx
            }
          }
        ]
      })
      .toPromise();
    return result;
  }

  public async cancel_pending_order_payment(data: InputOrderTransaction) {
    const result = await this.apollo
      .mutate({
        mutation: cancel_pending_order_payment,
        variables: { data },
        refetchQueries: [
          {
            query: get_order_transaction,
            variables: {
              order_transaction_idx: data.order_transaction_idx
            }
          }
        ]
      })
      .toPromise();
    return result;
  }

  public async update_user(user_idx: number, data: InputUser) {
    const result = await this.apollo
      .mutate({
        mutation: update_user,
        variables: {
          data,
          user_idx
        }
      })
      .toPromise();
    return result;
  }

  public async delete_user(user_idx: number) {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_user,
        variables: { user_idx }
      })
      .toPromise();
    return result.data.delete_user;
  }

  /**
   * 포스트 삭제
   * @param post_idx
   */
  public async delete_post_admin(post_idx: number) {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_post_admin,
        variables: { post_idx }
      })
      .toPromise();
    return result.data.delete_post_admin;
  }

  public async update_user_info(user_idx: number, user_name: string, user_phone: string): Promise<User> {
    const result: any = await this.apollo
      .mutate({
        mutation: update_user_info,
        variables: { user_idx, user_name, user_phone }
      })
      .toPromise();
    return result.data.update_user_info;
  }

  public async get_order_transaction(order_transaction_idx: number) {
    console.log('get_order_transaction');
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: get_order_transaction,
        variables: { order_transaction_idx }
      })
      .toPromise();
    return result.data.get_order_transaction;
  }

  //포스트 리스트 가져오기
  public async select_all_post_list(state: State) {
    const result: any = await this.apollo
      .query({
        query: select_all_post_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_all_post_list;
  }

  //포스트의 댓글 가져오기
  public async select_reply_list_admin(post_idx: number) {
    const result: any = await this.apollo
      .query({
        query: select_reply_list_admin,
        fetchPolicy: 'no-cache',
        variables: { post_idx }
      })
      .toPromise();
    return result.data.select_reply_list_admin;
  }

  //포스트에 댓글 작성하기
  public async insert_reply_admin(data: InputReply) {
    const result = await this.apollo
      .mutate({
        mutation: insert_reply_admin,
        variables: { data }
      })
      .toPromise();
    return result;
  }

  //포스트 댓글 업데이트
  public async update_reply_admin(data: InputReply) {
    const result = await this.apollo
      .mutate({
        mutation: update_reply_admin,
        variables: { data }
      })
      .toPromise();
    return result;
  }

  //좋아요 클릭
  public async insert_like_admin(post_idx: number, user_idx: number) {
    const result = await this.apollo
      .mutate({
        mutation: insert_like_admin,
        variables: { post_idx, user_idx }
      })
      .toPromise();
    return result;
  }

  //좋아요 확인
  public async is_like_admin(post_idx: number, user_idx: number) {
    const result = await this.apollo
      .query({
        query: is_like_admin,
        variables: { post_idx, user_idx }
      })
      .toPromise();
    // @ts-ignore
    return result.data.is_like_admin;
  }

  //심사 리스트 가져오기
  public async select_reviewer_review_list(state: State, review_status: string) {
    const result: any = await this.apollo
      .query({
        query: select_reviewer_review_list,
        variables: { state, review_status }
      })
      .toPromise();
    return result.data.select_reviewer_review_list;
  }

  //심사 등록하기
  public async insert_contest_review(data: InputContestReview) {
    const result = await this.apollo
      .mutate({
        mutation: insert_contest_review,
        variables: { data }
      })
      .toPromise();
    return result;
  }

  //심사 등록하기
  public async insert_contest_review_comment(data: InputContestReview) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_contest_review_comment,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_contest_review_comment;
  }

  //심사 등록하기
  public async insert_contest_review_point(data: InputContestReview) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_contest_review_point,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_contest_review_point;
  }

  //심사 리스트 가져오기
  public async select_review_list(state: State): Promise<SelectContestReviewListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_review_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_review_list;
  }

  //공지사항 등록
  public async add_notice(data: InputNotice) {
    const result: any = await this.apollo
      .mutate({
        mutation: add_notice,
        variables: { data }
      })
      .toPromise();
    return result.data.add_notice;
  }

  //공지사항 업데이트
  public async update_notice(notice_idx: number, data: InputNotice) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_notice,
        variables: { data, notice_idx }
      })
      .toPromise();
    return result.data.update_notice;
  }

  //공지사항 리스트 가져오기
  public async select_notice_list(state: State): Promise<SelectNoticeListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_notice_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_notice_list;
  }

  public async_select_notice_list(state: State) {
    return this.apollo
      .query({
        query: select_notice_list,
        variables: { state }
      })
      .pipe(
        map((result: any) => {
          console.log('async_select_notice_list', result);
          return result.data.select_notice_list.rows;
        })
      );
  }

  /**
   *
   * @param state
   */
  public async select_push_alarm_list_admin(state: State): Promise<SelectPushAlarmListReturn> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_push_alarm_list_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.select_push_alarm_list_admin;
  }

  /**
   * 푸시 등록
   * @param data
   */
  public async insert_push_alarm(data: InputPushAlarm) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_push_alarm,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.insert_push_alarm;
  }

  /**
   * 푸시 업데이트
   * @param data
   */
  public async update_push_alarm(data: InputPushAlarm) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_push_alarm,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.update_push_alarm;
  }

  /**
   * 배너 리스트
   * @param state
   */
  public async select_banner_list_admin(state: State): Promise<SelectBannerListReturn> {
    console.log('select_banner_list_admin');
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_banner_list_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.select_banner_list_admin;
  }

  public async select_banner_list_open_admin(): Promise<SelectBannerListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_banner_list_open_admin
      })
      .toPromise();
    return result.data.select_banner_list_open_admin;
  }

  public async delete_banner_admin(banner_idx: number): Promise<boolean> {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_banner_admin,
        variables: { banner_idx }
      })
      .toPromise();
    return result.data.delete_banner_admin;
  }

  /**
   * 배너 등록
   * @param data
   */
  public async insert_banner(data: InputBanner) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_banner,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.insert_banner;
  }

  /**
   * 배너 업데이트
   * @param data
   */
  public async update_banner(data: InputBanner) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_banner,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.update_banner;
  }

  public select_store_list_admin(state: State) {
    return this.apollo
      .query({
        query: select_store_list_for_search,
        variables: { state: state }
      })
      .pipe(
        map((result: any) => {
          return result.data.select_store_list_admin.rows;
        })
      );
  }

  public async select_store_list_admin_to_promise(state: State) {
    const result: any = await this.apollo
      .query({
        query: select_store_list_for_search,
        variables: { state: state }
      })
      .toPromise();

    return result.data.select_store_list_admin.rows;
  }

  public async select_item_list_admin(state: State, option_state: State): Promise<SelectItemListReturn> {
    console.log('select_banner_list_admin');
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_item_list_admin,
        variables: { state, option_state }
      })
      .toPromise();
    return result.data.select_item_list_admin;
  }

  public async search_item_list_idx_admin(state: State): Promise<SelectItemListReturn> {
    console.log('search_item_list_idx_admin');
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: search_item_list_idx_admin,
        variables: { state }
      })
      .toPromise();
    console.log('search_item_list_idx_admin', result);
    return result.data.search_item_list_idx_admin;
  }

  public async select_item_thum_list_admin(state: State, option_state: State): Promise<SelectItemListReturn> {
    console.log('select_banner_list_admin');
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_item_thum_list_admin,
        variables: { state, option_state }
      })
      .toPromise();
    return result.data.select_item_thum_list_admin;
  }

  public async get_item_admin(item_idx: number): Promise<Item> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: get_item_admin,
        variables: { item_idx }
      })
      .toPromise();
    return result.data.get_item_admin;
  }

  public async select_item_section_list_admin(state: State): Promise<SelectShopSectionItemListReturn> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_item_section_list_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.select_item_section_list_admin;
  }

  public async select_item_post_admin(state: State): Promise<SelectPostItemListReturn> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_item_post_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.select_item_post_admin;
  }

  public async select_company_list(state: State): Promise<SelectCompanyListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_company_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_company_list;
  }

  public async select_company_group_list(state: State): Promise<SelectCompanyListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_company_group_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_company_group_list;
  }

  public async select_company_manager_list(state: State): Promise<SelectCompanyListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_company_manager_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_company_manager_list;
  }

  public async insert_company_manager(data: InputCompanyManager) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_company_manager,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_company_manager;
  }

  public async update_company_manager(data: InputCompanyManager) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_company_manager,
        variables: { data }
      })
      .toPromise();
    return result.data.update_company_manager;
  }
  public async delete_company_manager(data: InputCompanyManager) {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_company_manager,
        variables: { data }
      })
      .toPromise();
    return result.data.delete_company_manager;
  }
  public async select_user_request_manager_list(state: State) {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_user_request_manager_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_user_request_manager_list;
  }

  public async update_user_request(user_request_manager_idx: number, data: InputUserRequestManagerData) {
    const result = await this.apollo
      .mutate({
        mutation: update_user_request,
        variables: {
          data,
          user_request_manager_idx
        }
      })
      .toPromise();
    return result;
  }

  public async drop_user_request(user_idx: number, user_request_manager_idx: number) {
    const result = await this.apollo
      .mutate({
        mutation: drop_user_request,
        variables: { user_idx, user_request_manager_idx }
      })
      .toPromise();
    return result;
  }

  public async insert_role(user_idx: number, role: string) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_role,
        variables: {
          user_idx,
          role
        }
      })
      .toPromise();
    return result.data.insert_role;
  }

  public async delete_role(user_idx: number, role: string) {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_role,
        variables: {
          user_idx,
          role
        }
      })
      .toPromise();
    return result.data.delete_role;
  }

  //포스트 resource 추가
  public async insert_post_resource(data: InputPostResource) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_post_resource,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_post_resource;
  }

  //아이템 resource 추가
  public async insert_item_resource(data: InputItemResource) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_item_resource,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_item_resource;
  }

  //아이템 resource 수정
  public async update_item_resource(data: InputItemResource) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_item_resource,
        variables: { data }
      })
      .toPromise();
    return result.data.update_item_resource;
  }

  //아이템 resource 추가
  public async insert_item_file(file: Upload, container: string, file_type: string) {
    const temp = await this.file_upload(file, container, file_type);
    return await this.insert_item_resource({
      item_resource_url: temp.file_url,
      item_resource_type: temp.file_kind,
      width: temp.width,
      height: temp.height
    });
  }

  //파일 업로드
  public async file_upload(file: Upload, container: string, file_type: string): Promise<UploadFile> {
    const result: any = await this.apollo
      .mutate({
        mutation: file_upload,
        variables: { file, container, file_type },
        context: {
          hasUpload: true
        }
      })
      .toPromise();
    return result.data.file_upload;
  }

  //아이템 추가
  public async insert_item(data: InputItem) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_item,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_item;
  }

  public async update_item(item_data: InputItem, item_delivery_data: InputItemDelivery): Promise<Item> {
    const result: any = await this.apollo
      .mutate({
        mutation: update_item,
        variables: {
          item_data,
          item_delivery_data
        }
      })
      .toPromise();
    return result.data.update_item;
  }

  public async update_item_is_open(item_idx_list, value) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_item_is_open,
        variables: {
          item_idx_list,
          value
        }
      })
      .toPromise();
    return result.data.update_item_is_open;
  }

  public async update_item_status(item_idx_list, value) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_item_status,
        variables: {
          item_idx_list,
          value
        }
      })
      .toPromise();
    return result.data.update_item_status;
  }

  //크롤링 아이템 추가
  public async insert_crawling_item(data: InputExcelItemList) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_crawling_item,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_crawling_item;
  }

  public async delete_item_admin(item_idx: number) {
    console.log('delete_item_admin-' + item_idx);
    const result: any = await this.apollo
      .mutate({
        mutation: delete_item_admin,
        variables: { item_idx }
      })
      .toPromise();
    return result.data.delete_item_admin;
  }

  /**
   * 옵션 삭제
   * @param item_option_idx
   */
  public async delete_item_option_admin(item_option_idx: number) {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_item_option_admin,
        variables: { item_option_idx }
      })
      .toPromise();
    return result.data.delete_item_option_admin;
  }

  public async delete_item_option(item_option_idx: number) {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_item_option,
        variables: { item_option_idx }
      })
      .toPromise();
    return result.data.delete_item_option;
  }

  /**
   * 옵션 추가
   * @param data
   */
  public async insert_item_option(data: InputItem): Promise<Item> {
    console.log('will update item =', data);
    const result: any = await this.apollo
      .mutate({
        mutation: insert_item_option,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.insert_item_option;
  }

  /**
   * 옵션 수정
   * @param data
   */
  public async update_item_option(data: InputItem): Promise<Item> {
    console.log('will update item =', data);
    const result: any = await this.apollo
      .mutate({
        mutation: update_item_option,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.update_item;
  }

  public async update_sales_channel_option(input_item_option_sales_channel: InputItemOptionSalesChannel, input_item_sales_channel: InputItemSalesChannel, input_item: InputItem): Promise<boolean> {
    const result: any = await this.apollo
      .mutate({
        mutation: update_sales_channel_option,
        variables: {
          input_item_option_sales_channel,
          input_item_sales_channel,
          input_item
        }
      })
      .toPromise();
    return result.data.update_sales_channel_option;
  }

  public async insert_sales_channel_option(input_item_option_sales_channel: InputItemOptionSalesChannel, input_item_sales_channel: InputItemSalesChannel, input_item: InputItem): Promise<boolean> {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_sales_channel_option,
        variables: {
          input_item_option_sales_channel,
          input_item_sales_channel,
          input_item
        }
      })
      .toPromise();
    return result.data.insert_sales_channel_option;
  }

  public async delete_sales_channel_option(item_option_idx: number, item_option_sales_channel_name: string, sales_channel_idx: number): Promise<boolean> {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_sales_channel_option,
        variables: {
          item_option_idx,
          item_option_sales_channel_name,
          sales_channel_idx
        }
      })
      .toPromise();
    return result.data.delete_sales_channel_option;
  }

  /**
   * 옵션 추가
   * @param data
   */
  public async insert_post_item(item_idx: number, list_order: number, post_idx: number): Promise<boolean> {
    // console.log('will update item =', data);
    console.log(item_idx, list_order, post_idx);
    const result: any = await this.apollo
      .mutate({
        mutation: insert_post_item,
        variables: {
          item_idx,
          list_order,
          post_idx
        }
      })
      .toPromise();
    return result.data.insert_post_item;
  }

  public async update_post_item_admin(data: InputPostItem): Promise<PostItem> {
    const result: any = await this.apollo
      .mutate({
        mutation: update_post_item_admin,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.update_post_item;
  }

  /**
   * 옵션 추가
   * @param data
   */
  public async delete_post_item(item_idx: number, post_idx: number): Promise<boolean> {
    // console.log('will update item =', data);
    const result: any = await this.apollo
      .mutate({
        mutation: delete_post_item,
        variables: {
          item_idx,
          post_idx
        }
      })
      .toPromise();
    return result.data.delete_post_item;
  }

  public async select_order_call_list(state: State): Promise<SelectOrderCallListReturn> {
    console.log('select_order_call_list');
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_order_call_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_order_call_list;
  }
  public async select_onelink_list(state: State): Promise<SelectOnelinkListReturn> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_onelink_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_onelink_list;
  }

  public async select_onelink_group_list(state: State): Promise<SelectOnelinkGroupListReturn> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_onelink_group_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_onelink_group_list;
  }

  public async get_onelink_group(onelink_group_idx): Promise<OnelinkGroup> {
    const temp = await this.select_onelink_group_list({
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'onelink_group_idx',
            operator: 'eq',
            value: `${onelink_group_idx}`
          }
        ]
      },
      skip: 0,
      take: 1
    });

    if (temp.rows.length > 0) {
      return temp.rows[0];
    } else {
      return null;
    }
  }

  public async get_onelink_list(onelink_idx): Promise<Onelink> {
    const temp = await this.select_onelink_list({
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'onelink_idx',
            operator: 'eq',
            value: `${onelink_idx}`
          }
        ]
      },
      skip: 0,
      take: 1
    });

    console.log('temp', temp);
    if (temp.rows.length > 0) {
      return temp.rows[0];
    } else {
      return null;
    }
  }

  public async update_order_call_admin(data: InputOrderCall): Promise<OrderCall> {
    const result: any = await this.apollo
      .mutate({
        fetchPolicy: 'no-cache',
        mutation: update_order_call_admin,
        variables: { data }
      })
      .toPromise();
    return result.data;
  }

  public async select_sync_item_stock_history_list(state: State): Promise<SelectSyncItemStockHistoryListReturn> {
    console.log('select_sync_item_stock_history_list');
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_sync_item_stock_history_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_sync_item_stock_history_list;
  }

  public async insert_temp_order(data: InputTempOrderList): Promise<boolean> {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_temp_order,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_temp_order;
  }

  public async select_temp_order_list(state: State): Promise<SelectTempOrderListReturn> {
    console.log('select_temp_order_list');
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_temp_order_list,
        variables: { state: state }
      })
      .toPromise();
    return result.data.select_temp_order_list;
  }

  public async que_temp_order_list(): Promise<SelectTempOrderListReturn> {
    console.log('que_temp_order_list');
    const result: any = await this.apollo
      .mutate({
        mutation: que_temp_order_list
      })
      .toPromise();
    return result.data.que_temp_order_list;
  }

  public async select_sales_channel_item_code_list(state: State): Promise<SelectSalesChannelItemCodeListReturn> {
    console.log('select_sales_channel_item_code_list');
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_sales_channel_item_code_list,
        variables: { state: state }
      })
      .toPromise();
    return result.data.select_sales_channel_item_code_list;
  }

  public async select_salse_channel_item_option_barcode_list(state: State) {
    console.log('select_salse_channel_item_option_barcode_list');
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_salse_channel_item_option_barcode_list,
        variables: { state: state }
      })
      .toPromise();
    return result.data.select_salse_channel_item_option_barcode_list;
  }

  public async search_item(state: State): Promise<SelectItemListReturn> {
    console.log('search_item');
    const result: any = await this.apollo
      .query({
        query: search_item,
        variables: { state: state }
      })
      .toPromise();
    return result.data.search_item;
  }

  public async search_item_option(state: State): Promise<SelectItemOptionListReturn> {
    console.log('search_item');
    const result: any = await this.apollo
      .query({
        query: search_item_option,
        variables: { state: state }
      })
      .toPromise();
    return result.data.search_item_option;
  }

  public async insert_sales_channel_item_code(data: InputSalesChannelItemCode) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_sales_channel_item_code,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.insert_sales_channel_item_code;
  }

  public async delete_sales_channel_item_code(sales_channel_item_code_idx: number) {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_sales_channel_item_code,
        variables: {
          sales_channel_item_code_idx
        }
      })
      .toPromise();
    return result.data.delete_sales_channel_item_code;
  }

  public async update_sales_channel_item_code(data: InputSalesChannelItemCode) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_sales_channel_item_code,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.update_sales_channel_item_code;
  }

  public async insert_sales_channel_item_option_barcode(data: InputSalesChannelItemOptionBarcode) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_sales_channel_item_option_barcode,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.insert_sales_channel_item_option_barcode;
  }

  public async update_sales_channel_item_option_barcode(data: InputSalesChannelItemOptionBarcode) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_sales_channel_item_option_barcode,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.update_sales_channel_item_option_barcode;
  }

  public async delete_sales_channel_item_option_barcode(sales_channel_item_option_barcode_idx: number) {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_sales_channel_item_option_barcode,
        variables: {
          sales_channel_item_option_barcode_idx
        }
      })
      .toPromise();
    return result.data.delete_sales_channel_item_code;
  }

  public async select_order_transaction_list(state: State): Promise<SelectOrderTransactionListReturn> {
    console.log('select_order_transaction_list');
    const result: any = await this.apollo
      .query({
        query: select_order_transaction_list,
        variables: { state: state }
      })
      .toPromise();
    return result.data.select_order_transaction_list;
  }

  public async allOrderTransactions(state: State): Promise<SelectOrderTransactionListReturn> {
    console.log('allOrderTransactions');
    const result: any = await this.apollo
      .query({
        query: allOrderTransactions,
        variables: { state: state }
      })
      .toPromise();
    console.log('allOrderTransactions result', result);

    return result.data.allOrderTransactions;
  }

  public async select_order_transaction_detail(state: State): Promise<SelectOrderTransactionListReturn> {
    console.log('select_order_transaction_detail');
    const result: any = await this.apollo
      .query({
        query: select_order_transaction_detail,
        variables: { state: state }
      })
      .toPromise();

    return result.data.select_order_transaction_detail;
  }

  public async select_delivery_order_list(state: State) {
    console.log('select_order_transaction_list state', state);
    const result: any = await this.apollo
      .query({
        query: select_delivery_order_list,
        variables: { state }
      })
      .toPromise();
    console.log('select_order_transaction_list result', result);

    return result.data.select_delivery_order_list;
  }

  public async select_delivery_order_list_for_pending(state: State) {
    console.log('select_order_transaction_list state', state);
    const result: any = await this.apollo
      .query({
        query: select_delivery_order_list_for_pending,
        variables: { state }
      })
      .toPromise();
    return result.data.select_delivery_order_list_for_pending;
  }

  public async select_delivery_order_transaction_list(state: State) {
    console.log('select_delivery_order_transaction_list state', state);
    const result: any = await this.apollo
      .query({
        query: select_delivery_order_transaction_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_delivery_order_transaction_list;
  }

  /**
   * @param order_transaction_idx
   * @param delivery_status
   * 배송상태를 변경하는 API
   */
  public async delivery_update_delivery_status_admin(order_transaction_idx: number, delivery_status: string, apply_all: boolean): Promise<OrderTransaction> {
    const result: any = await this.apollo
      .mutate({
        mutation: delivery_update_delivery_status_admin,
        variables: {
          order_transaction_idx,
          delivery_status,
          apply_all
        }
      })
      .toPromise();
    return result.data.delivery_update_delivery_status_admin;
  }

  /**
   * 임시 주문 상태 변경
   */
  public async update_temp_order_status(temp_order_idx: number, temp_order_status: number): Promise<TempOrder> {
    const result: any = await this.apollo
      .mutate({
        mutation: update_temp_order_status,
        variables: {
          temp_order_idx,
          temp_order_status
        }
      })
      .toPromise();
    return result.data.update_temp_order_status;
  }

  /**
   * 임시 주문 삭제
   */
  public async delete_temp_order(temp_order_idx: number): Promise<boolean> {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_temp_order,
        variables: {
          temp_order_idx
        }
      })
      .toPromise();
    return result.data.delete_temp_order;
  }

  /**
   *
   */
  public async que_temp_order_insert(): Promise<boolean> {
    console.log('que_temp_order_insert');
    const result: any = await this.apollo
      .mutate({
        mutation: que_temp_order_insert
      })
      .toPromise();
    return result.data.que_temp_order_insert;
  }

  public async insert_invoice_list(data: InputInvoiceList): Promise<boolean> {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_invoice_list,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_invoice_list;
  }

  public async select_invoice_list(state: State): Promise<SelectInvoiceListReturn> {
    console.log('select_invoice_list');
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_invoice_list,
        variables: { state: state }
      })
      .toPromise();
    return result.data.select_invoice_list;
  }

  /**
   * 이벤트
   */

  public async select_topic_list_admin(state: State): Promise<SelectTopicListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_topic_list_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.select_topic_list_admin;
  }

  public async update_topic_admin(data: InputTopic) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_topic_admin,
        variables: { data }
      })
      .toPromise();
    return result;
  }

  public async add_topic_admin(data: InputTopic) {
    const result: any = await this.apollo
      .mutate({
        mutation: add_topic_admin,
        variables: { data }
      })
      .toPromise();
    return result;
  }

  /**
   * 쇼핑
   */

  public async shop_select_shop_section_list_admin(state: State) {
    const result: any = await this.apollo
      .query({
        query: shop_select_shop_section_list_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.shop_select_shop_section_list_admin;
  }

  public search_shop_section_list_admin(state: State) {
    return this.apollo
      .query({
        query: search_shop_section_list_admin,
        variables: { state }
      })
      .pipe(
        map((result: any) => {
          console.log('search_shop_section_list_admin', result);
          return result.data.search_shop_section_list_admin.rows;
        })
      );
  }

  public search_live_tv_list_admin(state: State) {
    return this.apollo
      .query({
        query: search_live_tv_list_admin,
        variables: { state }
      })
      .pipe(
        map((result: any) => {
          console.log('search_live_tv_list_admin', result);
          return result.data.search_live_tv_list_admin.rows;
        })
      );
  }

  public async select_shop_section_item_list_admin(shop_section_idx: number) {
    const result: any = await this.apollo
      .query({
        query: select_shop_section_item_list_admin,
        variables: { shop_section_idx: shop_section_idx }
      })
      .toPromise();
    return result.data.select_shop_section_item_list_admin;
  }

  public async shop_insert_shop_section_admin(data: InputShopSection) {
    const result: any = await this.apollo
      .mutate({
        mutation: shop_insert_shop_section_admin,
        variables: { data }
      })
      .toPromise();
    return result.data.shop_insert_shop_section_admin;
  }

  public async shop_insert_shop_section_item_admin(data: InputShopSectionItem) {
    const result: any = await this.apollo
      .mutate({
        mutation: shop_insert_shop_section_item_admin,
        variables: { data }
      })
      .toPromise();
    return result.data.shop_insert_shop_section_item_admin;
  }

  public async shop_update_shop_section_admin(data: InputShopSection) {
    const result: any = await this.apollo
      .mutate({
        mutation: shop_update_shop_section_admin,
        variables: { data }
      })
      .toPromise();
    return result.data.shop_update_shop_section_admin;
  }

  public async update_shop_section_list_admin(data: InputShopSection[], offset: number) {
    const result: any = await this.apollo
      .mutate({
        mutation: shop_update_shop_section_list_admin,
        variables: { data, offset }
      })
      .toPromise();
    return result.data.shop_update_shop_section_list_admin;
  }

  public async delete_shop_section_admin(shop_section_idx: number) {
    const result: any = await this.apollo
      .mutate({
        mutation: shop_delete_shop_section_admin,
        variables: { shop_section_idx }
      })
      .toPromise();
    return result.data.shop_delete_shop_section_admin;
  }

  public async update_shop_section_item_admin(data: InputShopSectionItem) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_shop_section_item_admin,
        variables: { data }
      })
      .toPromise();
    return result.data.update_shop_section_item_admin;
  }

  public async shop_delete_shop_section_item_admin(shop_section_item_idx: number) {
    const result: any = await this.apollo
      .mutate({
        mutation: shop_delete_shop_section_item_admin,
        variables: { shop_section_item_idx }
      })
      .toPromise();
    return result.data.shop_delete_shop_section_item_admin;
  }

  public async copy_class_room_shop_section(data: InputCopyShopSection) {
    const result: any = await this.apollo
      .mutate({
        mutation: copy_class_room_shop_section,
        variables: { data }
      })
      .toPromise();
    return result.data.copy_class_room_shop_section;
  }

  public async shop_insert_shop_section_item_list_admin(data: InputShopSectionItem[]) {
    const result: any = await this.apollo
      .mutate({
        mutation: shop_insert_shop_section_item_list_admin,
        variables: { data }
      })
      .toPromise();
    return result.data.shop_insert_shop_section_item_list_admin;
  }

  /**
   * 결제타입 리스트
   * @param state
   */
  public async select_payment_type_list_admin(state: State): Promise<SelectPaymentTypeListReturn> {
    console.log('select_payment_type_list_admin');
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_payment_type_list_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.select_payment_type_list_admin;
  }

  /**
   * 결제타입 등록
   * @param data
   */
  public async insert_payment_type(data: InputPaymentType) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_payment_type,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.insert_payment_type;
  }

  /**
   * 결제타입 업데이트
   * @param data
   */
  public async update_payment_type(data: InputPaymentType) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_payment_type,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.update_payment_type;
  }

  public async admin_select_config_service_list(state: State) {
    const result: any = await this.apollo
      .query({
        query: admin_select_config_service_list,
        variables: { state }
      })
      .toPromise();
    console.log(result);
    return result.data.admin_select_config_service_list;
  }

  /**
   * config_service 업뎃
   */

  public async admin_update_config_service(data): Promise<boolean> {
    const result: any = await this.apollo
      .mutate({
        mutation: admin_update_config_service,
        variables: { data }
      })
      .toPromise();
    return result.data.admin_update_config_service;
  }

  /**
   * 판매처 코드 엑셀로 등록하기
   * @param data
   */
  public async admin_insert_sales_channel_item_code_excel(data: InputSalesChannelItemCodeExcelList): Promise<boolean> {
    const result: any = await this.apollo
      .mutate({
        mutation: admin_insert_sales_channel_item_code_excel,
        variables: { data }
      })
      .toPromise();
    return result.data.admin_insert_sales_channel_item_code_excel;
  }

  /*
   * 결제 취소
   */
  public async payment_cancel_all_admin(data: InputOrderCancel) {
    const result: any = await this.apollo
      .mutate({
        mutation: payment_cancel_all_admin,
        variables: { data }
      })
      .toPromise();
    return result.data.payment_cancel_all_admin;
  }

  public async point_check_left_admin(user_idx: number): Promise<number> {
    const result: any = await this.apollo
      .query({
        query: point_check_left_admin,
        variables: { user_idx }
      })
      .toPromise();
    return result.data.point_check_left_admin;
  }

  /*
   * 유저 포인트 조회
   */
  public async point_select_user_point_list_admin(user_idx: number): Promise<SelectPointAllInfoReturn> {
    const result: any = await this.apollo
      .query({
        query: point_select_user_point_list_admin,
        variables: { user_idx }
      })
      .toPromise();
    return result.data.point_select_user_point_list_admin;
  }

  /*
   * 유저 포인트 적립
   */
  public async point_create_user_point_admin(data: InputPoint): Promise<Point> {
    const result: any = await this.apollo
      .mutate({
        mutation: point_create_user_point_admin,
        variables: { data }
      })
      .toPromise();
    return result.data.point_create_user_point_admin;
  }

  /*
   * 유저 포인트 무효화
   */
  public async point_expire_user_point_admin(point_idx: number): Promise<Point> {
    const result: any = await this.apollo
      .mutate({
        mutation: point_expire_user_point_admin,
        variables: { point_idx }
      })
      .toPromise();
    return result.data.point_expire_user_point_admin;
  }

  /**
   * 모든 포인트 조회
   */
  public async point_select_point_list_admin(state: State): Promise<SelectPointListReturn> {
    const result: any = await this.apollo
      .query({
        query: point_select_point_list_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.point_select_point_list_admin;
  }

  public async point_select_point_ready_list_admin(state: State): Promise<SelectPointListReturn> {
    const result: any = await this.apollo
      .query({
        query: point_select_point_ready_list_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.point_select_point_ready_list_admin;
  }

  /**
   * 포인트 대량 생성
   */
  public async point_create_bulk_list_admin(data: InputPointList) {
    const result: any = await this.apollo
      .mutate({
        mutation: point_create_bulk_list_admin,
        variables: { data }
      })
      .toPromise();
    return result;
  }

  public async merge_item_admin(data: InputMergeItem) {
    const result: any = await this.apollo
      .mutate({
        mutation: merge_item_admin,
        variables: { data }
      })
      .toPromise();
    return result.data.merge_item_admin;
  }

  public async merge_item_option_admin(data: InputMergeItemOption) {
    const result: any = await this.apollo
      .mutate({
        mutation: merge_item_option_admin,
        variables: { data }
      })
      .toPromise();
    return result.data.merge_item_option_admin;
  }

  public async get_item_option_admin(item_option_barcode: string) {
    console.log('get_item_option_admin');
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: get_item_option_admin,
        variables: { item_option_barcode }
      })
      .toPromise();
    return result.data.get_item_option_admin;
  }

  public async delivery_update_order_item_admin(data: InputOrderItem) {
    const result: any = await this.apollo
      .mutate({
        mutation: delivery_update_order_item_admin,
        variables: { data }
      })
      .toPromise();
    return result.data.delivery_update_order_item_admin;
  }

  public async shop_select_shop_section_group_admin(state: State, shop_section_group_type: string): Promise<SelectShopSectionGroupListReturn> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: shop_select_shop_section_group_admin,
        variables: {
          state,
          shop_section_group_type
        }
      })
      .toPromise();
    return result.data.shop_select_shop_section_group_admin;
  }
  public async shop_delete_shop_section_group_admin(shop_section_group_idx: number): Promise<boolean> {
    const result: any = await this.apollo
      .mutate({
        fetchPolicy: 'no-cache',
        mutation: shop_delete_shop_section_group_admin,
        variables: {
          shop_section_group_idx
        }
      })
      .toPromise();
    return result.data.shop_delete_shop_section_group_admin;
  }

  public shop_search_section_list(state: State) {
    return this.apollo
      .query({
        query: shop_select_shop_section_list_admin,
        variables: { state: state }
      })
      .pipe(
        map((result: any) => {
          console.log('shop_search_section_list map -', result);
          result.data.shop_select_shop_section_list_admin.rows.forEach((section) => {
            section.display = `[${section.shop_section_idx}] ${section.shop_section_title} - ${section.shop_section_type})`;
          });
          return result.data.shop_select_shop_section_list_admin.rows;
        })
      );
  }

  public async shop_insert_shop_section_group(data: InputShopSectionGroup) {
    const result = await this.apollo
      .mutate({
        mutation: shop_insert_shop_section_group,
        variables: { data }
      })
      .toPromise();
    return result;
  }

  public async shop_update_shop_section_group(data: InputShopSectionGroup) {
    const result = await this.apollo
      .mutate({
        mutation: shop_update_shop_section_group,
        variables: { data }
      })
      .toPromise();
    return result;
  }

  public async shop_update_shop_section_group_list_admin(data: InputShopSectionGroup[], offset: number): Promise<boolean> {
    const result: any = await this.apollo
      .mutate({
        mutation: shop_update_shop_section_group_list_admin,
        variables: { data, offset }
      })
      .toPromise();
    return result.data.shop_update_shop_section_group_list_admin;
  }

  public async post_select_fumi_tv_list_admin(state: State) {
    const result: any = await this.apollo
      .query({
        query: post_select_fumi_tv_list_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.post_select_fumi_tv_list_admin;
  }

  public async post_select_live_tv_list_admin(state: State) {
    const result: any = await this.apollo
      .query({
        query: post_select_live_tv_list_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.post_select_live_tv_list_admin;
  }

  public async post_select_fumi_tv_admin(post_idx: number): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: post_select_fumi_tv_admin,
        variables: { post_idx }
      })
      .toPromise();
    return result.data.post_select_fumi_tv_admin;
  }

  public async post_insert_fumi_tv(data: InputPost) {
    const result = await this.apollo
      .mutate({
        mutation: post_insert_fumi_tv,
        variables: { data }
      })
      .toPromise();
    return result;
  }

  public async post_update_fumi_tv(data: InputPost) {
    const result = await this.apollo
      .mutate({
        mutation: post_update_fumi_tv,
        variables: { data }
      })
      .toPromise();
    return result;
  }

  public async post_update_fumi_tv_live(data: InputPostTv) {
    const result = await this.apollo
      .mutate({
        mutation: post_update_fumi_tv_live,
        variables: { data }
      })
      .toPromise();
    return result;
  }

  public async post_save_fumi_tv_live(data: InputPostTv): Promise<PostTv> {
    const result: any = await this.apollo
      .mutate({
        mutation: post_save_fumi_tv_live,
        variables: { data }
      })
      .toPromise();
    console.log('post_save_fumi_tv_live', result);
    return result.data.post_save_fumi_tv_live;
  }

  public async shop_select_category_list_admin(state: State): Promise<SelectCategoryListReturn> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: shop_select_category_list_admin,
        variables: {
          state
        }
      })
      .toPromise();
    return result.data.shop_select_category_list_admin;
  }

  public shop_search_category_list_admin(state: State) {
    return this.apollo
      .query({
        query: shop_select_category_list_admin,
        variables: { state: state }
      })
      .pipe(
        map((result: any) => {
          console.log('shop_search_category_list_admin map -', result);
          result.data.shop_select_category_list_admin.rows.forEach((category) => {
            const grand_parent_category_name = category.parent_category ? (category.parent_category.parent_category ? category.parent_category.parent_category.category_name + ' >> ' : '') : '';
            const parent_category_name = category.parent_category ? category.parent_category.category_name + ' >> ' : '';
            category.category_name = `${grand_parent_category_name} ${parent_category_name} [${category.category_idx}] ${category.category_name}`;
          });
          return result.data.shop_select_category_list_admin.rows;
        }),
        share()
      );
  }

  public search_store_list_admin(state: State) {
    return this.apollo
      .query({
        query: search_store_list_admin,
        variables: { state: state }
      })
      .pipe(
        map((result: any) => {
          return result.data.search_store_list_admin.rows;
        }),
        share()
      );
  }

  public async shop_insert_category_admin(data: InputCategory): Promise<Category> {
    const result: any = await this.apollo
      .mutate({
        mutation: shop_insert_category_admin,
        variables: { data }
      })
      .toPromise();
    return result.data.shop_insert_category_admin;
  }

  public async shop_update_category_admin(data: InputCategory): Promise<Category> {
    const result: any = await this.apollo
      .mutate({
        mutation: shop_update_category_admin,
        variables: { data }
      })
      .toPromise();
    return result.data.shop_update_category_admin;
  }

  public async post_delete_fumi_tv(post_idx: number) {
    const result = await this.apollo
      .mutate({
        mutation: post_delete_fumi_tv,
        variables: { post_idx }
      })
      .toPromise();
    return result;
  }

  public async delete_fumi_tv(post_tv_idx: number) {
    const result = await this.apollo
      .mutate({
        mutation: delete_fumi_tv,
        variables: { post_tv_idx }
      })
      .toPromise();
    return result;
  }

  public async shop_delete_category_admin(data: Category) {
    const result: any = await this.apollo
      .mutate({
        mutation: shop_delete_category_admin,
        variables: { data }
      })
      .toPromise();
    return result.data.shop_delete_category_admin;
  }

  public async post_full_sync_post_tv_admin() {
    const result: any = await this.apollo
      .mutate({
        mutation: post_full_sync_post_tv_admin
      })
      .toPromise();
    return result.data.post_full_sync_post_tv_admin;
  }

  public async full_sync_store_admin() {
    const result: any = await this.apollo
      .mutate({
        mutation: full_sync_store_admin
      })
      .toPromise();
    return result.data.full_sync_store_admin;
  }

  public async post_select_featured_content(idx: number): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: post_select_featured_content_admin,
        variables: { idx }
      })
      .toPromise();
    return result.data.post_select_featured_content_admin;
  }

  public async post_select_featured_content_list(state: State): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: post_select_featured_content_list_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.post_select_featured_content_list_admin;
  }

  public async post_insert_featured_content(post_data: InputPost, featured_content_data: InputFeaturedContent): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: post_insert_featured_content_admin,
        variables: { post_data, featured_content_data }
      })
      .toPromise();
    return result.data.post_insert_featured_content_admin;
  }

  public async post_update_featured_content(post_data: InputPost, featured_content_data: InputFeaturedContent): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: post_update_featured_content_admin,
        variables: { post_data, featured_content_data }
      })
      .toPromise();
    return result.data.post_update_featured_content_admin;
  }

  public async post_delete_featured_content(idx: number): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: post_delete_featured_content_admin,
        variables: { idx }
      })
      .toPromise();
    return result.data.post_delete_featured_content_admin;
  }

  public async tag_insert_tag_from_post_list_admin(data: InputPost[]) {
    const result: any = await this.apollo
      .mutate({
        mutation: tag_insert_tag_from_post_list_admin,
        variables: { data }
      })
      .toPromise();
    return result.data.tag_insert_tag_from_post_list_admin;
  }

  public async event_select_event_list(state: State): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: event_select_event_list_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.event_select_event_list_admin;
  }

  public async_event_select_event_list(state: State) {
    return this.apollo
      .query({
        query: event_select_event_list_admin,
        variables: { state }
      })
      .pipe(
        map((result: any) => {
          console.log('event_select_event_list_admin', result);
          return result.data.event_select_event_list_admin.rows;
        })
      );
  }

  public async event_select_event(event_idx: number): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: event_select_event_admin,
        variables: { event_idx }
      })
      .toPromise();
    return result.data.event_select_event_admin;
  }

  public async event_insert_event(event_data: InputEvent): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: event_insert_event_admin,
        variables: { event_data }
      })
      .toPromise();
    return result.data.event_insert_event_admin;
  }

  public async event_update_event(event_data: InputEvent): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: event_update_event_admin,
        variables: { event_data }
      })
      .toPromise();
    return result.data.event_update_event_admin;
  }

  public async event_delete_event(event_idx: number): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: event_delete_event_admin,
        variables: { event_idx }
      })
      .toPromise();
    return result.data.event_delete_event_admin;
  }

  public async login_agency(user_id: string, user_pw: string) {
    const result: any = await this.apollo
      .mutate({
        mutation: login_agency,
        variables: { user_id, user_pw }
      })
      .toPromise();
    return result.data.login_agency;
  }

  public async regist_agency(user_id: string, user_pw: string, user_name: string) {
    const result: any = await this.apollo
      .mutate({
        mutation: regist_agency,
        variables: { user_id, user_pw, user_name }
      })
      .toPromise();
    return result.data.regist_agency;
  }

  public async insert_request_manager(company_name: string, user_depart: string, user_name: string) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_request_manager,
        variables: { company_name, user_depart, user_name }
      })
      .toPromise();
    return result.data.insert_request_manager;
  }

  public async select_request_manager_one(): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: select_request_manager_one
      })
      .toPromise();
    return result.data.select_request_manager_one;
  }

  public async content_order_insert_content_order(content_order_data: InputContentOrder): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: content_order_insert_content_order_admin,
        variables: { content_order_data }
      })
      .toPromise();
    return result.data.content_order_insert_content_order_admin;
  }

  public async content_order_select_content_order(content_order_idx: number): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: content_order_select_content_order_admin,
        variables: { content_order_idx }
      })
      .toPromise();
    return result.data.content_order_select_content_order_admin;
  }

  public async content_order_select_content_order_list(state: State): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: content_order_select_content_order_list_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.content_order_select_content_order_list_admin;
  }

  public async content_order_update_content_order(content_order_data: InputContentOrder): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: content_order_update_content_order_admin,
        variables: { content_order_data }
      })
      .toPromise();
    return result.data.content_order_update_content_order_admin;
  }

  public async content_order_update_content_order_list(content_order_list_data: InputContentOrder[]): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: content_order_update_content_order_list_admin,
        variables: { content_order_list_data }
      })
      .toPromise();
    return result.data.content_order_update_content_order_list_admin;
  }

  public async content_order_delete_content_order(content_order_idx: number): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: content_order_delete_content_order_admin,
        variables: { content_order_idx }
      })
      .toPromise();
    return result.data.content_order_delete_content_order_admin;
  }

  public async notice_dialog_select_notice_dialog_admin(state: State): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: notice_dialog_select_notice_dialog_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.notice_dialog_select_notice_dialog_admin;
  }

  public async notice_dialog_insert_notice_dialog_admin(notice_dialog_data: InputNoticeDialog): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: notice_dialog_insert_notice_dialog_admin,
        variables: { notice_dialog_data }
      })
      .toPromise();
    return result.data.notice_dialog_insert_notice_dialog_admin;
  }

  public async notice_dialog_update_notice_dialog_admin(notice_dialog_data: InputNoticeDialog): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: notice_dialog_update_notice_dialog_admin,
        variables: { notice_dialog_data }
      })
      .toPromise();
    return result.data.notice_dialog_update_notice_dialog_admin;
  }

  public async notice_dialog_delete_notice_dialog_admin(notice_dialog_data: NoticeDialog): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: notice_dialog_delete_notice_dialog_admin,
        variables: { notice_dialog_data }
      })
      .toPromise();
    return result.data.notice_dialog_delete_notice_dialog_admin;
  }

  public async insert_dev_order_transaction_admin(item_option: InputItemOption, user_idx: number): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_dev_order_transaction_admin,
        variables: { item_option, user_idx }
      })
      .toPromise();
    return result.data.insert_dev_order_transaction_admin;
  }

  public async insert_item_review_admin(data: InputPost, post_resource_list: Array<InputPostResource>, order_item_idx: number) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_item_review_admin,
        variables: { data, post_resource_list, order_item_idx }
      })
      .toPromise();
    return result.data.insert_item_review_admin;
  }

  public async coupon_select_coupon_rule_list_admin(state: State): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: coupon_select_coupon_rule_list_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.coupon_select_coupon_rule_list_admin;
  }

  public async coupon_select_coupon_rule_admin(coupon_rule_idx: number): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: coupon_select_coupon_rule_admin,
        variables: { coupon_rule_idx }
      })
      .toPromise();
    return result.data.coupon_select_coupon_rule_admin;
  }

  public async coupon_insert_coupon_rule_admin(coupon_rule_data: InputCouponRule): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: coupon_insert_coupon_rule_admin,
        variables: { coupon_rule_data }
      })
      .toPromise();
    return result.data.coupon_insert_coupon_rule_admin;
  }

  public async coupon_update_coupon_rule_admin(coupon_rule_data: InputCouponRule): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: coupon_update_coupon_rule_admin,
        variables: { coupon_rule_data }
      })
      .toPromise();
    return result.data.coupon_update_coupon_rule_admin;
  }

  public async coupon_select_coupon_rule_group_list_admin(state: State): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: coupon_select_coupon_rule_group_list_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.coupon_select_coupon_rule_group_list_admin;
  }

  public async coupon_select_coupon_rule_group_admin(coupon_rule_group_idx: number): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: coupon_select_coupon_rule_group_admin,
        variables: { coupon_rule_group_idx }
      })
      .toPromise();
    return result.data.coupon_select_coupon_rule_group_admin;
  }

  public async coupon_insert_coupon_rule_group_admin(coupon_rule_group_data: InputCouponRuleGroup): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: coupon_insert_coupon_rule_group_admin,
        variables: { coupon_rule_group_data }
      })
      .toPromise();
    return result.data.coupon_insert_coupon_rule_group_admin;
  }

  public async coupon_update_coupon_rule_group_admin(coupon_rule_group_data: InputCouponRuleGroup): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: coupon_update_coupon_rule_group_admin,
        variables: { coupon_rule_group_data }
      })
      .toPromise();
    return result.data.coupon_update_coupon_rule_group_admin;
  }

  public async coupon_select_coupon_by_user_idx_admin(state: State, user_idx: number): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: coupon_select_coupon_by_user_idx_admin,
        variables: { state, user_idx }
      })
      .toPromise();
    return result.data.coupon_select_coupon_by_user_idx_admin;
  }

  public async shop_update_category_idx_from_category_list_admin(data: InputItem[]): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: shop_update_category_idx_from_category_list_admin,
        variables: { data }
      })
      .toPromise();
    return result.data.shop_update_category_idx_from_category_list_admin;
  }

  public async delivery_update_order_item_list_admin(data: DeliveryOrderItem[], delivery_status: string): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: delivery_update_order_item_list_admin,
        variables: { data, delivery_status }
      })
      .toPromise();
    return result.data.delivery_update_order_item_list_admin;
  }

  public async delivery_update_delivery_address_admin(order_transaction_idx: number, address: string, address_detail: string): Promise<any> {
    const result = await this.apollo
      .mutate({
        mutation: delivery_update_delivery_address_admin,
        variables: {
          order_transaction_idx,
          address,
          address_detail
        }
      })
      .toPromise();
    return result;
  }

  public async delete_duplicate_tag_content(): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_duplicate_tag_content
      })
      .toPromise();
    return result.data.delete_duplicate_tag_content;
  }

  public async delete_duplicate_tag(): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_duplicate_tag
      })
      .toPromise();
    return result.data.delete_duplicate_tag;
  }

  public async select_admin_log_list(state: State): Promise<any> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_admin_log_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_admin_log_list;
  }

  public async select_chat_join_admin(state: State): Promise<SelectChatJoinListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_chat_join_admin,
        variables: { state }
      })
      .toPromise();
    return result.data.select_chat_join_admin;
  }

  public async select_chat_message(chat_room_idx: number, state: State): Promise<SelectChatMessageListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_chat_message,
        variables: { chat_room_idx, state }
      })
      .toPromise();
    return result.data.select_chat_message;
  }

  public async insert_chat_message(data: InputChatMessage) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_chat_message,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_chat_message;
  }

  async insert_chat_join_heart_beat_admin(data: InputChatJoinHeartBeat) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_chat_join_heart_beat_admin,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_chat_join_heart_beat_admin;
  }

  async chat_join_heart_beat(chat_room_idx: number, is_on: boolean) {
    return await this.insert_chat_join_heart_beat_admin({
      user_idx: 1,
      chat_room_idx,
      is_on,
      heart_beat_datetime: new Date()
    });
  }

  public async update_order_item_payment_status_admin(order_item_idx: number, status: string) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_order_item_payment_status_admin,
        variables: { order_item_idx, status }
      })
      .toPromise();
    return result.data.update_order_item_payment_status_admin;
  }

  public async get_chat_token(): Promise<string> {
    const result: any = await this.apollo
      .mutate({
        mutation: get_chat_token
      })
      .toPromise();
    return result.data.get_chat_token;
  }

  public async insert_chat_room(data: InputChatRoom) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_chat_room,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_chat_room;
  }

  public async after_insert_chat_message(data: InputChatRoom) {
    const result: any = await this.apollo
      .mutate({
        mutation: after_insert_chat_message,
        variables: { data }
      })
      .toPromise();
    return result.data.after_insert_chat_message;
  }

  public async insert_onelink(data: InputOnelink) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_onelink,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_onelink;
  }

  public async insert_onelink_group(data: InputOnelinkGroup) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_onelink_group,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_onelink_group;
  }

  public async update_onelink_group_only_meta(data: InputOnelinkGroup) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_onelink_group_only_meta,
        variables: { data }
      })
      .toPromise();
    return result.data.update_onelink_group_only_meta;
  }

  public async admin_delete_user(user_idx: number) {
    const result: any = await this.apollo
      .mutate({
        mutation: admin_delete_user,
        variables: { user_idx }
      })
      .toPromise();
    return result.data.admin_delete_user;
  }

  public async admin_copy_item(item_idx: number) {
    const result: any = await this.apollo
      .mutate({
        mutation: admin_copy_item,
        variables: { item_idx }
      })
      .toPromise();
    return result.data.admin_copy_item;
  }

  public async update_onelink(data: InputOnelink) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_onelink,
        variables: { data }
      })
      .toPromise();
    return result.data.update_onelink;
  }

  public async update_onelink_only_meta(data: InputOnelink) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_onelink_only_meta,
        variables: { data }
      })
      .toPromise();
    return result.data.update_onelink_only_meta;
  }

  public async delete_onelink(data: InputOnelink) {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_onelink,
        variables: { data }
      })
      .toPromise();
    return result.data.update_onelink;
  }

  public async create_shotlink(url: string) {
    const result: any = await this.apollo
      .mutate({
        mutation: create_shotlink,
        variables: { url }
      })
      .toPromise();
    return result.data.create_shotlink;
  }

  public async insert_que(que_name: string): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: insert_que,
        variables: { que_name }
      })
      .toPromise();
    return result.data.insert_que;
  }

  public async item_image_migration(item_idx: number) {
    const result: any = await this.apollo
      .mutate({
        mutation: item_image_migration,
        variables: {
          item_idx
        }
      })
      .toPromise();
    return result.data.item_image_migration;
  }

  //사용자 목록
  public async select_onelink_analytics(state: State) {
    const result: any = await this.apollo
      .query({
        query: select_onelink_analytics,
        variables: { state }
      })
      .toPromise();
    return result.data.select_onelink_analytics;
  }

  public async select_bigquery_analytics(state: State) {
    const result: any = await this.apollo
      .query({
        query: select_bigquery_analytics,
        variables: { state }
      })
      .toPromise();
    return result.data.select_bigquery_analytics;
  }

  public async select_user_link_list(state: State) {
    const result: any = await this.apollo
      .query({
        query: select_user_link_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_user_link_list;
  }

  public async select_bank_account_log_list(state: State) {
    const result: any = await this.apollo
      .query({
        query: select_bank_account_log_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_bank_account_log_list;
  }

  public async select_bank_transfer_list(state: State) {
    const result: any = await this.apollo
      .query({
        query: select_bank_transfer_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_bank_transfer_list;
  }

  public async select_class_room_incentive_list(state: State) {
    const result: any = await this.apollo
      .query({
        query: select_class_room_incentive_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_class_room_incentive_list;
  }

  public async select_class_room_list(state: State) {
    const result: any = await this.apollo
      .query({
        query: select_class_room_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_class_room_list;
  }

  public async get_class_room(user_idx: number) {
    const state = {
      skip: 0,
      take: 1,
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'user_idx',
            operator: 'eq',
            value: `${user_idx}`
          }
        ]
      }
    };

    const result: any = await this.apollo
      .query({
        query: select_class_room_list,
        variables: { state }
      })
      .toPromise();

    const temp_result = result.data.select_class_room_list;
    if (temp_result.count == 0) {
      return null;
    } else {
      return temp_result.rows[0];
    }
  }

  public async request_class_room_incentive_process() {
    const result: any = await this.apollo
      .mutate({
        mutation: request_class_room_incentive_process,
        variables: {}
      })
      .toPromise();
    return result.data.request_class_room_incentive_process;
  }

  /**
   * 배너 업데이트
   * @param data
   */
  public async request_class_room_incentive_done(class_room_incentive_idx: number) {
    const result: any = await this.apollo
      .mutate({
        mutation: request_class_room_incentive_done,
        variables: {
          class_room_incentive_idx
        }
      })
      .toPromise();
    return result.data.request_class_room_incentive_done;
  }

  /**
   * 배너 업데이트
   * @param data
   */
  public async update_bank_account_log(data: InputBankAccountLog) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_bank_account_log,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.update_bank_account_log;
  }

  public async update_bank_transfer(data: InputBankTransfer) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_bank_transfer,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.update_bank_transfer;
  }

  //반품승인
  public async confirm_refund(data: InputOrderCancel) {
    const result: any = await this.apollo
      .mutate({
        mutation: confirm_refund,
        variables: { data }
      })
      .toPromise();
    return result.data.confirm_refund;
  }

  //취소신청
  public async insert_admin_order_cancel(data: InputOrderCancel) {
    const result = await this.apollo
      .mutate({
        mutation: insert_admin_order_cancel,
        variables: { data }
      })
      .pipe(
        map((result: any) => {
          return result.data.insert_admin_order_cancel;
        })
      )
      .toPromise();
    return result;
  }

  public async confirm_cancel_fee(data: InputOrderCancel) {
    const result = await this.apollo
      .mutate({
        mutation: confirm_cancel_fee,
        variables: { data }
      })
      .pipe(
        map((result: any) => {
          return result.data.confirm_cancel_fee;
        })
      )
      .toPromise();
    return result;
  }

  public async update_order_cancel(data: InputOrderCancel) {
    data = this.filterKeyData(data, Keys_OrderCancel);

    const result = await this.apollo
      .mutate({
        mutation: update_order_cancel,
        variables: { data }
      })
      .pipe(
        map((result: any) => {
          return result.data.update_order_cancel;
        })
      )
      .toPromise();
    return result;
  }

  public async reject_order_cancel(data: InputOrderCancel) {
    data = { order_cancel_idx: data.order_cancel_idx };
    const result: any = await this.apollo
      .mutate({
        mutation: reject_order_cancel,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.reject_order_cancel;
  }

  public async insert_admin_memo(data: InputAdminMemo) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_admin_memo,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.insert_admin_memo;
  }

  public async update_basket_coupon_enable(data: InputBasketCouponCompany) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_basket_coupon_enable,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.update_basket_coupon_enable;
  }

  public async update_order_item(data: InputOrderItem) {
    data = this.filterKeyData(data, Keys_OrderItem);

    console.log('filtered', data);
    const result: any = await this.apollo
      .mutate({
        mutation: update_order_item,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.update_order_item;
  }

  public async update_order_transaction(data: InputOrderTransaction) {
    data = this.filterKeyData(data, Keys_OrderTransaction);

    console.log('filtered', data);
    const result: any = await this.apollo
      .mutate({
        mutation: update_order_transaction,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.update_order_transaction;
  }

  public async delete_admin_memo(data: InputAdminMemo) {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_admin_memo,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.delete_admin_memo;
  }

  public async insert_admin_log(data: InputAdminLog) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_admin_log,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.insert_admin_log;
  }

  public async select_order_cancel_list(state: State) {
    const result: any = await this.apollo
      .query({
        query: select_order_cancel_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_order_cancel_list;
  }

  public async select_class_room_point_list(state: State) {
    const result: any = await this.apollo
      .query({
        query: select_class_room_point_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_class_room_point_list;
  }

  public async insert_fumist_point(data: InputClassRoomPoint) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_fumist_point,
        variables: {
          data
        }
      })
      .toPromise();
    return result.data.insert_fumist_point;
  }
}

// notice_dialog_insert_notice_dialog_admin
// notice_dialog_delete_notice_dialog_admin
