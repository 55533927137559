import gql from 'graphql-tag';
export const bankTransferFragment = gql`
fragment bankTransferFragment on BankTransfer {
    bank_transfer_idx
    user_idx
    order_transaction_idx
    order_item_idx
    payment_idx
    check_sum
    bank_name
    bank_account
    bank_owner
    bank_code
    amount
    status
    complete_datetime
    memo
    error
    regist_datetime
    update_datetime
  }
`;

export const classRoomPointFragment = gql`
fragment classRoomPointFragment on ClassRoomPoint {
    class_room_point_idx
    class_room_idx
    class_join_idx
    point_amount
    point_type
    receive_user_idx
    expire_date
    point_idx
    point_status
    point_title
    insert_key
    regist_datetime
    update_datetime
    insert_point_datetime
  }
`;

export const classRoomFragment = gql`
fragment classRoomFragment on ClassRoom {
    class_room_idx
    class_name
    user_idx
    title
    description
    total_user_count
    total_order_count
    total_order_price
    total_incentive_price
    total_confirmed_order_price
    total_confirmed_incentive_price
    total_canceled_order_price
    total_canceled_count
    current_ready_incentive_price
    current_confirmed_incentive_price
    class_img_url
    class_status
    regist_datetime
    update_datetime
    max_user_count
    class_level
    parent_class_room
    is_leader
    is_super_leader
    current_order_price
    current_point_balance
    total_visit_count
    is_point_add_enable
    current_incentive_price
    current_shop_point_balance
    is_enable_incentive_pay
  }
`;



export const orderTransactionFragment = gql`
  fragment orderTransactionFragment on OrderTransaction {
    order_transaction_idx
    order_object_id
    user_idx
    sales_channel_idx
    sales_channel_order_number
    sales_channel_order_number2
    sales_channel_item_code_idx
    sales_channel_request_number
    ref_user_idx
    ref_post_idx
    address
    address_detail
    post_code
    request
    order_user_name
    order_user_phone
    receiver_name
    receiver_phone
    receiver_phone_backup
    order_transaction_title
    order_transaction_img_url
    total_count
    total_origin_price
    total_sell_price
    total_cancel_price
    total_refund_price
    total_admin_plus_price
    total_external_plus_price
    total_user_pay_price
    total_cancel_user_pay_price
    total_buy_price
    total_platform_fee
    total_payment_fee
    total_use_point
    total_cancel_point
    total_add_point
    total_payment_price
    total_delivery_fee
    total_company_delivery_fee
    total_use_coupon_price
    total_cancel_coupon_price
    payment_type
    payment_idx
    coupon_idx
    order_status
    payment_status
    payment_confirm_datetime
    payment_confirm_user_idx
    order_transaction_memo
    delivery_status
    auto_order_complete_sms
    auto_payment_complete_sms
    regist_datetime
    update_datetime
    create_user_idx
    update_user_idx
    manager_user_idx
    invoice_delivery_company_name
    invoice_num
    order_num
    device
    delivery_request_datetime
    delivery_regist_invoice_datetime
    ref_idx
    ref_table
    onelink_idx
    app_open_pid
    app_open_campaign
    app_open_adset
    arrival_source
    point_ready_idx
    order_transaction_pending_idx
    ip
    is_deleted
  }
`;

export const paymentCancelRequestFragment = gql`
fragment paymentCancelRequestFragment on PaymentCancelRequest {
    payment_cancel_request_idx
    pg
    payment_method
    payment_code
    cancel_amount
    cancel_reason
    refund_request_code
    refund_request_response
    refund_request_message
    regist_datetime
    update_datetime
    payment_idx
    order_transaction_idx
}
`;

export const orderCancelFragment = gql`
  fragment orderCancelFragment on OrderCancel {
    order_cancel_idx
    user_idx
    order_transaction_idx
    order_item_idx_list
    create_user_idx
    cancel_type
    request_type
    total_cancel_price
    total_refund_price
    cancel_fee
    cancel_user_pay_price
    refund_user_pay_price
    cancel_point
    refund_point
    cancel_coupon_price
    cancel_status
    change_status
    refund_status
    cancel_reason
    cancel_message
    cancel_result
    user_pay_fee
    user_pay_fee_confirm_status
    user_pay_fee_confirm_datetime
    user_pay_fee_confirm_user_idx
    item_inspect_status
    item_inspect_datetime
    item_inspect_message
    item_inspect_user_idx
    item_return_invoice_num
    item_return_delivery_company_name
    item_return_invoice_regist_datetime
    item_return_request_datetime
    item_return_request_user_idx
    item_change_invoice_num
    item_change_delivery_company_name
    item_change_invoice_regist_datetime
    refund_confirm_user_idx
    refund_confirm_status
    refund_confirm_datetime
    refund_payment_type
    refund_payment_error
    refund_request_code
    refund_request_message
    refund_request_response
    refund_request_tid
    refund_payment_datetime
    send_cancel_message_status
    send_cancel_message_datetime
    send_cancel_mssage_error
    memo
    update_datetime
    regist_datetime
    manager_check_datetime
    reject_order_cancel_datetime
    reject_message
    next_check_datetime
    is_admin_create
  }
`;




export const orderItemFragment = gql`
  fragment orderItemFragment on OrderItem {
    order_item_idx
    user_idx
    order_transaction_idx
    sales_channel_idx
    item_idx
    item_option_idx
    order_count
    item_option_text
    sell_price
    origin_price
    buy_price
    admin_plus_price
    user_pay_price
    external_plus_price
    platform_fee
    payment_fee
    delivery_fee
    company_delivery_fee
    use_point
    add_point
    order_item_status
    order_item_title
    order_item_delivery_status
    order_item_payment_status
    invoice
    invoice_num
    invoice_delivery_company_name
    invoice_check_datetime
    delivery_complete_datetime
    delivery_complete_sms_send_datetime
    order_num
    order_turn
    regist_datetime
    update_datetime
    create_user_idx
    update_user_idx
    manager_user_idx
    delivery_regist_invoice_datetime
    apply_stock
    adjustment_idx
    arrival_source
    app_open_pid
    app_open_campaign
    app_open_adset
    onelink_idx
    ref_idx
    ref_table
    point_ready_idx
    point_ready_ref_idx
    reword_check_datetime
    reword_status
    reword_memo
    memo
    shop_cart_idx
    order_item_payment_confirm_datetime
    order_item_payment_confirm_user_idx
    ip
    order_item_complete_order_datetime
    review_enable_datetime
    write_review_datetime
    order_item_cancel_datetime
    order_item_cancel_status
    order_item_change_status
    order_item_refund_status
    incentive_check_datetime
    incentive_check_status
    incentive_check_momo
    order_cancel_idx
    is_deleted
  }
`;


export const chatRoomFragment = gql`
  fragment chatRoomFragment on ChatRoom {
    chat_room_idx
    master_user_idx
    chat_room_key
    chat_room_type
    chat_room_title
    chat_room_img_url
    recent_message
    join_user_count
    ref_idx
    ref_table
    regist_datetime
    update_datetime
  }
`;

export const chatJoinFragment = gql`
  fragment chatJoinFragment on ChatJoin {
    chat_join_idx
    user_idx
    chat_room_idx
    join_status
    is_on
    new_message_count
    push_alarm_status
    heart_beat_datetime
    reject_message
    regist_datetime
    update_datetime
  }
`;

export const chatJoinWithRoomFragment = gql`
  fragment chatJoinWithRoomFragment on ChatJoin {
    ...chatJoinFragment
    chat_room {
      ...chatRoomFragment
    }
  }
  ${chatJoinFragment}
  ${chatRoomFragment}
`;





export const select_user_list_only = gql`
  query select_user_list_only($state: State) {
    select_user_list_only(state: $state) {
      count
      rows {
        user_idx
        login_type
        source
        user_id
        social_id
        access_token
        user_name
        user_email
        user_phone
        user_birthday
        user_gender
        user_profile
        age_range
        push_alarm_onoff
        ph_authen
        ph_authen_datetime
        user_img_url
        purchase_count
        login_count
        total_post_count
        total_reply_count
        total_like_count
        total_following_count
        total_follower_count
        last_login_datetime
        accept_policy_datetime
        user_droped_datetime
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const select_user_list_admin = gql`
  query select_user_list_admin($state: State) {
    select_user_list_admin(state: $state) {
      count
      rows {
        user_idx
        login_type
        source
        user_id
        social_id
        access_token
        user_name
        user_email
        user_phone
        user_birthday
        user_gender
        user_profile
        age_range
        address_book_list {
          address_book_idx
          address
          address_detail
          receiver_name
        }
        order_list {
          order_transaction_idx
          sales_channel_idx
          address
          address_detail
          total_buy_price
          payment_type
          payment_status
          order_item_list {
            order_item_idx
            order_item_title
            order_item_delivery_status
          }
          create_user {
            user_idx
            user_name
          }
          regist_datetime
        }
        push_alarm_onoff
        ph_authen
        ph_authen_datetime
        user_img_url
        purchase_count
        login_count
        total_post_count
        total_reply_count
        total_like_count
        total_following_count
        total_follower_count
        last_login_datetime
        accept_policy_datetime
        user_droped_datetime
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const select_user = gql`
  query select_user_admin($user_idx: Int!) {
    select_user_admin(user_idx: $user_idx) {
      user_idx
      login_type
      source
      user_id
      social_id
      access_token
      user_name
      user_email
      user_phone
      user_birthday
      user_gender
      user_profile
      age_range
      address_book_list {
        address_book_idx
        address
        address_detail
        receiver_name
      }
      order_list {
        order_transaction_idx
        sales_channel_idx
        address
        address_detail
        total_buy_price
        total_user_pay_price
        payment_type
        payment_status
        order_item_list {
          order_item_idx
          order_item_title
          order_item_delivery_status
        }
        create_user {
          user_idx
          user_name
        }
        regist_datetime
      }
      push_alarm_onoff
      ph_authen
      ph_authen_datetime
      user_img_url
      purchase_count
      login_count
      total_post_count
      total_reply_count
      total_like_count
      total_following_count
      total_follower_count
      last_login_datetime
      accept_policy_datetime
      user_droped_datetime
      regist_datetime
      update_datetime
    }
  }
`;


export const get_user = gql`
  query get_user($user_idx: Int!) {
    get_user(user_idx: $user_idx) {
      user_idx
      login_type
      source
      user_id
      social_id
      access_token
      user_name
      user_email
      user_phone
      user_birthday
      user_gender
      user_profile
      age_range
      push_alarm_onoff
      ph_authen
      ph_authen_datetime
      user_img_url
      purchase_count
      login_count
      total_post_count
      total_reply_count
      total_like_count
      total_following_count
      total_follower_count
      last_login_datetime
      accept_policy_datetime
      user_droped_datetime
      regist_datetime
      update_datetime
    }
  }
`;


export const sync_all_item_option_stock = gql`
  mutation sync_all_item_option_stock {
    sync_all_item_option_stock
  }
`;

export const sync_item_option_stock = gql`
  mutation sync_item_option_stock($item_option_idx: Int!) {
    sync_item_option_stock(item_option_idx: $item_option_idx)
  }
`;

export const sync_all_invoice = gql`
  mutation sync_all_invoice {
    sync_all_invoice
  }
`;

export const sync_invoice = gql`
  mutation sync_invoice($order_transaction_idx: Int!) {
    sync_invoice(order_transaction_idx: $order_transaction_idx)
  }
`;

export const update_invoice_complete_admin = gql`
  mutation update_invoice_complete_admin {
    update_invoice_complete_admin
  }
`;

export const delete_duplicate_tag_content = gql`
  mutation delete_duplicate_tag_content {
    delete_duplicate_tag_content
  }
`;

export const delete_duplicate_tag = gql`
  mutation delete_duplicate_tag {
    delete_duplicate_tag
  }
`;

export const select_user_sales_info_admin = gql`
  query select_user_sales_info_admin($user_idx: Int!) {
    select_user_sales_info_admin(user_idx: $user_idx) {
      user_sales_info_idx
      user_idx
      religion
      wedding_anniversary
      hobby
      personality
      job
      company
      job_position
      social_meeting
      health_state
      product_interest
      current_using_product
      use_experience_our
      use_experience_other
      product_image
      preferred_product
    }
  }
`;

export const select_user_sales_info_list_admin = gql`
  query select_user_sales_info_list_admin($state: State!) {
    select_user_sales_info_list_admin(state: $state) {
      count
      rows {
        user_sales_info_idx
        user_idx
        religion
        wedding_anniversary
        hobby
        personality
        job
        company
        job_position
        social_meeting
        health_state
        product_interest
        current_using_product
        use_experience_our
        use_experience_other
        product_image
        preferred_product
      }
    }
  }
`;

export const upsert_user_sales_info_admin = gql`
  mutation upsert_user_sales_info_admin($data: InputUserSalesInfo!) {
    upsert_user_sales_info_admin(data: $data) {
      user_sales_info_idx
      user_idx
      religion
      wedding_anniversary
      hobby
      personality
      job
      company
      job_position
      social_meeting
      health_state
      product_interest
      current_using_product
      use_experience_our
      use_experience_other
      product_image
      preferred_product
    }
  }
`;

export const select_user_sales_family_info_list_admin = gql`
  query select_user_sales_family_info_list_admin($state: State!) {
    select_user_sales_family_info_list_admin(state: $state) {
      count
      rows {
        user_sales_family_info_idx
        user_idx
        family_name
        relationship
        birthday
        wedding_anniversary
        health_state
        job
        company
        job_position
        memo
      }
    }
  }
`;

export const upsert_user_sales_family_info_admin = gql`
  mutation upsert_user_sales_family_info_admin(
    $data: InputUserSalesFamilyInfo!
  ) {
    upsert_user_sales_family_info_admin(data: $data) {
      user_sales_family_info_idx
      user_idx
      family_name
      relationship
      birthday
      wedding_anniversary
      health_state
      job
      company
      job_position
      memo
    }
  }
`;

export const add_user = gql`
  mutation add_user($data: InputUser!) {
    add_user(data: $data) {
      user_idx
      user_name
      user_img_url
      user_phone
    }
  }
`;
export const confirm_refund = gql`
  mutation confirm_refund($data: InputOrderCancel!) {
    confirm_refund(data: $data) {
      order_cancel_idx
    }
  }
`;

export const insert_admin_order_cancel = gql`
  mutation insert_admin_order_cancel($data: InputOrderCancel!) {
    insert_admin_order_cancel(data: $data) {
      order_cancel_idx
    }
  }
`;

export const insert_admin_memo = gql`
  mutation insert_admin_memo($data: InputAdminMemo!) {
    insert_admin_memo(data: $data) {
      admin_memo_idx
    }
  }
`;

export const update_basket_coupon_enable = gql`
  mutation update_basket_coupon_enable($data: InputBasketCouponCompany!) {
    update_basket_coupon_enable(data: $data)
  }
`;




export const reject_order_cancel = gql`
  mutation reject_order_cancel($data: InputOrderCancel!) {
    reject_order_cancel(data: $data) {
      order_cancel_idx
    }
  }
`;



export const insert_fumist_point = gql`
  mutation insert_fumist_point($data: InputClassRoomPoint!) {
    insert_fumist_point(data: $data) 
  }
`;


export const delete_admin_memo = gql`
  mutation delete_admin_memo($data: InputAdminMemo!) {
    delete_admin_memo(data: $data) 
  }
`;







export const insert_admin_log = gql`
  mutation insert_admin_log($data: InputAdminLog!) {
    insert_admin_log(data: $data) 
  }
`;



export const update_order_item = gql`
  mutation update_order_item($data: InputOrderItem!) {
    update_order_item(data: $data) {
      ...orderItemFragment
    }
  }
  ${orderItemFragment}
`;

export const update_order_transaction = gql`
  mutation update_order_transaction($data: InputOrderTransaction!) {
    update_order_transaction(data: $data) {
      ...orderTransactionFragment
    }
  }
  ${orderTransactionFragment}
`;



export const confirm_cancel_fee = gql`
  mutation confirm_cancel_fee($data: InputOrderCancel!) {
    confirm_cancel_fee(data: $data) {
      order_cancel_idx
    }
  }
`;
export const update_order_cancel = gql`
  mutation update_order_cancel($data: InputOrderCancel!) {
    update_order_cancel(data: $data) {
      order_cancel_idx
    }
  }
`;




export const insert_post = gql`
  mutation insert_post(
    $data: InputPost!
    $post_resource_list: [InputPostResource!]!
  ) {
    insert_post(
      data: $data
      post_resource_list: $post_resource_list
    ) {
      post_idx
    }
  }
`;

export const insert_post_resource = gql`
  mutation insert_post_resource($data: InputPostResource!) {
    insert_post_resource(data: $data) {
      post_resource_idx
    }
  }
`;

export const insert_item_resource = gql`
  mutation insert_item_resource($data: InputItemResource!) {
    insert_item_resource(data: $data) {
      item_resource_idx
      item_idx
      item_resource_type
      item_resource_url
      item_resource_content
      width
      height
      color
      regist_datetime
      update_datetime
    }
  }
`;

export const update_item_resource = gql`
  mutation update_item_resource($data: InputItemResource!) {
    update_item_resource(data: $data) {
      item_resource_idx
      item_idx
      item_resource_type
      item_resource_url
      item_resource_content
      width
      height
      color
    }
  }
`;

export const post_update_post_admin = gql`
  mutation post_update_post_admin(
    $data: InputPost!
    $post_resource_list: [InputPostResource!]!
  ) {
    post_update_post_admin(
      data: $data
      post_resource_list: $post_resource_list
    ) {
      post_idx
    }
  }
`;

export const update_user = gql`
  mutation update_user($user_idx: Int!, $data: InputUser!) {
    update_user(user_idx: $user_idx, data: $data) {
      user_idx
    }
  }
`;

export const insert_contest_review = gql`
  mutation insert_contest_review($data: InputContestReview!) {
    insert_contest_review(data: $data) {
      contest_review_idx
      point_image
      point_pose
      point_story
      comment
      final_result
    }
  }
`;

export const insert_contest_review_comment = gql`
  mutation insert_contest_review_comment($data: InputContestReview!) {
    insert_contest_review_comment(data: $data) {
      contest_review_idx
      point_image
      point_pose
      point_story
      comment
      final_result
    }
  }
`;

export const insert_contest_review_point = gql`
  mutation insert_contest_review_point($data: InputContestReview!) {
    insert_contest_review_point(data: $data) {
      contest_review_idx
      point_image
      point_pose
      point_story
      comment
      final_result
    }
  }
`;

export const delete_user = gql`
  mutation delete_user($user_idx: Int!) {
    delete_user(user_idx: $user_idx)
  }
`;

export const delete_post_admin = gql`
  mutation delete_post_admin($post_idx: Int!) {
    delete_post_admin(post_idx: $post_idx)
  }
`;

export const update_user_info = gql`
  mutation update_user_info(
    $user_idx: Int!
    $user_name: String!
    $user_phone: String!
  ) {
    update_user_info(
      user_idx: $user_idx
      user_name: $user_name
      user_phone: $user_phone
    ) {
      user_idx
      user_name
      user_phone
    }
  }
`;

export const select_login_type = gql`
  query select_login_type {
    select_login_type {
      rows
    }
  }
`;

export const select_source_list = gql`
  query select_source_list {
    select_source_list {
      rows
    }
  }
`;

export const select_sales_channel_list = gql`
  query select_sales_channel_list($state: State) {
    select_sales_channel_list(state: $state) {
      count
      rows {
        sales_channel_idx
        sales_channel_id
        sales_channel_name
        sales_channel_fee_rate
        sales_channel_description
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const search_analytics_list = gql`
  query search_analytics_list(
    $state: State
    $analytics_interval: String
  ) {
    search_analytics_list(
      state: $state
      analytics_interval: $analytics_interval
    ) {
      count
      rows {
        sales_channel_idx
        sales_channel_name
        total_count
        total_sell_price
        total_buy_price
        total_payment_fee
        total_platform_fee
        total_user_pay_price
        total_origin_price
        target_date
      }
      sales_channel_list {
        sales_channel_name
        sales_channel_idx
      }
      range
    }
  }
`;

export const search_user_analytics_list = gql`
  query search_user_analytics_list(
    $state: State
    $analytics_interval: String
  ) {
    search_user_analytics_list(
      state: $state
      analytics_interval: $analytics_interval
    ) {
      regist_user
      access_user
      post
      reply
      total_regist_user
      total_post
      total_reply
    }
  }
`;

export const select_reviewer_review_list = gql`
  query select_reviewer_review_list(
    $state: State!
    $review_status: String!
  ) {
    select_reviewer_review_list(
      state: $state
      review_status: $review_status
    ) {
      rows {
        post_idx
        preview_img_url
        title
        description
        like_count
        reply_count
        open_status
        regist_datetime
        update_datetime
        post_resource_list {
          width
          height
          post_resource_url
        }
        user {
          user_idx
          user_name
          user_img_url
          user_profile
          user_phone
        }
        contest_review {
          contest_review_idx
          review_user_idx
          point_pose
          point_image
          point_extra
          point_story
          final_result
          post_idx
          comment
        }
      }
      count
    }
  }
`;

//

export const select_reply_list_admin = gql`
  query select_reply_list_admin($post_idx: Int!) {
    select_reply_list_admin(post_idx: $post_idx) {
      count
      rows {
        reply_idx
        user_idx
        post_idx
        content
        parent_reply_idx
        admin_check_status
        user {
          user_idx
          user_name
        }
      }
    }
  }
`;

export const insert_reply_admin = gql`
  mutation insert_reply_admin($data: InputReply!) {
    insert_reply_admin(data: $data) {
      reply_idx
      user_idx
      post_idx
      content
      admin_check_status
    }
  }
`;

export const update_reply_admin = gql`
  mutation update_reply_admin($data: InputReply!) {
    update_reply_admin(data: $data)
  }
`;

export const insert_like_admin = gql`
  mutation insert_like_admin($post_idx: Int!, $user_idx: Int!) {
    insert_like_admin(post_idx: $post_idx, user_idx: $user_idx)
  }
`;

export const is_like_admin = gql`
  query is_like_admin($post_idx: Int!, $user_idx: Int!) {
    is_like_admin(post_idx: $post_idx, user_idx: $user_idx)
  }
`;

export const select_review_list = gql`
  query select_review_list($state: State) {
    select_review_list(state: $state) {
      count
      rows {
        post_idx
        post {
          post_idx
          preview_img_url
          title
          description
          like_count
          reply_count
          regist_datetime
          update_datetime
          user {
            user_idx
            user_name
            user_phone
            login_type
            source
            user_img_url
          }
        }
        review_user {
          user_name
          user_img_url
          user_profile
          user_phone
        }
        contest_review_idx
        review_user_idx
        point_pose
        point_image
        point_extra
        point_story
        final_result
        point_total
        comment
      }
    }
  }
`;

export const select_all_post_list = gql`
  query select_all_post_list($state: State) {
    select_all_post_list(state: $state) {
      count
      rows {
        post_idx
        user_idx
        title
        description
        preview_img_url
        reply_count
        like_count
        view_count
        admin_check_status
        open_status
        post_type
        regist_datetime
        update_datetime
        tag_list
        user {
          user_idx
          user_name
          user_phone
          user_gender
          user_img_url
          user_profile
          login_type
        }
        post_resource_list {
          post_resource_idx
          post_resource_url
          post_resource_type
          width
          height
          color
        }
        like_list {
          user {
            user_name
            user_idx
            user_img_url
          }
        }
        post_item_list {
          item {
            item_idx
            main_item_resource {
              item_resource_url
              width
              height
              color
            }
          }
        }
        reply_list {
          content
          regist_datetime
          user_idx
          user {
            user_idx
            user_name
            user_img_url
          }
        }
      }
    }
  }
`;

export const allOrderTransactions = gql`
  query allOrderTransactions($state: State) {
    allOrderTransactions(state: $state) {
      count
      rows {
        order_transaction_idx
        order_object_id
        user_idx
        sales_channel_idx
        sales_channel_order_number
        address
        address_detail
        request
        post_code
        order_transaction_memo
        order_transaction_title
        order_user_name
        order_user_phone
        receiver_name
        receiver_phone
        receiver_phone_backup
        total_count
        payment_idx
        total_sell_price
        total_user_pay_price
        total_admin_plus_price
        total_external_plus_price
        total_origin_price
        total_buy_price
        total_platform_fee
        total_payment_fee
        total_company_delivery_fee
        total_use_point
        total_add_point
        total_user_pay_price
        total_delivery_fee
        total_use_coupon_price
        ref_user_idx
        ref_post_idx
        payment_type
        order_status
        payment_status
        delivery_status
        payment_confirm_datetime
        regist_datetime
        update_datetime
        invoice_num
        invoice_delivery_company_name
        user {
          user_idx
          user_name
          user_phone
          user_refund_account_list {
            user_refund_account_idx
            user_idx
            account_number
            bank_name
            account_holder
          }
        }
        sales_channel {
          sales_channel_idx
          sales_channel_name
          sales_channel_fee_rate
        }
        payment_list {
          payment_idx
          payment_code
          payment_price
          payment_status
          payment_type
          payment_method
          cancel_price
          cancel_datetime
          cancel_reason
          update_user_idx
          confirm_datetime
          regist_datetime
        }
        payment {
          payment_idx
          payment_code
          payment_price
          payment_status
          payment_account
          payment_type
          payment_method
          cancel_price
          cancel_datetime
          update_user_idx
          confirm_datetime
          regist_datetime
        }
        order_item_list {
          order_item_idx
          item_idx
          order_item_title
          order_count
          sell_price
          buy_price
          external_plus_price
          user_pay_price
          admin_plus_price
          order_item_payment_status
          order_item_delivery_status
          invoice_num
          invoice_delivery_company_name
          item {
            title
            title_internal
          }
          item_option {
            item_option_name
            item_option_code
            item_option_barcode
          }
        }
        create_user {
          user_idx
          user_name
        }
        update_user {
          user_idx
          user_name
        }
        coupon {
          coupon_rule {
            title
            min_order_amount
          }
        }
      }
    }
  }
`;

export const get_order_transaction = gql`
  query get_order_transaction($order_transaction_idx: Int!) {
    get_order_transaction(
      order_transaction_idx: $order_transaction_idx
    ) {
      order_transaction_idx
      order_object_id
      user_idx
      sales_channel_idx
      sales_channel_order_number
      address
      request
      post_code
      order_transaction_memo
      order_transaction_title
      order_user_name
      order_user_phone
      receiver_name
      receiver_phone
      receiver_phone_backup
      total_count
      payment_idx
      total_sell_price
      total_user_pay_price
      total_admin_plus_price
      total_external_plus_price
      total_origin_price
      total_buy_price
      total_platform_fee
      total_payment_fee
      total_company_delivery_fee
      total_use_point
      total_add_point
      total_user_pay_price
      total_delivery_fee
      ref_user_idx
      ref_post_idx
      payment_type
      order_status
      payment_status
      delivery_status
      payment_confirm_datetime
      regist_datetime
      update_datetime
      user {
        user_id
        user_name
        user_phone
      }
      sales_channel {
        sales_channel_idx
        sales_channel_name
        sales_channel_fee_rate
      }
      payment_list {
        payment_idx
        payment_code
        payment_price
        payment_account
        payment_status
        payment_type
        payment_method
        update_user_idx
        confirm_datetime
        regist_datetime
      }
      payment {
        payment_idx
        payment_code
        payment_price
        payment_status
        payment_account
        payment_type
        payment_method
        update_user_idx
        confirm_datetime
        regist_datetime
      }
      order_item_list {
        order_item_idx
        item_idx
        order_item_title
        sell_price
        buy_price
        external_plus_price
        user_pay_price
        admin_plus_price
        item_option {
          item_option_name
          item_option_code
          item_option_barcode
        }
      }
    }
  }
`;

export const allOrderItems = gql`
  query allOrderItems($state: State) {
    allOrderItems(state: $state) {
      count
      rows {
        order_transaction_idx
        user_idx
        regist_datetime
        update_datetime
        order_item_title
        order_item_delivery_status
        order_item_payment_status
        order_item_payment_status
        sell_price
        buy_price
        origin_price
        external_plus_price
        user_pay_price
        admin_plus_price
        platform_fee
        payment_fee
        delivery_fee
        company_delivery_fee
        user {
          user_idx
          user_name
          user_phone
        }
        order_transaction {
          total_count
          total_buy_price
          total_origin_price
          total_sell_price
          total_payment_fee
          total_platform_fee
          total_use_point
          total_add_point
          total_delivery_fee
          total_user_pay_price
          total_admin_plus_price
          total_external_plus_price
          total_company_delivery_fee
          payment_status

          sales_channel {
            sales_channel_idx
            sales_channel_name
            sales_channel_fee_rate
          }
          payment_list {
            payment_type
            payment_price
            payment_status
            confirm_datetime
          }
        }
        order_item_idx
        item_idx
        item {
          title
          item_sell_price
          item_buy_price
          item_origin_price
          item_delivery_fee
          item_company_delivery_fee
        }
        item_option_idx
        item_option_text
        item_option {
          item_option_idx
          item_option_name
          item_option_code
          item_option_barcode
        }
      }
    }
  }
`;

export const search_user_list = gql`
  query search_user_list($state: State) {
    search_user_list(state: $state) {
      count
      rows {
        user_idx
        user_name
        user_phone
        source
        login_type
        regist_datetime
        update_datetime
        user_img_url
        address_book_list {
          address_title
          address_book_idx
          address
          address_detail
          receiver_phone
          receiver_phone_backup
          post_code
          receiver_name
        }
      }
    }
  }
`;

export const search_item_list = gql`
  query search_item_list($state: State) {
    search_item_list(state: $state) {
      count
      rows {
        item_idx
        title
        item_image_url
        item_buy_price
        item_sell_price
        item_origin_price
        item_option_list {
          item_option_idx
          item_option_name
          item_option_barcode
          item_option_stock
          item_option_plus_sell_price
          item_option_plus_buy_price
        }
        item_sales_channel_list {
          item_sales_channel_plus_price
          item_sales_channel_fee
          sales_channel_idx
          sales_channel {
            sales_channel_idx
            sales_channel_name
          }
        }
      }
    }
  }
`;


export const search_item_list_admin = gql`
  query search_item_list_admin($state: State) {
    search_item_list_admin(state: $state) {
      count
      rows {
        item_idx
        title
        item_image_url
        item_buy_price
        item_sell_price
        item_origin_price
        item_option_list {
          item_option_idx
          item_option_name
          item_option_barcode
          item_option_stock
          item_option_plus_sell_price
          item_option_plus_buy_price
        }
      }
    }
  }
`;




export const select_order_transaction_list = gql`
  query select_order_transaction_list($state: State) {
    select_order_transaction_list(state: $state) {
      count
      rows {
        order_transaction_idx
        order_object_id
        user_idx
        sales_channel_idx
        sales_channel_order_number
        address
        address_detail
        request
        post_code
        order_transaction_memo
        order_transaction_title
        order_user_name
        order_user_phone
        receiver_name
        receiver_phone
        receiver_phone_backup
        total_count
        payment_idx
        total_sell_price
        total_user_pay_price
        total_admin_plus_price
        total_external_plus_price
        total_origin_price
        total_buy_price
        total_platform_fee
        total_payment_fee
        total_company_delivery_fee
        total_use_point
        total_add_point
        total_user_pay_price
        total_delivery_fee
        total_use_coupon_price
        ref_user_idx
        ref_post_idx
        payment_type
        order_status
        payment_status
        delivery_status
        payment_confirm_datetime
        regist_datetime
        update_datetime
        invoice_num
        invoice_delivery_company_name
        user {
          user_idx
          user_name
          user_phone
        }
        sales_channel {
          sales_channel_idx
          sales_channel_name
          sales_channel_fee_rate
        }
      }
    }
  }
`;




export const select_order_transaction_detail = gql`
  query select_order_transaction_detail($state: State) {
    select_order_transaction_detail(state: $state) {
      count
      rows {
        ...orderTransactionFragment
        user {
          user_idx
          user_name
          user_phone
          user_refund_account_list {
            user_refund_account_idx
            user_idx
            account_number
            bank_name
            account_holder
          }
        }
        sales_channel {
          sales_channel_idx
          sales_channel_name
          sales_channel_fee_rate
        }
        payment_list {
          payment_idx
          payment_code
          payment_price
          payment_status
          payment_type
          payment_method
          cancel_price
          cancel_datetime
          cancel_reason
          update_user_idx
          confirm_datetime
          regist_datetime
        }
        payment {
          payment_idx
          payment_code
          payment_price
          payment_status
          payment_account
          payment_type
          payment_method
          cancel_price
          cancel_datetime
          update_user_idx
          confirm_datetime
          regist_datetime
        }
        order_item_list {
          ...orderItemFragment
          item {
            title
            title_internal
          }
          item_option {
            item_option_name
            item_option_code
            item_option_barcode
          }
        } 
        create_user {
          user_idx
          user_name
        }
        update_user {
          user_idx
          user_name
        }
        coupon {
          coupon_rule {
            title
            min_order_amount
          }
        }
        order_cancel_list{
          ...orderCancelFragment
        }
        payment_cancel_request_list{
          ...paymentCancelRequestFragment
        }
      }
    }
  }
  ${orderTransactionFragment}
  ${orderItemFragment}
  ${orderCancelFragment}
  ${paymentCancelRequestFragment}
  
`;

export const migration_data = gql`
  mutation migration_data($data: String!) {
    migration_data(data: $data) {
      user_idx
    }
  }
`;

export const request_excel_download = gql`
  mutation request_excel_download($state: State!) {
    request_excel_download(state: $state)
  }
`;

export const delivery_update_delivery_address_admin = gql`
  mutation delivery_update_delivery_address_admin(
    $order_transaction_idx: Int!
    $address: String!
    $address_detail: String!
  ) {
    delivery_update_delivery_address_admin(
      order_transaction_idx: $order_transaction_idx
      address: $address
      address_detail: $address_detail
    )
  }
`;

export const insert_admin_order_transaction = gql`
  mutation insert_admin_order_transaction($data: InputOrder!) {
    insert_admin_order_transaction(data: $data) {
      order_transaction_idx
    }
  }
`;

export const payment_update_order_transaction_delivery_admin = gql`
  mutation payment_update_order_transaction_delivery_admin(
    $data: InputOrderTransaction!
  ) {
    payment_update_order_transaction_delivery_admin(data: $data) {
      order_transaction_idx
      order_object_id
      user_idx
      sales_channel_idx
      sales_channel_order_number
      sales_channel_order_number2
      sales_channel_item_code_idx
      sales_channel_request_number
      ref_user_idx
      ref_post_idx
      address
      address_detail
      post_code
      request
      order_user_name
      order_user_phone
      receiver_name
      receiver_phone
      receiver_phone_backup
      order_transaction_title
      order_transaction_img_url
      total_count
      total_origin_price
      total_sell_price
      total_admin_plus_price
      total_external_plus_price
      total_user_pay_price
      total_buy_price
      total_platform_fee
      total_payment_fee
      total_use_point
      total_add_point
      total_payment_price
      total_delivery_fee
      total_company_delivery_fee
      payment_type
      payment_idx
      order_status
      payment_status
      payment_confirm_datetime
      order_transaction_memo
      delivery_status
      auto_order_complete_sms
      auto_payment_complete_sms
      regist_datetime
      update_datetime
      create_user_idx
      update_user_idx
      manager_user_idx
      invoice_delivery_company_name
      invoice_num
      order_num
      delivery_request_datetime
      delivery_regist_invoice_datetime
      ref_idx
      ref_table
    }
  }
`;

export const payment_update_order_transaction_item_admin = gql`
  mutation payment_update_order_transaction_item_admin(
    $data: InputOrder!
  ) {
    payment_update_order_transaction_item_admin(data: $data) {
      order_transaction_idx
      order_object_id
      user_idx
      sales_channel_idx
      sales_channel_order_number
      address
      address_detail
      request
      post_code
      order_transaction_memo
      order_transaction_title
      order_user_name
      order_user_phone
      receiver_name
      receiver_phone
      receiver_phone_backup
      total_count
      payment_idx
      total_sell_price
      total_user_pay_price
      total_admin_plus_price
      total_external_plus_price
      total_origin_price
      total_buy_price
      total_platform_fee
      total_payment_fee
      total_company_delivery_fee
      total_use_point
      total_add_point
      total_user_pay_price
      total_delivery_fee
      ref_user_idx
      ref_post_idx
      payment_type
      order_status
      payment_status
      delivery_status
      payment_confirm_datetime
      regist_datetime
      update_datetime
      user {
        user_idx
        user_name
        user_phone
      }
      sales_channel {
        sales_channel_idx
        sales_channel_name
        sales_channel_fee_rate
      }
      payment_list {
        payment_idx
        payment_code
        payment_price
        payment_status
        payment_type
        update_user_idx
        confirm_datetime
        regist_datetime
      }
      payment {
        payment_idx
        payment_code
        payment_price
        payment_status
        payment_account
        payment_type
        update_user_idx
        confirm_datetime
        regist_datetime
      }
      order_item_list {
        order_item_idx
        item_idx
        order_item_title
        sell_price
        buy_price
        external_plus_price
        user_pay_price
        admin_plus_price
        item_option {
          item_option_name
          item_option_code
          item_option_barcode
        }
      }
      create_user {
        user_idx
        user_name
      }
      update_user {
        user_idx
        user_name
      }
    }
  }
`;

export const insert_payment = gql`
  mutation insert_payment($data: InputPayment!) {
    insert_payment(data: $data) {
      payment_idx
      order_transaction_idx
    }
  }
`;

export const insert_user_card_number = gql`
  mutation insert_user_card_number($data: InputUserCardNumber!) {
    insert_user_card_number(data: $data) {
      user_card_number_idx
      user_idx
      card_number
      expire_date
      card_company
      card_owner
      card_type
    }
  }
`;

export const delete_user_card_number = gql`
  mutation delete_user_card_number($user_card_number_idx: Int!) {
    delete_user_card_number(
      user_card_number_idx: $user_card_number_idx
    ) {
      user_card_number_idx
    }
  }
`;

export const search_user_card_number = gql`
  query search_user_card_number($user_idx: Int!) {
    search_user_card_number(user_idx: $user_idx) {
      count
      rows {
        user_card_number_idx
        card_code
        card_number
        card_company
        card_owner
        card_type
        seq
        expire_date
        regist_datetime
      }
    }
  }
`;

export const update_order_payment = gql`
  mutation update_order_payment($data: InputOrderPayment!) {
    update_order_payment(data: $data) {
      order_transaction_idx
    }
  }
`;

export const insert_sms = gql`
  mutation insert_sms($data: InputSms!) {
    insert_sms(data: $data)
  }
`;

export const select_sms_list_admin = gql`
  query select_sms_list_admin($state: State!) {
    select_sms_list_admin(state: $state) {
      count
      rows {
        sms_idx
        user_idx
        title
        phone
        message
        sms_ref_idx
        sms_ref_table
        regist_datetime
        update_datetime
        send_result_text
        send_result
      }
    }
  }
`;

export const update_payment_status = gql`
  mutation update_payment_status($data: InputUpdatePaymentStatus!) {
    update_payment_status(data: $data) {
      payment_idx
    }
  }
`;

export const request_card_pay = gql`
  mutation request_card_pay($data: InputPayCard!) {
    request_card_pay(data: $data) {
      result
      result_message
    }
  }
`;

export const payment_pay_billkey_admin = gql`
  mutation payment_pay_billkey_admin(
    $data: InputIamportRequestBill!
    $user_idx: Int!
  ) {
    payment_pay_billkey_admin(data: $data, user_idx: $user_idx)
  }
`;

export const payment_select_card_list_admin = gql`
  query payment_select_card_list_admin($state: State!) {
    payment_select_card_list_admin(state: $state) {
      count
      rows {
        subscribe_card_info_idx
        user_idx
        card_number
        card_code
        card_name
        customer_uid
        is_deleted
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const payment_issue_card_admin = gql`
  mutation payment_issue_card_admin(
    $data: InputIssueBillingKeyInfo!
  ) {
    payment_issue_card_admin(data: $data)
  }
`;

export const payment_delete_card_admin = gql`
  mutation payment_delete_card_admin($data: InputSubscribeCardInfo!) {
    payment_delete_card_admin(data: $data)
  }
`;

export const confirm_order_transaction_payment = gql`
  mutation confirm_order_transaction_payment(
    $data: InputUpdatePaymentStatus!
  ) {
    confirm_order_transaction_payment(data: $data) {
      order_transaction_idx
    }
  }
`;

export const payment_cancel_all_admin = gql`
  mutation payment_cancel_all_admin($data: InputOrderCancel!) {
    payment_cancel_all_admin(data: $data) {
      order_cancel_idx
    }
  }
`;



export const cancel_pending_order_payment = gql`
  mutation cancel_pending_order_payment($data: InputOrderPayment!) {
    cancel_pending_order_payment(data: $data) {
      order_transaction_idx
    }
  }
`;

export const delete_order_transaction = gql`
  mutation delete_order_transaction($order_transaction_idx: Int!) {
    delete_order_transaction(
      order_transaction_idx: $order_transaction_idx
    )
  }
`;

export const file_upload = gql`
  mutation file_upload(
    $file: Upload!
    $container: String!
    $file_type: String!
  ) {
    file_upload(
      file: $file
      container: $container
      file_type: $file_type
    ) {
      file_name
      file_type
      file_kind
      file_url
      width
      height
    }
  }
`;

export const select_notice_list = gql`
  query select_notice_list($state: State!) {
    select_notice_list(state: $state) {
      count
      rows {
        notice_idx
        title
        content
        main_img_url
        notice_onoff
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const add_notice = gql`
  mutation add_notice($data: InputNotice!) {
    add_notice(data: $data) {
      notice_idx
    }
  }
`;

export const update_notice = gql`
  mutation update_notice($data: InputNotice!, $notice_idx: Int!) {
    update_notice(data: $data, notice_idx: $notice_idx) {
      notice_idx
    }
  }
`;

export const select_push_alarm_list_admin = gql`
  query select_push_alarm_list_admin($state: State!) {
    select_push_alarm_list_admin(state: $state) {
      count
      rows {
        push_alarm_idx
        user_idx
        push_target_type
        push_ref_idx
        push_ref_table
        push_alarm_status
        segment
        alarm_title
        alarm_message
        alarm_type
        alarm_url
        alarm_open_datetime
        user_display_onoff
        reservation_datetime
        send_datetime
        send_result
        send_id
        push_data
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const insert_push_alarm = gql`
  mutation insert_push_alarm($data: InputPushAlarm!) {
    insert_push_alarm(data: $data) {
      push_alarm_idx
    }
  }
`;

export const update_push_alarm = gql`
  mutation update_push_alarm($data: InputPushAlarm!) {
    update_push_alarm(data: $data) {
      push_alarm_idx
    }
  }
`;

export const select_banner_list_admin = gql`
  query select_banner_list_admin($state: State) {
    select_banner_list_admin(state: $state) {
      count
      rows {
        banner_idx
        banner_title
        banner_location
        banner_link_type
        banner_img_url
        banner_link_url
        banner_click_count
        banner_onoff
        banner_type
        display_order
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const select_banner_list_open_admin = gql`
  query select_banner_list_open_admin {
    select_banner_list_open_admin {
      count
      rows {
        banner_idx
        banner_title
        banner_location
        banner_link_type
        banner_img_url
        banner_link_url
        banner_click_count
        banner_onoff
        banner_type
        display_order
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const delete_banner_admin = gql`
  mutation delete_banner_admin($banner_idx: Int!) {
    delete_banner_admin(banner_idx: $banner_idx)
  }
`;

export const get_item_admin = gql`
  query get_item_admin($item_idx: Int!) {
    get_item_admin(item_idx: $item_idx) {
      item_idx
      title
      title_internal
      description
      category_idx
      memo
      item_image_url
      crawling_url
      enable_basket_coupon
      item_delivery {
        item_delivery_idx
        item_idx
        delivery_fee_templete_idx
        departure_address
        departure_delivery_company_name
        departure_delivery_company_credit_code
        return_address
        return_delivery_company_name
        return_delivery_company_credit_code
        as_departure_address
        as_departure_delivery_company_name
        as_departure_delivery_company_credit_code
        as_return_address
        as_return_delivery_company_name
        as_return_delivery_company_credit_code
        delivery_adjustment_type
        delivery_type_one
        delivery_type_two
        storage_method
        as_return_type
        as_send_type
        is_enable_store_return
        is_enable_package_delivery
        delivery_fee_type
        delivery_departure_type
        free_delivery_minimum_price
        delivery_departure_fee
        delivery_return_fee
        delivery_exchange_fee
        delivery_item_type
        delivery_item_unit
        delivery_item_fee
        extra_delivery_fee_type
        extra_delivery_fee
        extra_delivery_jeju_fee
      }
      item_category {
        category_idx
        category_name
        display_order
        parent_category_idx
        parent_category {
          category_idx
          category_name
          display_order
          parent_category_idx
          parent_category {
            category_idx
            category_name
            parent_category_idx
            level
          }
          level
          is_open
          description
          regist_datetime
          update_datetime
        }
        level
        is_open
      }
      store_idx
      store {
        store_idx
        user_idx
        name
        type
        title
        size
        description
        thumbnail_url
        bookmark_count
        update_user_idx
        user {
          user_name
        }
      }
      item_status
      is_open
      item_sell_price
      item_buy_price
      item_origin_price
      item_delivery_fee
      platform_fee
      item_profit_share_price
      total_stock
      regist_datetime
      update_datetime
      manager_user_idx
      delivery_company_idx
      company_idx
      main_image_resource_idx
      main_video_resource_idx
      manager_user {
        user_name
      }
      create_user_idx
      create_user {
        user_name
      }
      update_user_idx
      update_user {
        user_name
      }
      company {
        company_idx
        company_name
        company_type
      }
      delivery_company {
        company_idx
        company_name
        company_type
      }
      main_item_resource {
        item_resource_idx
        item_resource_url
        width
        height
      }
      main_image_resource {
        item_resource_idx
        item_resource_url
        width
        height
      }
      main_video_resource {
        item_resource_idx
        item_resource_url
        width
        height
      }
      item_option_list {
        item_option_idx
        item_option_code
        item_option_barcode
        item_option_name
        item_option_stock
        item_option_plus_sell_price
        item_option_plus_buy_price
        is_open
        item_option_sales_channel_list {
          sales_channel_idx
          item_option_sales_channel_name
          item_option_sales_channel_status
          item_option_sales_channel_plus_price
          sales_channel {
            sales_channel_name
          }
        }
      }
      item_sales_channel_list {
        sales_channel_idx
        item_sales_channel_fee
        item_sales_channel_plus_price
        sales_channel {
          sales_channel_name
        }
      }
      item_company_list {
        item_company_type
        company {
          company_idx
          company_name
        }
      }
      item_resource_list {
        item_resource_link_idx
        item_resource {
          item_resource_url
          width
          height
        }
      }
    }
  }
`;

export const select_item_post_admin = gql`
  query select_item_post_admin($state: State!) {
    select_item_post_admin(state: $state) {
      count
      rows {
        post_idx
        item_idx
        list_order
        post {
          title
          user_idx
          preview_img_url
        }
        item {
          title
          item_idx
          item_sell_price
          item_image_url
        }
      }
      
    }
  }
`;

export const select_item_section_list_admin = gql`
  query select_item_section_list_admin($state: State!) {
    select_item_section_list_admin(state: $state) {
      count
      rows {
        shop_section_item_idx
        shop_section_idx
        item_idx
        post_idx
        display_order
        is_open
        img_url
        regist_datetime
        update_datetime
        shop_section {
          shop_section_title
          is_open
        }
      }
    }
  }
`;




export const select_item_thum_list_admin = gql`
  query select_item_thum_list_admin($state: State!, $option_state: State) {
    select_item_thum_list_admin(
      state: $state
      option_state: $option_state
    ) {
      count
      rows {
        item_idx
        title
        title_internal
        description
        is_open
        item_status
        item_sell_price
        item_buy_price
        item_origin_price
        item_delivery_fee
        platform_fee
        item_profit_share_price
        total_stock
        regist_datetime
        update_datetime
        manager_user_idx
        brand_idx
        crawling_url
        enable_basket_coupon
        item_image_url
        item_category {
          category_idx
          category_name
          parent_category_idx
        }

        company {
          company_idx
          company_name
          company_type
        }
        delivery_company {
          company_idx
          company_name
          company_type
        }
      }
    }
  }
`;

export const search_item_list_idx_admin = gql`
  query search_item_list_idx_admin($state: State!) {
    search_item_list_idx_admin(
      state: $state
    ) {
      count
      rows {
        item_idx
        title
        title_internal
        is_open
        item_status
        item_sell_price
        item_buy_price
        item_origin_price
        item_delivery_fee
        platform_fee
        item_profit_share_price
        total_stock
        regist_datetime
        update_datetime
        manager_user_idx
        brand_idx
        crawling_url
        enable_basket_coupon
        item_image_url
      }
    }
  }
`;



export const select_item_list_admin = gql`
  query select_item_list_admin($state: State!, $option_state: State) {
    select_item_list_admin(
      state: $state
      option_state: $option_state
    ) {
      count
      rows {
        item_idx
        title
        title_internal
        description
        is_open
        item_status
        item_sell_price
        item_buy_price
        item_origin_price
        item_delivery_fee
        platform_fee
        item_profit_share_price
        total_stock
        regist_datetime
        update_datetime
        manager_user_idx
        brand_idx
        crawling_url
        enable_basket_coupon
        item_image_url
        item_category {
          category_idx
          category_name
          parent_category_idx
        }
        manager_user {
          user_name
        }
        create_user_idx
        create_user {
          user_name
        }
        update_user_idx
        update_user {
          user_name
        }
        company {
          company_idx
          company_name
          company_type
        }
        delivery_company {
          company_idx
          company_name
          company_type
        }
        main_item_resource {
          item_resource_idx
          item_resource_url
          width
          height
        }
        main_image_resource {
          item_resource_idx
          item_resource_url
          width
          height
        }
        item_option_list {
          item_option_idx
          item_option_code
          item_option_barcode
          item_option_name
          item_option_stock
          item_option_plus_sell_price
          item_option_plus_buy_price
          item_option_sales_channel_list {
            sales_channel_idx
            item_option_sales_channel_name
            item_option_sales_channel_status
            item_option_sales_channel_plus_price
            sales_channel {
              sales_channel_name
            }
          }
        }
        item_sales_channel_list {
          sales_channel_idx
          item_sales_channel_fee
          item_sales_channel_plus_price
          sales_channel {
            sales_channel_name
          }
        }
        item_company_list {
          item_company_type
          company {
            company_idx
            company_name
          }
        }
        item_resource_list {
          item_resource_link_idx
          item_resource {
            item_resource_url
            width
            height
          }
        }
        item_section_list {
          shop_section_item_idx
          shop_section_idx
          item_idx
          post_idx
          display_order
          is_open
          img_url
          regist_datetime
          update_datetime
          shop_section {
            shop_section_title
            is_open
          }
        }
        store {
          store_idx
          name
        }
      }
    }
  }
`;

export const insert_banner = gql`
  mutation insert_banner($data: InputBanner!) {
    insert_banner(data: $data) {
      banner_idx
    }
  }
`;

export const update_banner = gql`
  mutation update_banner($data: InputBanner!) {
    update_banner(data: $data) {
      banner_idx
    }
  }
`;


export const update_bank_account_log = gql`
  mutation update_bank_account_log($data: InputBankAccountLog!) {
    update_bank_account_log(data: $data) {
      bank_account_log_idx
      bank_name
      account_number
      trans_ref_key
      trans_datetime
      withdraw
      deposit
      trans_remark
      balance
      regist_datetime
      update_datetime
      is_fee
      is_checked

    }
  }
`;

export const update_bank_transfer = gql`
  mutation update_bank_transfer($data: InputBankTransfer!) {
    update_bank_transfer(data: $data) {
      ...bankTransferFragment
    }
  }
  ${bankTransferFragment}
`;


export const request_class_room_incentive_process = gql`
  mutation request_class_room_incentive_process {
    request_class_room_incentive_process 
  }
`;



export const request_class_room_incentive_done = gql`
  mutation request_class_room_incentive_done($class_room_incentive_idx:  Int!) {
    request_class_room_incentive_done(class_room_incentive_idx: $class_room_incentive_idx)
  }
`;







export const select_user_request_manager_list = gql`
  query select_user_request_manager_list($state: State) {
    select_user_request_manager_list(state: $state) {
      count
      rows {
        user_request_manager_idx
        user {
          user_idx
          user_phone
          login_type
          user_id
          role_join_list {
            role
          }
          company_manager_list {
            company_idx
            company {
              company_idx
              company_name
            }
          }
        }
        user_idx
        user_name
        user_depart
        status
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const select_company_list = gql`
  query select_company_list($state: State) {
    select_company_list(state: $state) {
      count
      rows {
        company_idx
        company_name
        company_img_url
        company_description
        company_address
        company_type
        regist_datetime
        update_datetime
        create_user_idx
        update_user_idx
        manager_user_idx
        adjustment_period_type
        adjustment_fee_type
        adjustment_fee_rate
        contract_type
        launch_datetime
        order_deadline
        order_send_type
        bank_name
        bank_account
        bank_owner
        adjustment_fee_policy
        adjustment_fee_event_policy
        company_group_idx
        company_group {
          company_group_idx
          group_type
          profit_share_rate
          group_description
        }
      }
    }
  }
`;


export const select_company_group_list = gql`
  query select_company_group_list($state: State) {
    select_company_group_list(state: $state) {
      count
      rows {
        company_group_idx
        group_type
        profit_share_rate
        group_description
      }
    }
  }
`;


export const select_company_manager_list = gql`
  query select_company_manager_list($state: State) {
    select_company_manager_list(state: $state) {
      count
      rows {
        company_idx
        user_idx
        manager_type
        user {
          user_idx
          user_name
          user_email
          user_id
          login_type
        }
      }
    }
  }
`;





export const select_company = gql`
  query select_company($company_idx: Int!) {
    select_company(company_idx: $company_idx) {
      company_idx
      company_name
      company_img_url
      company_description
      company_address
      company_notice
      company_type
      regist_datetime
      update_datetime
      create_user_idx
      update_user_idx
      manager_user_idx
      adjustment_period_type
      adjustment_fee_type
      adjustment_fee_rate
      contract_type
      launch_datetime
      order_deadline
      order_send_type
      bank_name
      bank_account
      bank_owner
      adjustment_fee_policy
      adjustment_fee_event_policy
      company_worker_list {
        company_worker_idx
        company_idx
        worker_type
        worker_name
        worker_phone
        worker_email
        regist_datetime
        update_datetime
      }
      company_group{
        company_group_idx
        group_type
        profit_share_rate
        group_description
      }
    }
  }
`;

export const select_sync_item_stock_history_list = gql`
  query select_sync_item_stock_history_list($state: State!) {
    select_sync_item_stock_history_list(state: $state) {
      count
      rows {
        sync_item_stock_history_idx
        user_idx
        result
        message
        error
        regist_datetime
        update_datetime
        complete_datetime
      }
    }
  }
`;

export const select_order_call_list = gql`
  query select_order_call_list($state: State!) {
    select_order_call_list(state: $state) {
      rows {
        order_call_idx
        user_idx
        ref_post_idx
        item_idx
        order_call_solved
        user {
          user_idx
          user_name
          user_phone
        }
        item {
          item_idx
          title
          item_sell_price
        }
        ref_post {
          post_idx
          user_idx
          title
          user {
            user_idx
            user_name
          }
        }
        regist_datetime
        update_datetime
      }
      count
    }
  }
`;


export const select_onelink_list = gql`
  query select_onelink_list($state: State!) {
    select_onelink_list(state: $state) {
      rows {
        onelink_idx
        onelink_template_id
        onelink_group_idx
        landing_type
        app_open_pid
        app_open_campaign
        app_open_adset
        deep_link_value
        is_retargeting
        onelink_url
        short_link
        push_web_url
        push_app_url
        memo
        regist_datetime
        update_datetime
        create_user_idx
        app_install_count
        app_open_count
        order_item_count
        order_item_amount
        is_company_link
        onelink_tag
        marketing_fee
        marketing_page_link
        send_count
      }
      count
    }
  }
`;

export const select_onelink_group_list = gql`
  query select_onelink_group_list($state: State!) {
    select_onelink_group_list(state: $state) {
      rows {
        onelink_group_idx
        title
        description
        create_user_idx
        update_user_idx
        marketing_fee
        app_install_count
        order_item_count
        app_open_count
        send_count
        regist_datetime
        update_datetime
        tag
        app_open_pid
        app_open_campaign
        app_open_adset
        marketing_page_link
        onelink_list {
          onelink_idx
          onelink_template_id
          landing_type
          app_open_pid
          app_open_campaign
          app_open_adset
          deep_link_value
          is_retargeting
          onelink_url
          short_link
          push_web_url
          push_app_url
          memo
          regist_datetime
          update_datetime
          create_user_idx
          app_install_count
          app_open_count
          order_item_count
          order_item_amount
          is_company_link
          onelink_tag
          marketing_fee
          marketing_page_link
          send_count          
        }
      }
      count
    }
  }
`;





export const update_order_call_admin = gql`
  mutation update_order_call_admin($data: InputOrderCall!) {
    update_order_call_admin(data: $data) {
      order_call_idx
      user_idx
      ref_post_idx
      item_idx
      order_call_solved
      regist_datetime
      update_datetime
    }
  }
`;


export const insert_company_manager = gql`
  mutation insert_company_manager($data: InputCompanyManager!) {
    insert_company_manager(data: $data) {
      user_idx
    }
  }
`;


export const update_company_manager = gql`
  mutation update_company_manager($data: InputCompanyManager!) {
    update_company_manager(data: $data) {
      user_idx
    }
  }
`;

export const delete_company_manager = gql`
  mutation delete_company_manager($data: InputCompanyManager!) {
    delete_company_manager(data: $data) {
      user_idx
    }
  }
`;





export const update_user_request = gql`
  mutation update_user_request(
    $data: InputUserRequestManagerData!
    $user_request_manager_idx: Int!
  ) {
    update_user_request(
      data: $data
      user_request_manager_idx: $user_request_manager_idx
    ) {
      user_request_manager_idx
    }
  }
`;

export const drop_user_request = gql`
  mutation drop_user_request(
    $user_idx: Int!
    $user_request_manager_idx: Int!
  ) {
    drop_user_request(
      user_idx: $user_idx
      user_request_manager_idx: $user_request_manager_idx
    ) {
      user_request_manager_idx
    }
  }
`;

export const insert_role = gql`
  mutation insert_role($user_idx: Int!, $role: String!) {
    insert_role(user_idx: $user_idx, role: $role) {
      role_join_idx
    }
  }
`;

export const delete_role = gql`
  mutation delete_role($user_idx: Int!, $role: String!) {
    delete_role(user_idx: $user_idx, role: $role)
  }
`;

export const add_company = gql`
  mutation add_company($data: InputCompany!) {
    add_company(data: $data) {
      company_idx
      company_name
      company_img_url
      company_description
      company_address
      company_type
      regist_datetime
      update_datetime
      create_user_idx
      update_user_idx
      manager_user_idx
      adjustment_period_type
      adjustment_fee_type
      adjustment_fee_rate
      contract_type
      launch_datetime
      order_deadline
      order_send_type
      bank_name
      bank_account
      bank_owner
      adjustment_fee_policy
      adjustment_fee_event_policy
    }
  }
`;

export const update_company = gql`
  mutation update_company($data: InputCompany!) {
    update_company(data: $data) {
      company_idx
      company_name
      company_img_url
      company_description
      company_address
      company_type
      regist_datetime
      update_datetime
      create_user_idx
      update_user_idx
      manager_user_idx
      adjustment_period_type
      adjustment_fee_type
      adjustment_fee_rate
      contract_type
      launch_datetime
      order_deadline
      order_send_type
      bank_name
      bank_account
      bank_owner
      adjustment_fee_policy
      adjustment_fee_event_policy
    }
  }
`;

export const delete_company = gql`
  mutation delete_company($company_idx: Int!) {
    delete_company(company_idx: $company_idx)
  }
`;

export const add_item = gql`
  mutation add_item($data: InputItem!) {
    add_item(data: $data) {
      item_idx
    }
  }
`;

export const update_item = gql`
  mutation update_item(
    $item_data: InputItem!
    $item_delivery_data: InputItemDelivery!
  ) {
    update_item(
      item_data: $item_data
      item_delivery_data: $item_delivery_data
    ) {
      item_idx
    }
  }
`;

export const insert_item = gql`
  mutation insert_item($data: InputItem!) {
    insert_item(data: $data) {
      item_idx
    }
  }
`;

export const insert_crawling_item = gql`
  mutation insert_crawling_item($data: InputExcelItemList!) {
    insert_crawling_item(data: $data)
  }
`;

export const insert_item_option = gql`
  mutation insert_item_option($data: InputItemOption!) {
    insert_item_option(data: $data) {
      item_option_idx
    }
  }
`;

export const update_item_option = gql`
  mutation update_item_option($data: InputItemOption!) {
    update_item_option(data: $data) {
      item_option_idx
    }
  }
`;

export const update_item_is_open = gql`
  mutation update_item_is_open(
    $item_idx_list: [Int!]!
    $value: Boolean!
  ) {
    update_item_is_open(item_idx_list: $item_idx_list, value: $value)
  }
`;

export const update_item_status = gql`
  mutation udpate_item_status($item_idx_list: [Int!]!, $value: Int!) {
    update_item_status(item_idx_list: $item_idx_list, value: $value)
  }
`;

export const insert_sales_channel_option = gql`
  mutation insert_sales_channel_option(
    $input_item_option_sales_channel: InputItemOptionSalesChannel!
    $input_item_sales_channel: InputItemSalesChannel!
    $input_item: InputItem!
  ) {
    insert_sales_channel_option(
      input_item_option_sales_channel: $input_item_option_sales_channel
      input_item_sales_channel: $input_item_sales_channel
      input_item: $input_item
    )
  }
`;

export const update_sales_channel_option = gql`
  mutation update_sales_channel_option(
    $input_item_option_sales_channel: InputItemOptionSalesChannel!
    $input_item_sales_channel: InputItemSalesChannel!
    $input_item: InputItem!
  ) {
    update_sales_channel_option(
      input_item_option_sales_channel: $input_item_option_sales_channel
      input_item_sales_channel: $input_item_sales_channel
      input_item: $input_item
    )
  }
`;

export const delete_sales_channel_option = gql`
  mutation delete_sales_channel_option(
    $item_option_idx: Int!
    $item_option_sales_channel_name: String!
    $sales_channel_idx: Int!
  ) {
    delete_sales_channel_option(
      item_option_idx: $item_option_idx
      item_option_sales_channel_name: $item_option_sales_channel_name
      sales_channel_idx: $sales_channel_idx
    )
  }
`;

export const insert_post_item = gql`
  mutation insert_post_item(
    $item_idx: Int!
    $list_order: Int!
    $post_idx: Int!
  ) {
    insert_post_item(
      item_idx: $item_idx
      list_order: $list_order
      post_idx: $post_idx
    )
  }
`;

export const update_post_item_admin = gql`
  mutation update_post_item_admin($data: InputPostItem!) {
    update_post_item_admin(data: $data) {
      post_idx
      item_idx
      list_order
    }
  }
`;

export const delete_post_item = gql`
  mutation delete_post_item($item_idx: Int!, $post_idx: Int!) {
    delete_post_item(item_idx: $item_idx, post_idx: $post_idx)
  }
`;

export const insert_temp_order = gql`
  mutation insert_temp_order($data: InputTempOrderList!) {
    insert_temp_order(data: $data) {
      total
      already
      no_item
      possible
      message
    }
  }
`;

export const insert_invoice_list = gql`
  mutation insert_invoice_list($data: InputInvoiceList!) {
    insert_invoice_list(data: $data)
  }
`;

export const select_temp_order_list = gql`
  query select_temp_order_list($state: State!) {
    select_temp_order_list(state: $state) {
      count
      rows {
        temp_order_idx
        sales_channel_idx
        sales_channel_id
        sales_channel_order_number
        sales_channel_order_number2
        order_item_name
        receiver_name
        receiver_phone
        receiver_phone_backup
        order_user_name
        order_user_phone
        address
        post_code
        count
        sales_channel_item_code
        request
        price
        temp_order_status
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const que_temp_order_list = gql`
  mutation que_temp_order_list {
    que_temp_order_list
  }
`;

export const select_sales_channel_item_code_list = gql`
  query select_sales_channel_item_code_list($state: State!) {
    select_sales_channel_item_code_list(state: $state) {
      count
      rows {
        sales_channel_item_code_idx
        sales_channel_idx
        sales_channel_item_count
        sales_channel_item_code
        sales_channel_item_code_memo
        regist_datetime
        update_datetime
        sales_channel {
          sales_channel_idx
          sales_channel_name
          sales_channel_id
        }
        salse_channel_item_option_barcode_list {
          sales_channel_item_option_barcode_idx
          item_idx
          item_option_idx
          item {
            item_idx
            title
          }
          item_option {
            item_option_idx
            item_option_name
            item_option_barcode
          }
        }
      }
    }
  }
`;

export const select_salse_channel_item_option_barcode_list = gql`
  query select_salse_channel_item_option_barcode_list(
    $state: State!
  ) {
    select_salse_channel_item_option_barcode_list(state: $state) {
      count
      rows {
        sales_channel_item_option_barcode_idx
        sales_channel_item_code_idx
        item_idx
        item_option_idx
        regist_datetime
        update_datetime
        item {
          title
          item_sell_price
          item_origin_price
        }
        item_option {
          item_option_idx
          item_option_barcode
          item_option_name
          item_option_plus_sell_price
        }
        sales_channel_item_code {
          sales_channel_item_code
        }
      }
    }
  }
`;

export const _select_sales_channel_item_code_list = gql`
  query select_sales_channel_item_code_list($state: State!) {
    select_sales_channel_item_code_list(state: $state) {
      count
      rows {
        sales_channel_item_code_idx
        sales_channel_idx
        sales_channel_item_count
        sales_channel_item_code
        sales_channel_item_code_memo
        regist_datetime
        update_datetime
        sales_channel {
          sales_channel_idx
          sales_channel_name
          sales_channel_id
        }
        salse_channel_item_option_barcode_list {
          sales_channel_item_option_barcode_idx
          item_idx
          item_option_idx
          item {
            title
            item_sell_price
          }
          item_option {
            item_option_name
            item_option_barcode
            item_option_plus_sell_price
          }
        }
      }
    }
  }
`;

export const search_item = gql`
  query search_item($state: State!) {
    search_item(state: $state) {
      count
      rows {
        item_idx
        title
        item_sell_price
        item_buy_price
        item_origin_price
        total_stock
        regist_datetime
        update_datetime
        item_option_list {
          item_option_idx
          item_idx
          item_option_barcode
          item_option_name
          item_option_stock
        }
      }
    }
  }
`;

export const search_item_option = gql`
  query search_item_option($state: State!) {
    search_item_option(state: $state) {
      count
      rows {
        item_option_idx
        item_idx
        item_option_barcode
        item_option_name
        item_option_stock
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const insert_sales_channel_item_code = gql`
  mutation insert_sales_channel_item_code(
    $data: InputSalesChannelItemCode!
  ) {
    insert_sales_channel_item_code(data: $data) {
      sales_channel_item_code_idx
    }
  }
`;

export const delete_sales_channel_item_code = gql`
  mutation delete_sales_channel_item_code(
    $sales_channel_item_code_idx: Int!
  ) {
    delete_sales_channel_item_code(
      sales_channel_item_code_idx: $sales_channel_item_code_idx
    )
  }
`;

export const update_sales_channel_item_code = gql`
  mutation update_sales_channel_item_code(
    $data: InputSalesChannelItemCode!
  ) {
    update_sales_channel_item_code(data: $data) {
      sales_channel_item_code_idx
    }
  }
`;

export const insert_sales_channel_item_option_barcode = gql`
  mutation insert_sales_channel_item_option_barcode(
    $data: InputSalesChannelItemOptionBarcode!
  ) {
    insert_sales_channel_item_option_barcode(data: $data) {
      sales_channel_item_option_barcode_idx
    }
  }
`;

export const update_sales_channel_item_option_barcode = gql`
  mutation update_sales_channel_item_option_barcode(
    $data: InputSalesChannelItemOptionBarcode!
  ) {
    update_sales_channel_item_option_barcode(data: $data) {
      sales_channel_item_option_barcode_idx
    }
  }
`;

export const delete_sales_channel_item_option_barcode = gql`
  mutation delete_sales_channel_item_option_barcode(
    $sales_channel_item_option_barcode_idx: Int!
  ) {
    delete_sales_channel_item_option_barcode(
      sales_channel_item_option_barcode_idx: $sales_channel_item_option_barcode_idx
    )
  }
`;

export const select_delivery_order_list = gql`
  query select_delivery_order_list($state: State!) {
    select_delivery_order_list(state: $state) {
      count
      rows {
        order_transaction_idx
        order_item_idx
        item_option_barcode
        delivery_company_idx
        delivery_company_name
        sales_channel_id
        sales_channel_order_number
        order_turn
        address
        address_detail
        request
        post_code
        order_user_name
        order_user_phone
        receiver_name
        receiver_phone
        receiver_phone_backup
        item_title
        item_option_name
        item_sell_price
        order_item_count
        order_transaction_memo
        delivery_status
        regist_datetime
        update_datetime
        crawling_url
      }
    }
  }
`;

export const select_delivery_order_list_for_pending = gql`
  query select_delivery_order_list_for_pending($state: State!) {
    select_delivery_order_list_for_pending(state: $state) {
      count
      rows {
        order_transaction_idx
        order_item_idx
        item_option_barcode
        delivery_company_idx
        delivery_company_name
        sales_channel_id
        sales_channel_order_number
        order_turn
        address
        address_detail
        request
        post_code
        order_user_name
        order_user_phone
        receiver_name
        receiver_phone
        receiver_phone_backup
        item_title
        item_option_name
        item_sell_price
        order_item_count
        order_transaction_memo
        delivery_status
        regist_datetime
        update_datetime
        crawling_url
      }
    }
  }
`;

export const select_delivery_order_transaction_list = gql`
  query select_delivery_order_transaction_list($state: State!) {
    select_delivery_order_transaction_list(state: $state) {
      count
      rows {
        order_transaction_idx
        item_option_barcode
        delivery_company_idx
        delivery_company_name
        sales_channel_id
        sales_channel_order_number
        order_turn
        address
        address_detail
        request
        post_code
        order_user_name
        order_user_phone
        receiver_name
        receiver_phone
        receiver_phone_backup
        item_title
        item_option_name
        order_item_count
        order_transaction_memo
        delivery_status
        sell_price
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const delivery_update_delivery_status_admin = gql`
  mutation delivery_update_delivery_status_admin(
    $order_transaction_idx: Int!
    $delivery_status: String!
    $apply_all: Boolean
  ) {
    delivery_update_delivery_status_admin(
      order_transaction_idx: $order_transaction_idx
      delivery_status: $delivery_status
      apply_all: $apply_all
    ) {
      order_transaction_idx
      order_object_id
      delivery_status
      regist_datetime
      update_datetime
      order_item_list {
        order_item_idx
        item_idx
        order_item_title
        order_item_delivery_status
      }
    }
  }
`;

export const update_temp_order_status = gql`
  mutation update_temp_order_status(
    $temp_order_idx: Int!
    $temp_order_status: Int!
  ) {
    update_temp_order_status(
      temp_order_idx: $temp_order_idx
      temp_order_status: $temp_order_status
    ) {
      temp_order_idx
    }
  }
`;

export const delete_temp_order = gql`
  mutation delete_temp_order($temp_order_idx: Int!) {
    delete_temp_order(temp_order_idx: $temp_order_idx)
  }
`;

export const que_temp_order_insert = gql`
  mutation que_temp_order_insert {
    que_temp_order_insert
  }
`;

export const select_invoice_list = gql`
  query select_invoice_list($state: State!) {
    select_invoice_list(state: $state) {
      count
      rows {
        invoice_idx
        shop_name
        invoice_num
        order_turn
        order_num
        order_seq
        invoice_delivery_company_name
        receiver_phone
        receiver_mobile
        receiver_name
        processed
        good_name
        error
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const select_topic_list_admin = gql`
  query select_topic_list_admin($state: State) {
    select_topic_list_admin(state: $state) {
      count
      rows {
        topic_idx
        topic_title
        topic_img_url
        topic_detail
        topic_onoff
        topic_tag
        topic_start_datetime
        topic_end_datetime
      }
    }
  }
`;

export const update_topic_admin = gql`
  mutation update_topic_admin($data: InputTopic!) {
    update_topic_admin(data: $data) {
      topic_idx
      topic_title
      topic_img_url
      topic_detail
      topic_onoff
      topic_start_datetime
      topic_end_datetime
    }
  }
`;

export const add_topic_admin = gql`
  mutation add_topic_admin($data: InputTopic!) {
    add_topic_admin(data: $data) {
      topic_idx
      topic_title
      topic_img_url
      topic_detail
      topic_onoff
      topic_start_datetime
      topic_end_datetime
    }
  }
`;

export const shop_select_shop_section_list_admin = gql`
  query shop_select_shop_section_list_admin($state: State) {
    shop_select_shop_section_list_admin(state: $state) {
      count
      rows {
        shop_section_idx
        shop_section_title
        shop_section_img_url
        is_open
        description
        display_order
        shop_section_type
        shop_section_mode
        shop_section_value
        start_datetime
        end_datetime
        regist_datetime
        update_datetime
        is_company_section
        create_user{
          user_idx,
          user_name,
          user_img_url,
          is_fumist
        }
        shop_section_items {
          shop_section_item_idx
          shop_section_idx
          item_idx
          post_idx
          display_order
          is_open
          img_url 
          item {
            item_idx
            title
            is_open
            item_status
            item_image_url
          }
        }
      }
    }
  }
`;

export const search_shop_section_list_admin = gql`
  query search_shop_section_list_admin($state: State) {
    search_shop_section_list_admin(state: $state) {
      count
      rows {
        shop_section_idx
        shop_section_title
        shop_section_img_url
        is_open
        description
        display_order
        shop_section_type
        shop_section_mode
        shop_section_value
        start_datetime
        end_datetime
        regist_datetime
        update_datetime
        
      }
    }
  }
`;


export const search_live_tv_list_admin = gql`
  query search_live_tv_list_admin($state: State) {
    search_live_tv_list_admin(state: $state) {
      count
      rows {
        post_tv_idx
        post_idx
        store_idx
        item_sell_price
        item_origin_price
        post_tv_title
        preview_image_url
      }
    }
  }
`;






export const select_shop_section_item_list_admin = gql`
  query select_shop_section_item_list_admin($shop_section_idx: Int!) {
    select_shop_section_item_list_admin(
      shop_section_idx: $shop_section_idx
    ) {
      count
      rows {
        shop_section_item_idx
        shop_section_idx
        item_idx
        post_idx
        display_order
        is_open
        img_url
        item {
          item_idx
          title
          is_open
          main_image_resource {
            item_resource_url
            width
            height
            color
          }
        }
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const shop_insert_shop_section_admin = gql`
  mutation shop_insert_shop_section_admin($data: InputShopSection!) {
    shop_insert_shop_section_admin(data: $data) {
      shop_section_idx
      shop_section_title
      is_open
      display_order
      shop_section_type
      regist_datetime
      update_datetime
      description
    }
  }
`;

export const shop_insert_shop_section_item_admin = gql`
  mutation shop_insert_shop_section_item_admin(
    $data: InputShopSectionItem!
  ) {
    shop_insert_shop_section_item_admin(data: $data) {
      shop_section_item_idx
      shop_section_idx
      item {
        title
      }
      item_idx
      post_idx
      display_order
      is_open
      img_url
      regist_datetime
      update_datetime
    }
  }
`;

export const shop_update_shop_section_admin = gql`
  mutation shop_update_shop_section_admin($data: InputShopSection!) {
    shop_update_shop_section_admin(data: $data) {
      shop_section_idx

    }
  }
`;

export const shop_update_shop_section_list_admin = gql`
  mutation shop_update_shop_section_list_admin(
    $data: [InputShopSection!]!
    $offset: Int!
  ) {
    shop_update_shop_section_list_admin(data: $data, offset: $offset)
  }
`;

export const shop_delete_shop_section_admin = gql`
  mutation shop_delete_shop_section_admin($shop_section_idx: Int!) {
    shop_delete_shop_section_admin(
      shop_section_idx: $shop_section_idx
    )
  }
`;

export const update_shop_section_item_admin = gql`
  mutation update_shop_section_item_admin(
    $data: InputShopSectionItem!
  ) {
    update_shop_section_item_admin(data: $data) {
      shop_section_item_idx
      shop_section_idx
      item_idx
      post_idx
      display_order
      is_open
      img_url
      regist_datetime
      update_datetime
    }
  }
`;

export const shop_insert_shop_section_item_list_admin = gql`
  mutation shop_insert_shop_section_item_list_admin(
    $data: [InputShopSectionItem!]!
  ) {
    shop_insert_shop_section_item_list_admin(data: $data)
  }
`;

export const select_payment_type_list_admin = gql`
  query select_payment_type_list_admin($state: State) {
    select_payment_type_list_admin(state: $state) {
      count
      rows {
        payment_type
        is_use
        regist_datetime
        update_datetime
        text
        display_order
      }
    }
  }
`;

export const insert_payment_type = gql`
  mutation insert_payment_type($data: InputPaymentType!) {
    insert_payment_type(data: $data) {
      payment_type
    }
  }
`;

export const update_payment_type = gql`
  mutation update_payment_type($data: InputPaymentType!) {
    update_payment_type(data: $data) {
      payment_type
    }
  }
`;

export const admin_select_config_service_list = gql`
  query admin_select_config_service_list($state: State) {
    admin_select_config_service_list(state: $state) {
      count
      rows {
        config_service_idx
        key_name
        key_value
        key_type
        explain_key
      }
    }
  }
`;

export const admin_update_config_service = gql`
  mutation admin_update_config_service($data: InputConfigService!) {
    admin_update_config_service(data: $data)
  }
`;

export const admin_insert_sales_channel_item_code_excel = gql`
  mutation admin_insert_sales_channel_item_code_excel(
    $data: InputSalesChannelItemCodeExcelList!
  ) {
    admin_insert_sales_channel_item_code_excel(data: $data) {
      count
      rows {
        sales_channel_item_code_idx
        sales_channel_item_code
        sales_channel_name
        salse_channel_item_option_barcode_list
        error
      }
    }
  }
`;

export const point_check_left_admin = gql`
  query point_check_left_admin($user_idx: Int!) {
    point_check_left_admin(user_idx: $user_idx)
  }
`;

export const point_select_user_point_list_admin = gql`
  query point_select_user_point_list_admin($user_idx: Int!) {
    point_select_user_point_list_admin(user_idx: $user_idx) {
      count
      rows {
        point_idx
        user_idx
        point_rule_idx
        order_transaction_idx
        title
        point_amount
        point_status
        expire_date
        regist_datetime
        update_datetime
      }
      point_balance
      point_to_be_expired
    }
  }
`;

export const point_create_user_point_admin = gql`
  mutation point_create_user_point_admin($data: InputPoint!) {
    point_create_user_point_admin(data: $data) {
      point_idx
      user_idx
      point_rule_idx
      order_transaction_idx
      title
      point_status
      expire_date
      point_amount
      create_user_idx
      regist_datetime
      update_datetime
    }
  }
`;

export const point_expire_user_point_admin = gql`
  mutation point_expire_user_point_admin($point_idx: Int!) {
    point_expire_user_point_admin(point_idx: $point_idx) {
      point_idx
      user_idx
      point_rule_idx
      order_transaction_idx
      title
      point_status
      expire_date
      point_amount
      create_user_idx
      regist_datetime
      update_datetime
    }
  }
`;

export const point_select_point_list_admin = gql`
  query point_select_point_list_admin($state: State!) {
    point_select_point_list_admin(state: $state) {
      count
      rows {
        point_idx
        user_idx
        point_rule_idx
        order_transaction_idx
        title
        point_status
        expire_date
        point_amount
        create_user_idx
        regist_datetime
        update_datetime
        user {
          login_type
          user_name
          user_phone
          user_gender
          age_range
        }
      }
    }
  }
`;

export const point_select_point_ready_list_admin = gql`
  query point_select_point_ready_list_admin($state: State!) {
    point_select_point_ready_list_admin(state: $state) {
      count
      rows {
 

        point_ready_idx
        user_idx
        point_rule_idx
        order_transaction_idx
        order_item_idx
        title
        description
        point_status
        expire_date
        point_amount
        create_user_idx
        add_ref_table_idx
        add_ref_table
        regist_datetime
        update_datetime
        predict_datetime
        complete_datetime
        point_idx
        memo        
        user {
          login_type
          user_name
          user_phone
          user_gender
          age_range
        }
      }
    }
  }
`;


export const point_create_bulk_list_admin = gql`
  mutation point_create_bulk_list_admin($data: InputPointList!) {
    point_create_bulk_list_admin(data: $data)
  }
`;

export const merge_item_admin = gql`
  mutation merge_item_admin($data: InputMergeItem!) {
    merge_item_admin(data: $data)
  }
`;

export const merge_item_option_admin = gql`
  mutation merge_item_option_admin($data: InputMergeItemOption!) {
    merge_item_option_admin(data: $data)
  }
`;

export const delete_item_admin = gql`
  mutation delete_item_admin($item_idx: Int!) {
    delete_item_admin(item_idx: $item_idx) {
      success
      message
    }
  }
`;

export const delete_item_option_admin = gql`
  mutation delete_item_option_admin($item_option_idx: Int!) {
    delete_item_option_admin(item_option_idx: $item_option_idx) {
      success
      message
    }
  }
`;

export const delete_item_option = gql`
  mutation delete_item_option($item_option_idx: Int!) {
    delete_item_option(item_option_idx: $item_option_idx)
  }
`;

export const get_item_option_admin = gql`
  query get_item_option_admin($item_option_barcode: String!) {
    get_item_option_admin(item_option_barcode: $item_option_barcode) {
      item_option_idx
      item_option_code
      item_option_barcode
      item_option_name
      item_option_stock
      item_option_plus_sell_price
      item_option_plus_buy_price
      item {
        item_idx
        title
        title_internal
        description
        item_status
        item_sell_price
        item_buy_price
        item_origin_price
        item_delivery_fee
        platform_fee
        item_profit_share_price
        total_stock
        regist_datetime
        update_datetime
        manager_user_idx
        delivery_company_idx
        company_idx
        main_image_resource_idx
        main_video_resource_idx
        company {
          company_idx
          company_name
          company_type
        }
        delivery_company {
          company_idx
          company_name
          company_type
        }
        main_item_resource {
          item_resource_idx
          item_resource_url
          width
          height
        }
        main_image_resource {
          item_resource_idx
          item_resource_url
          width
          height
        }
        main_video_resource {
          item_resource_idx
          item_resource_url
          width
          height
        }
      }
    }
  }
`;

export const delivery_update_order_item_admin = gql`
  mutation delivery_update_order_item_admin($data: InputOrderItem!) {
    delivery_update_order_item_admin(data: $data) {
      order_item_idx
      user_idx
      order_transaction_idx
      sales_channel_idx
      item_idx
      item_option_idx
      order_count
      item_option_text
      sell_price
      origin_price
      buy_price
      admin_plus_price
      user_pay_price
      external_plus_price
      platform_fee
      payment_fee
      delivery_fee
      company_delivery_fee
      use_point
      add_point
      order_item_status
      order_item_title
      order_item_delivery_status
      order_item_payment_status
      invoice
      invoice_num
      invoice_delivery_company_name
      invoice_check_datetime
      regist_datetime
      update_datetime
      create_user_idx
      update_user_idx
      manager_user_idx
    }
  }
`;

export const shop_select_shop_section_group_admin = gql`
  query shop_select_shop_section_group_admin(
    $state: State
    $shop_section_group_type: String!
  ) {
    shop_select_shop_section_group_admin(
      state: $state
      shop_section_group_type: $shop_section_group_type
    ) {
      count
      rows {
        shop_section_group_idx
        shop_section_idx
        display_order
        is_open
        regist_datetime
        update_datetime
        shop_section {
          shop_section_idx
          shop_section_title
          is_open
          display_order
          shop_section_type
        }
      }
    }
  }
`;

export const shop_delete_shop_section_group_admin = gql`
  mutation shop_delete_shop_section_group_admin(
    $shop_section_group_idx: Int!
  ) {
    shop_delete_shop_section_group_admin(
      shop_section_group_idx: $shop_section_group_idx
    )
  }
`;

export const shop_search_section_list = gql`
  query shop_search_section_list($state: State!) {
    shop_search_section_list(state: $state) {
      count
      rows {
        shop_section_idx
        shop_section_title
        is_open
        display_order
        shop_section_type
      }
    }
  }
`;

export const shop_insert_shop_section_group = gql`
  mutation shop_insert_shop_section_group(
    $data: InputShopSectionGroup!
  ) {
    shop_insert_shop_section_group(data: $data) {
      shop_section_group_idx
      group_type
      shop_section_idx
      display_order
      is_open
      regist_datetime
      update_datetime
    }
  }
`;

export const shop_update_shop_section_group = gql`
  mutation shop_update_shop_section_group(
    $data: InputShopSectionGroup!
  ) {
    shop_update_shop_section_group(data: $data) {
      shop_section_group_idx
      group_type
      shop_section_idx
      display_order
      is_open
      regist_datetime
      update_datetime
    }
  }
`;

export const shop_update_shop_section_group_list_admin = gql`
  mutation shop_update_shop_section_group_list_admin(
    $data: [InputShopSectionGroup!]!
    $offset: Int!
  ) {
    shop_update_shop_section_group_list_admin(
      data: $data
      offset: $offset
    )
  }
`;






export const post_select_live_tv_list_admin = gql`
  query post_select_live_tv_list_admin($state: State!) {
    post_select_live_tv_list_admin(state: $state) {
      count
      rows {
        post_tv_idx
        post_idx
        store_idx
        item_sell_price
        item_origin_price
        post_tv_tags
        admin_check_status
        regist_datetime
        update_datetime
        video_url
        item_list_title
        width
        height
        display_order
        preview_video_url
        post_tv_title
        post_tv_img_url
        post_tv_img_url_before
        post_tv_img_url_after
        post_tv_banner_image_url
        preview_image_url
        show_home
        live_start_datetime
        live_end_datetime
        push_send_datetime
        post_tv_like_count
        store {
          store_idx
          name
        }
        post {
          post_idx
          user_idx
          title
          description
          preview_img_url
          video_url
          reply_count
          like_count
          view_count
          admin_check_status
          open_status
          post_type
          regist_datetime
          update_datetime
          post_item_list {
            item_idx
            post_idx
            item {
              item_idx
              title
              title_internal
            }
            list_order
          }
        }  
      }
    }
  }
`;



export const post_select_fumi_tv_list_admin = gql`
  query post_select_fumi_tv_list_admin($state: State!) {
    post_select_fumi_tv_list_admin(state: $state) {
      count
      rows {
        post_idx
        user_idx
        title
        description
        preview_img_url
        video_url
        reply_count
        like_count
        view_count
        admin_check_status
        open_status
        post_type
        regist_datetime
        update_datetime
        post_tv {
          post_tv_idx
          post_idx
          item_sell_price
          item_origin_price
          post_tv_banner_image_url
          post_tv_tags
          admin_check_status
          regist_datetime
          update_datetime
          video_url
          store_idx
          item_list_title
          display_order
          width
          height
          show_home
          live_start_datetime
          live_end_datetime

          store {
            store_idx
            name
          }
        }
        user {
          user_idx
          user_name
          user_phone
          user_gender
          user_img_url
          user_profile
          login_type
        }
        post_item_list {
          item {
            item_idx
            title
            title_internal
            main_image_resource {
              item_resource_url
              width
              height
              color
            }
          }
          list_order
        }
        tag_list
      }
    }
  }
`;

export const post_insert_fumi_tv = gql`
  mutation post_insert_fumi_tv($data: InputPost!) {
    post_insert_fumi_tv(data: $data) {
      post_idx
      title
      video_url
      preview_img_url
      view_count
      update_datetime
      regist_datetime
    }
  }
`;

export const post_update_fumi_tv = gql`
  mutation post_update_fumi_tv($data: InputPost!) {
    post_update_fumi_tv(data: $data) {
      post_idx
      title
      video_url
      preview_img_url
      view_count
      update_datetime
      regist_datetime
    }
  }
`;


export const post_update_fumi_tv_live = gql`
  mutation post_update_fumi_tv_live($data: InputPostTv!) {
    post_update_fumi_tv_live(data: $data) {
      post_idx
    }
  }
`;

export const post_save_fumi_tv_live = gql`
  mutation post_save_fumi_tv_live($data: InputPostTv!) {
    post_save_fumi_tv_live(data: $data) {
      post_tv_idx
    }
  }
`;





export const post_delete_fumi_tv = gql`
  mutation delete_post($post_idx: Int!) {
    delete_post_admin(post_idx: $post_idx)
  }
`;


export const delete_fumi_tv = gql`
  mutation delete_fumi_tv($post_tv_idx: Int!) {
    delete_fumi_tv(post_tv_idx: $post_tv_idx)
  }
`;
export const post_full_sync_post_tv_admin = gql`
  mutation post_full_sync_post_tv_admin {
    post_full_sync_post_tv_admin
  }
`;


export const full_sync_store_admin = gql`
  mutation full_sync_store_admin {
    full_sync_store_admin
  }
`;





export const post_select_fumi_tv_admin = gql`
  query post_select_fumi_tv_admin($post_idx: Int!) {
    post_select_fumi_tv_admin(post_idx: $post_idx) {
      title
      preview_img_url
      regist_datetime
      update_datetime
      user_idx
      post_idx
      video_url
      post_item_list {
        item_idx
        post_idx
        item {
          item_idx
          item_origin_price
          item_sell_price
          title
          main_image_resource {
            item_resource_url
          }
        }
        list_order
      }
    }
  }
`;

export const shop_delete_shop_section_item_admin = gql`
  mutation shop_delete_shop_section_item_admin(
    $shop_section_item_idx: Int!
  ) {
    shop_delete_shop_section_item_admin(
      shop_section_item_idx: $shop_section_item_idx
    )
  }
`;



export const copy_class_room_shop_section = gql`
  mutation copy_class_room_shop_section($data: InputCopyShopSection!) {
    copy_class_room_shop_section(data: $data)
  }
`;



export const shop_select_category_list_admin = gql`
  query shop_select_category_list_admin($state: State!) {
    shop_select_category_list_admin(state: $state) {
      count
      rows {
        category_idx
        category_name
        display_order
        parent_category_idx
        parent_category {
          category_idx
          category_name
          display_order
          parent_category_idx
          parent_category {
            category_idx
            category_name
            parent_category {
              category_idx
              category_name
            }
          }
          level
          is_open
          description
          regist_datetime
          update_datetime
        }
        level
        is_open
        display_group_type
        description
        img_url
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const shop_update_category_admin = gql`
  mutation shop_update_category_admin($data: InputCategory!) {
    shop_update_category_admin(data: $data) {
      category_idx
      category_name
      display_order
      parent_category_idx
      level
      display_group_type
      is_open
      description
      img_url
      regist_datetime
      update_datetime
    }
  }
`;

export const shop_insert_category_admin = gql`
  mutation shop_insert_category_admin($data: InputCategory!) {
    shop_insert_category_admin(data: $data) {
      category_idx
      category_name
      display_order
      parent_category_idx
      level
      display_group_type
      is_open
      description
      img_url
      regist_datetime
      update_datetime
    }
  }
`;

export const shop_delete_category_admin = gql`
  mutation shop_delete_category_admin($data: InputCategory!) {
    shop_delete_category_admin(data: $data)
  }
`;

export const post_select_featured_content_admin = gql`
  query post_select_featured_content_admin($idx: Int!) {
    post_select_featured_content_admin(idx: $idx) {
      idx
      post_idx
      is_open
      type
      ref_table
      ref_table_idx
      button_text
      featured_img_url
      regist_datetime
      update_datetime
      post {
        post_idx
        user_idx
        title
        description
        post_type
        preview_img_url
        reply_count
        like_count
        view_count
        open_status
        regist_datetime
        update_datetime
        post_item_list {
          item_idx
          post_idx
          list_order
          item {
            main_image_resource {
              item_resource_url
            }
            item_origin_price
            item_sell_price
            item_idx
            title
          }
        }
        reply_list {
          content
          regist_datetime
          user_idx
          user {
            user_idx
            user_name
            user_img_url
          }
        }
      }
    }
  }
`;

export const post_select_featured_content_list_admin = gql`
  query post_select_featured_content_list_admin($state: State!) {
    post_select_featured_content_list_admin(state: $state) {
      count
      rows {
        idx
        post_idx
        is_open
        type
        ref_table
        ref_table_idx
        button_text
        featured_img_url
        regist_datetime
        update_datetime
        post {
          post_idx
          user_idx
          title
          description
          post_type
          preview_img_url
          reply_count
          like_count
          view_count
          open_status
          regist_datetime
          update_datetime
          post_item_list {
            item_idx
            post_idx
            list_order
            item {
              main_image_resource {
                item_resource_url
              }
              item_origin_price
              item_sell_price
              item_idx
              title
            }
          }
        }
      }
    }
  }
`;

export const post_insert_featured_content_admin = gql`
  mutation post_insert_featured_content_admin(
    $post_data: InputPost!
    $featured_content_data: InputFeaturedContent!
  ) {
    post_insert_featured_content_admin(
      post_data: $post_data
      featured_content_data: $featured_content_data
    ) {
      idx
      post_idx
      is_open
      type
      ref_table
      ref_table_idx
      button_text
      featured_img_url
      regist_datetime
      update_datetime
      post {
        post_idx
        user_idx
        title
        description
        post_type
        preview_img_url
        reply_count
        like_count
        view_count
        open_status
        regist_datetime
        update_datetime
        post_item_list {
          item_idx
          post_idx
          list_order
          item {
            main_image_resource {
              item_resource_url
            }
            item_origin_price
            item_sell_price
            item_idx
            title
          }
        }
      }
    }
  }
`;

export const post_update_featured_content_admin = gql`
  mutation post_update_featured_content_admin(
    $post_data: InputPost!
    $featured_content_data: InputFeaturedContent!
  ) {
    post_update_featured_content_admin(
      post_data: $post_data
      featured_content_data: $featured_content_data
    ) {
      idx
      post_idx
      is_open
      type
      ref_table
      ref_table_idx
      button_text
      featured_img_url
      regist_datetime
      update_datetime
      post {
        post_idx
        user_idx
        title
        description
        post_type
        preview_img_url
        reply_count
        like_count
        view_count
        open_status
        regist_datetime
        update_datetime
        post_item_list {
          item_idx
          post_idx
          list_order
          item {
            main_image_resource {
              item_resource_url
            }
            item_origin_price
            item_sell_price
            item_idx
            title
          }
        }
      }
    }
  }
`;

export const post_delete_featured_content_admin = gql`
  mutation post_delete_featured_content_admin($idx: Int!) {
    post_delete_featured_content_admin(idx: $idx)
  }
`;

export const tag_insert_tag_from_post_list_admin = gql`
  mutation tag_insert_tag_from_post_list_admin($data: [InputPost!]!) {
    tag_insert_tag_from_post_list_admin(data: $data)
  }
`;

export const event_select_event_list_admin = gql`
  query event_select_event_list_admin($state: State!) {
    event_select_event_list_admin(state: $state) {
      count
      rows {
        event_idx
        title
        description
        banner_img_url
        full_img_url
        video_url
        is_open
        html
        status
        start_datetime
        end_datetime
        type
        badge_url
        update_user_idx
      }
    }
  }
`;

export const event_select_event_admin = gql`
  query event_select_event_admin($event_idx: Int!) {
    event_select_event_admin(event_idx: $event_idx) {
      event_idx
      title
      description
      banner_img_url
      full_img_url
      video_url
      is_open
      html
      status
      start_datetime
      end_datetime
      type
      badge_url
      update_user_idx
    }
  }
`;

export const event_insert_event_admin = gql`
  mutation event_insert_event_admin($event_data: InputEvent!) {
    event_insert_event_admin(event_data: $event_data) {
      event_idx
      title
      description
      banner_img_url
      full_img_url
      video_url
      is_open
      html
      status
      start_datetime
      end_datetime
      type
      badge_url
      update_user_idx
    }
  }
`;

export const event_update_event_admin = gql`
  mutation event_update_event_admin($event_data: InputEvent!) {
    event_update_event_admin(event_data: $event_data) {
      event_idx
      title
      description
      banner_img_url
      full_img_url
      video_url
      is_open
      html
      status
      start_datetime
      end_datetime
      type
      badge_url
      update_user_idx
    }
  }
`;
export const event_delete_event_admin = gql`
  mutation event_delete_event_admin($event_idx: Int!) {
    event_delete_event_admin(event_idx: $event_idx)
  }
`;

export const login_agency = gql`
  mutation login_agency($user_id: String!, $user_pw: String!) {
    login_agency(user_id: $user_id, user_pw: $user_pw) {
      token
    }
  }
`;

export const regist_agency = gql`
  mutation regist_agency(
    $user_id: String!
    $user_pw: String!
    $user_name: String!
  ) {
    regist_agency(
      user_id: $user_id
      user_pw: $user_pw
      user_name: $user_name
    ) {
      token
    }
  }
`;

export const insert_request_manager = gql`
  mutation insert_request_manager(
    $company_name: String!
    $user_depart: String!
    $user_name: String!
  ) {
    insert_request_manager(
      company_name: $company_name
      user_depart: $user_depart
      user_name: $user_name
    ) {
      company_name
      user_depart
      user_name
      user_request_manager_idx
      user_idx
      status
      regist_datetime
    }
  }
`;

export const select_request_manager_one = gql`
  query select_request_manager_one {
    select_request_manager_one {
      company_name
      user_depart
      user_name
      user_request_manager_idx
      user_idx
      status
      regist_datetime
    }
  }
`;

export const content_order_insert_content_order_admin = gql`
  mutation content_order_insert_content_order_admin(
    $content_order_data: InputContentOrder!
  ) {
    content_order_insert_content_order_admin(
      content_order_data: $content_order_data
    ) {
      content_order_idx
      content_page
      content_name
      content_name_internal
      display_order
      is_open
    }
  }
`;

export const content_order_select_content_order_admin = gql`
  query content_order_select_content_order_admin(
    $content_order_idx: Int!
  ) {
    content_order_select_content_order_admin(
      content_order_idx: $content_order_idx
    ) {
      content_order_idx
      content_page
      content_name
      content_name_internal
      display_order
      is_open
    }
  }
`;

export const content_order_select_content_order_list_admin = gql`
  query content_order_select_content_order_list_admin(
    $state: State!
  ) {
    content_order_select_content_order_list_admin(state: $state) {
      count
      rows {
        content_order_idx
        content_page
        content_name
        content_name_internal
        display_order
        is_open
      }
    }
  }
`;

export const content_order_update_content_order_admin = gql`
  mutation content_order_update_content_order_admin(
    $content_order_data: InputContentOrder!
  ) {
    content_order_update_content_order_admin(
      content_order_data: $content_order_data
    ) {
      content_order_idx
      content_page
      content_name
      content_name_internal
      display_order
      is_open
    }
  }
`;

export const content_order_update_content_order_list_admin = gql`
  mutation content_order_update_content_order_list_admin(
    $content_order_list_data: [InputContentOrder!]!
  ) {
    content_order_update_content_order_list_admin(
      content_order_list_data: $content_order_list_data
    )
  }
`;

export const content_order_delete_content_order_admin = gql`
  mutation content_order_delete_content_order_admin(
    $content_order_idx: Int!
  ) {
    content_order_delete_content_order_admin(
      content_order_idx: $content_order_idx
    )
  }
`;

export const notice_dialog_select_notice_dialog_admin = gql`
  query notice_dialog_select_notice_dialog_admin($state: State!) {
    notice_dialog_select_notice_dialog_admin(state: $state) {
      count
      rows {
        notice_dialog_idx
        title
        img_url
        link_url
        button_text
        button_color
        link_url_two
        button_text_two
        button_color_two
        type
        is_open
        display_order
        start_datetime
        end_datetime
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const notice_dialog_insert_notice_dialog_admin = gql`
  mutation notice_dialog_insert_notice_dialog_admin(
    $notice_dialog_data: InputNoticeDialog!
  ) {
    notice_dialog_insert_notice_dialog_admin(
      notice_dialog_data: $notice_dialog_data
    ) {
      notice_dialog_idx
      title
      img_url
      link_url
      button_text
      button_color
      type
      is_open
      display_order
      start_datetime
      end_datetime
      regist_datetime
      update_datetime
    }
  }
`;

export const notice_dialog_update_notice_dialog_admin = gql`
  mutation notice_dialog_update_notice_dialog_admin(
    $notice_dialog_data: InputNoticeDialog!
  ) {
    notice_dialog_update_notice_dialog_admin(
      notice_dialog_data: $notice_dialog_data
    ) {
      notice_dialog_idx
      title
      img_url
      link_url
      button_text
      button_color
      type
      is_open
      display_order
      start_datetime
      end_datetime
      regist_datetime
      update_datetime
    }
  }
`;

export const notice_dialog_delete_notice_dialog_admin = gql`
  mutation notice_dialog_delete_notice_dialog_admin(
    $notice_dialog_data: NoticeDialog!
  ) {
    notice_dialog_delete_notice_dialog_admin(
      notice_dialog_data: $notice_dialog_data
    )
  }
`;

export const insert_dev_order_transaction_admin = gql`
  mutation insert_dev_order_transaction_admin(
    $item_option: InputItemOption!
    $user_idx: Int!
  ) {
    insert_dev_order_transaction_admin(
      item_option: $item_option
      user_idx: $user_idx
    )
  }
`;

export const insert_item_review_admin = gql`
  mutation insert_item_review_admin(
    $data: InputPost!
    $post_resource_list: [InputPostResource!]!
    $order_item_idx: Int!
  ) {
    insert_item_review_admin(
      data: $data
      post_resource_list: $post_resource_list
      order_item_idx: $order_item_idx
    ) {
      post_idx
    }
  }
`;

export const coupon_select_coupon_rule_list_admin = gql`
  query coupon_select_coupon_rule_list_admin($state: State!) {
    coupon_select_coupon_rule_list_admin(state: $state) {
      count
      rows {
        coupon_rule_idx
        coupon_rule_group_idx
        title
        description
        type
        limit_count
        amount
        min_order_amount
        use_ref_table
        use_ref_idx_string
        valid_day
        expire_datetime
        is_open
        code
        update_user_idx
        start_datetime
        end_datetime
        regist_datetime
      }
    }
  }
`;

export const coupon_select_coupon_rule_admin = gql`
  query coupon_select_coupon_rule_admin($coupon_rule_idx: Int!) {
    coupon_select_coupon_rule_admin(
      coupon_rule_idx: $coupon_rule_idx
    ) {
      coupon_rule_idx
      coupon_rule_group_idx
      title
      description
      type
      limit_count
      amount
      min_order_amount
      use_ref_table
      use_ref_idx_string
      valid_day
      expire_datetime
      is_open
      code
      update_user_idx
      start_datetime
      end_datetime
      regist_datetime
    }
  }
`;

export const coupon_insert_coupon_rule_admin = gql`
  mutation coupon_insert_coupon_rule_admin(
    $coupon_rule_data: InputCouponRule!
  ) {
    coupon_insert_coupon_rule_admin(
      coupon_rule_data: $coupon_rule_data
    ) {
      coupon_rule_idx
      coupon_rule_group_idx
      title
      description
      type
      limit_count
      amount
      min_order_amount
      use_ref_table
      use_ref_idx_string
      valid_day
      expire_datetime
      is_open
      code
      update_user_idx
      start_datetime
      end_datetime
      regist_datetime
      update_datetime
    }
  }
`;

export const coupon_update_coupon_rule_admin = gql`
  mutation coupon_update_coupon_rule_admin(
    $coupon_rule_data: InputCouponRule!
  ) {
    coupon_update_coupon_rule_admin(
      coupon_rule_data: $coupon_rule_data
    ) {
      coupon_rule_idx
      coupon_rule_group_idx
      title
      description
      type
      limit_count
      amount
      min_order_amount
      use_ref_table
      use_ref_idx_string
      valid_day
      expire_datetime
      is_open
      code
      update_user_idx
      start_datetime
      end_datetime
      regist_datetime
      update_datetime
    }
  }
`;



export const coupon_select_coupon_rule_group_list_admin = gql`
  query coupon_select_coupon_rule_group_list_admin($state: State!) {
    coupon_select_coupon_rule_group_list_admin(state: $state) {
      count
      rows {
        coupon_rule_group_idx
        title
        description
        ref_table
        ref_table_idx
        coupon_rule {
          coupon_rule_idx
          coupon_rule_group_idx
          title
          description
          type
          limit_count
          amount
          min_order_amount
          use_ref_table
          use_ref_idx_string
          valid_day
          expire_datetime
          is_open
          code
          update_user_idx
          start_datetime
          end_datetime
          regist_datetime
          update_datetime
        }
        update_user_idx
        regist_datetime
      }
    }
  }
`;

export const coupon_select_coupon_rule_group_admin = gql`
  query coupon_select_coupon_rule_group_admin(
    $coupon_rule_group_idx: Int!
  ) {
    coupon_select_coupon_rule_group_admin(
      coupon_rule_group_idx: $coupon_rule_group_idx
    ) {
      coupon_rule_group_idx
      title
      description
      ref_table
      ref_table_idx
      coupon_rule {
        coupon_rule_idx
        coupon_rule_group_idx
        title
        description
        type
        limit_count
        amount
        min_order_amount
        use_ref_table
        use_ref_idx_string
        valid_day
        expire_datetime
        is_open
        code
        update_user_idx
        start_datetime
        end_datetime
        regist_datetime
        update_datetime
      }
      update_user_idx
      regist_datetime
    }
  }
`;

export const coupon_insert_coupon_rule_group_admin = gql`
  mutation coupon_insert_coupon_rule_group_admin(
    $coupon_rule_group_data: InputCouponRuleGroup!
  ) {
    coupon_insert_coupon_rule_group_admin(
      coupon_rule_group_data: $coupon_rule_group_data
    ) {
      coupon_rule_group_idx
      title
      description
      ref_table
      ref_table_idx
      update_user_idx
    }
  }
`;

export const coupon_update_coupon_rule_group_admin = gql`
  mutation coupon_update_coupon_rule_group_admin(
    $coupon_rule_group_data: InputCouponRuleGroup!
  ) {
    coupon_update_coupon_rule_group_admin(
      coupon_rule_group_data: $coupon_rule_group_data
    ) {
      coupon_rule_group_idx
      title
      description
      ref_table
      ref_table_idx
      update_user_idx
      regist_datetime
      update_datetime
    }
  }
`;

export const coupon_select_coupon_by_user_idx_admin = gql`
  query coupon_select_coupon_by_user_idx_admin(
    $state: State!
    $user_idx: Int!
  ) {
    coupon_select_coupon_by_user_idx_admin(
      state: $state
      user_idx: $user_idx
    ) {
      count
      rows {
        coupon_idx
        user_idx
        coupon_rule_idx
        coupon_rule {
          coupon_rule_idx
          coupon_rule_group_idx
          title
          description
          type
          limit_count
          amount
          min_order_amount
          use_ref_table
          use_ref_idx_string
          valid_day
          expire_datetime
          is_open
          code
          update_user_idx
          start_datetime
          end_datetime
          regist_datetime
          update_datetime
        }
        order_transaction_idx
        code
        status
        is_read
        use_datetime
        expire_datetime
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const company_insert_company_worker = gql`
  mutation company_insert_company_worker($data: InputCompanyWorker!) {
    company_insert_company_worker(data: $data) {
      company_worker_idx
      company_idx
      worker_type
      worker_name
      worker_phone
      worker_email
      regist_datetime
      update_datetime
    }
  }
`;

export const company_update_company_worker = gql`
  mutation company_update_company_worker($data: InputCompanyWorker!) {
    company_update_company_worker(data: $data) {
      company_worker_idx
      company_idx
      worker_type
      worker_name
      worker_phone
      worker_email
      regist_datetime
      update_datetime
    }
  }
`;

export const company_delete_company_worker = gql`
  mutation company_delete_company_worker($company_worker_idx: Int!) {
    company_delete_company_worker(
      company_worker_idx: $company_worker_idx
    )
  }
`;

export const shop_update_category_idx_from_category_list_admin = gql`
  mutation shop_update_category_idx_from_category_list_admin(
    $data: [InputItem!]!
  ) {
    shop_update_category_idx_from_category_list_admin(data: $data)
  }
`;

export const delivery_update_order_item_list_admin = gql`
  mutation delivery_update_order_item_list_admin(
    $data: [InputOrderItem!]!
    $delivery_status: String!
  ) {
    delivery_update_order_item_list_admin(
      data: $data
      delivery_status: $delivery_status
    )
  }
`;

export const get_brand_list = gql`
  query get_brand_list($state: State!) {
    get_brand_list(state: $state) {
      count
      rows {
        brand_idx
        name
        description
        thumbnail_url
        is_open
        display_order
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const get_brand = gql`
  query get_brand($brand_idx: Int!) {
    get_brand(brand_idx: $brand_idx) {
      brand_idx
      name
      description
      thumbnail_url
      is_open
      display_order
      regist_datetime
      update_datetime
    }
  }
`;

export const insert_brand_admin = gql`
  mutation insert_brand_admin($data: InputBrand!) {
    insert_brand_admin(data: $data) {
      brand_idx
      name
      description
      thumbnail_url
      is_open
      display_order
    }
  }
`;

export const update_brand_admin = gql`
  mutation update_brand_admin($data: InputBrand!) {
    update_brand_admin(data: $data) {
      brand_idx
      name
      description
      thumbnail_url
      is_open
      display_order
      regist_datetime
      update_datetime
    }
  }
`;

export const select_store = gql`
  query select_store($store_idx: Int!) {
    select_store(store_idx: $store_idx) {
      store_idx
      user_idx
      name
      title
      size
      type
      is_open
      description
      thumbnail_url
      bookmark_count
      update_user_idx
      is_open
      display_order
      regist_datetime
      update_datetime
      user {
        user_name
        user_img_url
      }
      update_user {
        user_name
      }
      tag_list
    }
  }
`;

export const select_store_list_admin = gql`
  query select_store_list_admin($state: State!) {
    select_store_list_admin(state: $state) {
      count
      rows {
        store_idx
        user_idx
        name
        type
        title
        size
        is_open
        display_order
        description
        thumbnail_url
        bookmark_count
        update_user_idx
        user {
          user_name
        }
      }
    }
  }
`;

export const select_store_list_for_search = gql`
  query select_store_list_admin($state: State!) {
    select_store_list_admin(state: $state) {
      count
      rows {
        store_idx
        name
      }
    }
  }
`;

export const upsert_store_admin = gql`
  mutation upsert_store_admin($data: InputStore!) {
    upsert_store_admin(data: $data) {
      store_idx
      user_idx
      name
      type
      size
      title
      description
      thumbnail_url
      bookmark_count
      update_user_idx
    }
  }
`;

export const search_store_list_admin = gql`
  query search_store_list_admin($state: State!) {
    search_store_list_admin(state: $state) {
      count
      rows {
        store_idx
        user_idx
        name
        type
        title
        size
        description
        thumbnail_url
        bookmark_count
        update_user_idx
        user {
          user_name
        }
      }
    }
  }
`;

export const coupon_use_coupon_admin = gql`
  mutation coupon_use_coupon_admin($coupon: InputCoupon!) {
    coupon_use_coupon_admin(coupon: $coupon) {
      coupon_idx
      user_idx
      coupon_rule_idx
      order_transaction_idx
      code
      status
      is_read
      use_datetime
      expire_datetime
      regist_datetime
      update_datetime
    }
  }
`;

export const coupon_refund_coupon_admin = gql`
  mutation coupon_refund_coupon_admin($coupon: InputCoupon!) {
    coupon_refund_coupon_admin(coupon: $coupon)
  }
`;

export const select_admin_log_list = gql`
  query select_admin_log_list($state: State!) {
    select_admin_log_list(state: $state) {
      count
      rows {
        admin_log_idx
        ref_table
        ref_table_idx
        user_idx
        message
        regist_datetime
        update_user {
          user_name
        }
      }
    }
  }
`;


export const select_chat_join_admin = gql`
  query select_chat_join_admin($state: State!) {
    select_chat_join_admin(state: $state) {
      count
      rows {
        ...chatJoinWithRoomFragment
      }
    }
  }
  ${chatJoinWithRoomFragment}
`;


export const select_chat_message = gql`
  query select_chat_message($chat_room_idx: Int!, $state: State!) {
    select_chat_message(
      chat_room_idx: $chat_room_idx
      state: $state
    ) {
      count
      rows {
        chat_message_idx
        chat_room_idx
        user_idx
        message_type
        message
        message_unread_count
        regist_datetime
        update_datetime
        user {
          user_idx
          user_name
          user_img_url
        }
      }
    }
  }
`;

export const insert_chat_message = gql`
  mutation insert_chat_message($data: InputChatMessage!) {
    insert_chat_message(data: $data) {
      chat_message_idx
    }
  }
`;

export const insert_chat_join_heart_beat_admin = gql`
  mutation insert_chat_join_heart_beat_admin($data: InputChatJoinHeartBeat!) {
    insert_chat_join_heart_beat_admin(data: $data) {
      chat_room_idx
    }
  }
`;

export const select_review_list_admin = gql`
query select_review_list_admin($state: State!) {
  select_review_list_admin(
    state: $state
  ) {
    count
    rows {
      review_idx
      user_idx
      order_item_idx
      post_idx
      item_idx
      rate
      review_type
      regist_datetime
      update_datetime
      user {
        user_name
      }
      post {
        title
        description
      }
      order_item {
        order_item_title
      }
    }
  }
}
`;

export const update_order_item_payment_status_admin = gql`
  mutation update_order_item_payment_status_admin($order_item_idx: Int!, $status: String!) {
    update_order_item_payment_status_admin(
      order_item_idx: $order_item_idx,
      status: $status      
    ) {
        order_item_idx
        item_idx
        order_item_title
        order_count
        sell_price
        buy_price
        external_plus_price
        user_pay_price
        admin_plus_price
        order_item_payment_status
        order_item_delivery_status
        invoice_num
        invoice_delivery_company_name
        item {
          title
          title_internal
        }
        item_option {
          item_option_name
          item_option_code
          item_option_barcode
        }
    }
  }
`
;

export const get_chat_token = gql`
  mutation get_chat_token {
    get_chat_token
  }
`;

export const insert_chat_room = gql`
  mutation insert_chat_room($data: InputChatRoom!) {
    insert_chat_room(data: $data) {
      chat_room_key
    }
  }
`;


export const after_insert_chat_message = gql`
  mutation after_insert_chat_message($data: InputChatRoom!) {
    after_insert_chat_message(data: $data) {
      chat_room_key
    }
  }
`;



export const insert_onelink = gql`
  mutation insert_onelink($data: InputOnelink!) {
    insert_onelink(data: $data) {
      onelink_idx
      onelink_template_id
      landing_type
      app_open_pid
      app_open_campaign
      app_open_adset
      deep_link_value
      is_retargeting
      onelink_url
      short_link
      push_web_url
      push_app_url
      memo
      regist_datetime
      update_datetime
    }
  }
`;


export const insert_onelink_group = gql`
  mutation insert_onelink_group($data: InputOnelinkGroup!) {
    insert_onelink_group(data: $data) {
      onelink_group_idx
      title
      description
      create_user_idx
      update_user_idx
      marketing_fee
      app_install_count
      order_item_count
      app_open_count
      send_count
      regist_datetime
      update_datetime
      tag
      app_open_pid
      app_open_campaign
    }
  }
`;


export const update_onelink_group_only_meta = gql`
  mutation update_onelink_group_only_meta($data: InputOnelinkGroup!) {
    update_onelink_group_only_meta(data: $data) {
      onelink_group_idx
      title
      description
      create_user_idx
      update_user_idx
      marketing_fee
      app_install_count
      order_item_count
      app_open_count
      send_count
      regist_datetime
      update_datetime
      tag
      app_open_pid
      app_open_campaign
    }
  }
`;









export const admin_delete_user = gql`
  mutation admin_delete_user($user_idx: Int!) {
    admin_delete_user(user_idx: $user_idx)
  }
`;

export const admin_copy_item = gql`
  mutation admin_copy_item($item_idx: Int!) {
    admin_copy_item(item_idx: $item_idx){
      item_idx
    }
  }
`;




export const update_onelink = gql`
  mutation update_onelink($data: InputOnelink!) {
    update_onelink(data: $data) {
      onelink_idx
      onelink_template_id
      landing_type
      app_open_pid
      app_open_campaign
      app_open_adset
      deep_link_value
      is_retargeting
      onelink_url
      short_link
      push_web_url
      push_app_url
      memo
      regist_datetime
      update_datetime
    }
  }
`;

export const update_onelink_only_meta = gql`
  mutation update_onelink_only_meta($data: InputOnelink!) {
    update_onelink_only_meta(data: $data) {
      onelink_idx
      onelink_template_id
      landing_type
      app_open_pid
      app_open_campaign
      app_open_adset
      deep_link_value
      is_retargeting
      onelink_url
      short_link
      push_web_url
      push_app_url
      memo
      regist_datetime
      update_datetime
    }
  }
`;



export const delete_onelink = gql`
  mutation delete_onelink($data: InputOnelink!) {
    delete_onelink(data: $data)
  }
`;





export const create_shotlink = gql`
  mutation create_shotlink($url: String!) {
    create_shotlink(url: $url)
  }
`;


export const insert_que = gql`
  mutation insert_que($que_name: String!) {
    insert_que(que_name: $que_name)
  }
`;

export const item_image_migration = gql`
  mutation item_image_migration($item_idx: Int!) {
    item_image_migration(item_idx: $item_idx)
  }
`;








export const select_bigquery_analytics = gql`
  query select_bigquery_analytics($state: State!) {
    select_bigquery_analytics(state: $state) {
      count
      rows {
        bigquery_analytics_idx
        target_key
        target_unique_key
        target_date
        impression_count
        view_count
        cart_count
        click_count
        complete_count
        user_count
        item_idx
        shop_section_idx
        banner_idx
        notice_dialog_idx
        regist_datetime
        update_datetime
        click_ratio
        order_ratio
        item {
          item_idx
          title
          item_sell_price
          item_image_url
        }
        shop_section {
          shop_section_idx
          shop_section_title
          is_open
        }
        banner {
          banner_idx
          banner_title
          banner_location
          banner_link_type
          banner_img_url
          banner_link_url
        }
        notice_dialog {
          notice_dialog_idx
          title
          img_url
          link_url
          button_text
        }
      }
    }
  }
`;


export const select_onelink_analytics = gql`
  query select_onelink_analytics($state: State!) {
    select_onelink_analytics(state: $state) {
      count
      rows {
        onelink_analytics_idx
        target_date
        target_key
        onelink_idx
        user_count
        total_sell_count
        total_sell_price
        total_buy_price
        regist_datetime
        update_datetime
        onelink {
          onelink_idx
          onelink_group_idx
          onelink_template_id
          landing_type
          app_open_pid
          app_open_campaign
          app_open_adset
          deep_link_value
          is_retargeting
          onelink_url
          short_link
          push_web_url
          push_app_url
          memo
          regist_datetime
          update_datetime
          create_user_idx
          app_install_count
          app_open_count
          order_item_count
          order_item_amount
          order_conversion_rate
          order_roas
          is_company_link
          onelink_tag
          marketing_fee
          marketing_page_link
          send_count
        }
      }
    }
  }
`;




export const select_user_link_list = gql`
  query select_user_link_list($state: State!) {
    select_user_link_list(state: $state) {
      count
      rows {
        user_link_idx
        from_user_idx
        to_user_idx
        link_force
        link_type
        memo
        regist_datetime
        update_datetime
        effect_count
        from_user {
          user_idx
          user_name
          user_img_url
        }
        to_user {
          user_idx
          user_name
          user_img_url
        }
      }
    }
  }
`;



export const select_bank_account_log_list = gql`
  query select_bank_account_log_list($state: State!) {
    select_bank_account_log_list(state: $state) {
      count
      rows {
        bank_account_log_idx
        bank_name
        account_number
        trans_ref_key
        trans_datetime
        withdraw
        deposit
        trans_remark
        balance
        regist_datetime
        update_datetime
        is_fee
        is_checked
      }
    }
  }
`;
export const select_bank_transfer_list = gql`
  query select_bank_transfer_list($state: State!) {
    select_bank_transfer_list(state: $state) {
      count
      rows {
        ...bankTransferFragment
      }
    }
  }
  ${bankTransferFragment}
`;




export const select_class_room_incentive_list = gql`
  query select_class_room_incentive_list($state: State!) {
    select_class_room_incentive_list(state: $state) {
      count
      rows {
        class_room_incentive_idx
        class_room_idx
        user_idx
        target_month
        total_incentive_price
        status
        bank_transfer_datetime
        bank_name
        bank_account
        bank_owner
        bank_code
        memo
        regist_datetime
        update_datetime
      }
    }
  }
`;



export const select_class_room_point_list = gql`
  query select_class_room_point_list($state: State!) {
    select_class_room_point_list(state: $state) {
      count 
      rows {
        ...classRoomPointFragment
      }
    }
  }
  ${classRoomPointFragment}
`;

export const select_class_room_list = gql`
  query select_class_room_list($state: State!) {
    select_class_room_list(state: $state) {
      count
      rows {
        ...classRoomFragment
      }
      
    }
  }
  ${classRoomFragment}
`;


export const select_order_cancel_list = gql`
  query select_order_cancel_list($state: State!) {
    select_order_cancel_list(state: $state) {
      count
      rows {
        ...orderCancelFragment
        order_transaction {
          ...orderTransactionFragment
        }
        order_cancel_item_list {
          order_item {
            ...orderItemFragment
          }
        }
      }
    }
  }
  ${orderCancelFragment}
  ${orderTransactionFragment}
  ${orderItemFragment}
  
`;



