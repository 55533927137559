import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgencyGuard } from './guards/agency.guard';
import { ManagerGuard } from './guards/manager.guard';
import { ReviewerGuard } from './guards/reviewer.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'main',
  },
  {
    path: 'public',
    loadChildren: () =>
      import('./pages/public/public.module').then(
        (m) => m.PublicModule,
      ),
  },
  {
    path: 'main',
    canActivate: [AgencyGuard],
    loadChildren: () =>
      import('./pages/main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'contest',
    canActivate: [ReviewerGuard],
    loadChildren: () =>
      import('./pages/contest/contest.module').then(
        (m) => m.ContestModule,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
