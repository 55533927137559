import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Apollo, QueryRef } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import { tap } from 'rxjs/operators/tap';
import { map } from 'rxjs/operators/map';
import {
    State,
    toDataSourceRequestString,
    translateDataSourceResultGroups,
    SortDescriptor,
} from '@progress/kendo-data-query';

import { User, InputUser, InputOrderTransaction, SalesChannel, Item, ItemOption, ItemSalesChannel, InputOrder } from 'src/app/services/graphql/models';
import { GridDataResult } from '@progress/kendo-angular-grid';
import gql from 'graphql-tag';
import { allOrderTransactions, get_order_transaction, allOrderItems, select_sales_channel_list, search_user_list, search_item_list, insert_admin_order_transaction, select_order_transaction_list, delete_order_transaction, request_excel_download, select_all_post_list } from '../queries';





@Injectable()
export class PostGraphqlService extends BehaviorSubject<GridDataResult> {


    public state: State;
    public loading: boolean;


    constructor(private apollo: Apollo) {
        super(null);
    }

    private query: QueryRef<any>;
    private watchQuery;

    public allOrderTransactions(state: State) {
        this.state = state;

        if (!state.sort || state.sort.length == 0) {
            console.log('no sort');
            const sort: SortDescriptor = {
                field: 'order_transaction_idx',
                dir: 'desc'
            }
            state.sort = [sort];
        }
        console.log('will query state111 =', state);
        this.loading = true;
        if (this.query) {
            this.query.refetch({ state: this.state })
            return;
        }

        this.query = this.apollo.watchQuery({
            query: allOrderTransactions,
            variables: { state: this.state }
        });
        this.watchQuery = this.query.valueChanges.pipe(
            map((changes: any) => <GridDataResult>{ data: changes.data.allOrderTransactions.rows, total: changes.data.allOrderTransactions.count }),
            tap(() => this.loading = false)
        );

        this.watchQuery.subscribe(data => super.next(data));
    }







    public allOrderItems(state: State) {
        this.state = state;
        this.loading = true;
        if (this.query) {
            this.query.refetch({ state: this.state })
            return;
        }




        this.query = this.apollo.watchQuery({
            query: allOrderItems,
            variables: { state: this.state }
        });
        this.watchQuery = this.query.valueChanges.pipe(
            map((changes: any) => <GridDataResult>{ data: changes.data.allOrderItems.rows, total: changes.data.allOrderItems.count }),
            tap(() => this.loading = false)
        );
        this.watchQuery.subscribe(data => super.next(data));
    }


    public select_all_post_list(state: State) {
        this.state = state;
        this.loading = true;
        if (this.query) {
            this.query.refetch({ state: this.state })
            return;
        }

        this.query = this.apollo.watchQuery({
            query: select_all_post_list,
            variables: { state: this.state }
        });
        this.watchQuery = this.query.valueChanges.pipe(
            map((changes: any) => <GridDataResult>{ data: changes.data.select_all_post_list.rows, total: changes.data.select_all_post_list.count }),
            tap(() => this.loading = false)
        );
        this.watchQuery.subscribe(data => super.next(data));
    }






    public async queryAllOrderTransactions(state: State) {

        state.skip = 0;
        state.take = -1;
        const result: any = await this.apollo.query({
            query: allOrderTransactions,
            variables: { state: state }
        }).toPromise();
        result.data.allOrderTransactions.rows.forEach(item => {
            item.order_item_code_list = '';
            item.order_item_option_name_list = '';

            item.order_item_list.forEach((order_item, i) => {
                if (i > 0) {
                    item.order_item_code_list += ',';
                    item.order_item_option_name_list += ',';
                }
                item.order_item_code_list += order_item.item_option.item_option_code;
                item.order_item_option_name_list += order_item.item_option.item_option_name;
            });
        });
        return { data: result.data.allOrderTransactions.rows, total: result.data.allOrderTransactions.count };
    }


    public async queryAllOrderItems(state: State) {
        const result: any = await this.apollo.query({
            query: allOrderItems,
            variables: { state: state }
        }).toPromise();
        return { data: result.data.allOrderItems.rows, total: result.data.allOrderItems.count };
    }





    public select_order_transaction_list(state: State) {
        this.state = state;

        if (this.query) {
            this.query.refetch({ state: this.state })
            return;
        }

        this.query = this.apollo.watchQuery({
            query: select_order_transaction_list,
            variables: { state: this.state }
        });
        this.query
            .valueChanges
            .pipe(
                map((changes: any) => <GridDataResult>{ data: changes.data.select_order_transaction_list.rows, total: changes.data.select_order_transaction_list.count }),
            )
            .subscribe(data => super.next(data));
    }

    public release() {
        if (this.query && this.query.valueChanges) {
            this.query.valueChanges.subscribe().unsubscribe();
        }
    }

    public async delete_order_transaction(order_transaction_idx: number) {
        const result = await this.apollo.mutate({
            mutation: delete_order_transaction,
            variables: { order_transaction_idx },
            refetchQueries: [{
                query: select_order_transaction_list,
                variables: { state: this.state }
            }]
        }).toPromise();
        return result;
    }



    public async request_excel_download(state: State) {
        const result: any = await this.apollo.mutate({
            mutation: request_excel_download,
            variables: { state }
        }).toPromise();
        return result.data.request_excel_download;
    }

}
