import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink, makeVar } from '@apollo/client/core';
import { NgModule } from '@angular/core';

import { createUploadLink } from 'apollo-upload-client';

import { environment } from '../environments/environment';

import { HttpClientModule } from '@angular/common/http';

const uri = environment.api_server_url + '/graphql'; // <-- add the URL of the GraphQL server here

export function createApollo(httpLink: HttpLink) {
  const uploadLink = new createUploadLink({ uri: uri });
  const link = ApolloLink.split((operation) => operation.getContext().hasUpload, uploadLink, httpLink.create({ uri }));

  return {
    link: link,
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPloicy: 'all'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPloicy: 'all'
      }
    }
  };
}

@NgModule({
  exports: [HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink]
    }
  ]
})
export class GraphQLModule {}
