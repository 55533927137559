import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Apollo, QueryRef } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import { tap } from 'rxjs/operators/tap';
import { map } from 'rxjs/operators/map';
import { State, toDataSourceRequestString, translateDataSourceResultGroups } from '@progress/kendo-data-query';

import { User, InputCompany, Company, InputCompanyWorker } from 'src/app/services/graphql/models';
import { GridDataResult } from '@progress/kendo-angular-grid';
import gql from 'graphql-tag';
import {
  select_company_list,
  add_company,
  update_company,
  delete_company,
  company_insert_company_worker,
  select_company,
  company_update_company_worker,
  company_delete_company_worker
} from '../queries';

@Injectable()
export class CompanyGraphqlService extends BehaviorSubject<GridDataResult> {
  state: State;
  company_idx: number;

  constructor(private apollo: Apollo) {
    super(null);
  }

  private query: QueryRef<any>;

  public async select_company(company_idx: number): Promise<Company> {
    this.company_idx = company_idx;
    const result: any = await this.apollo
      .query({
        query: select_company,
        variables: { company_idx }
      })
      .toPromise();
    return result.data.select_company;
  }

  public select_company_list(state: State) {
    this.state = state;

    if (this.query) {
      this.query.refetch({ state: this.state });
      return;
    }

    this.query = this.apollo.watchQuery({
      query: select_company_list,
      variables: { state: this.state }
    });
    this.query.valueChanges
      .pipe(
        map(
          (changes: any) =>
            <GridDataResult>{
              data: changes.data.select_company_list.rows,
              total: changes.data.select_company_list.count
            }
        )
      )
      .subscribe((data) => super.next(data));
  }

  public release() {
    if (this.query && this.query.valueChanges) {
      this.query.valueChanges.subscribe().unsubscribe();
    }
  }

  public async add_company(data: InputCompany): Promise<Company> {
    const tempDataItem = JSON.parse(JSON.stringify(data));
    delete tempDataItem['company_group'];

    const result: any = await this.apollo
      .mutate({
        mutation: add_company,
        variables: { data: tempDataItem }
        // refetchQueries: [
        //   {
        //     query: select_company_list,
        //     variables: { state: this.state },
        //   },
        // ],
      })
      .toPromise();
    return result.data.add_company;
  }

  public async update_company(data: InputCompany): Promise<Company> {
    const tempDataItem = JSON.parse(JSON.stringify(data));
    delete tempDataItem['company_group'];

    const result: any = await this.apollo
      .mutate({
        mutation: update_company,
        variables: {
          data: tempDataItem
        }
        // refetchQueries: [
        //   {
        //     query: select_company_list,
        //     variables: { state: this.state },
        //   },
        // ],
      })
      .toPromise();
    return result.data.update_company;
  }

  public async delete_company(company_idx: number) {
    const result = await this.apollo
      .mutate({
        mutation: delete_company,
        variables: { company_idx },
        refetchQueries: [
          {
            query: select_company_list,
            variables: { state: this.state }
          }
        ]
      })
      .toPromise();
    return result;
  }

  public async company_insert_company_worker(data: InputCompanyWorker): Promise<Company> {
    const result: any = await this.apollo
      .mutate({
        mutation: company_insert_company_worker,
        variables: { data }
        // refetchQueries: [
        //   {
        //     query: select_company,
        //     variables: { company_idx: this.company_idx },
        //   },
        // ],
      })
      .toPromise();
    return result.data.company_insert_company_worker;
  }

  public async company_update_company_worker(data: InputCompanyWorker): Promise<Company> {
    const result: any = await this.apollo
      .mutate({
        mutation: company_update_company_worker,
        variables: { data },
        refetchQueries: [
          {
            query: select_company,
            variables: { company_idx: this.company_idx }
          }
        ]
      })
      .toPromise();
    return result.data.company_update_company_worker;
  }

  public async company_delete_company_worker(company_worker_idx: number): Promise<Company> {
    const result: any = await this.apollo
      .mutate({
        mutation: company_delete_company_worker,
        variables: { company_worker_idx },
        refetchQueries: [
          {
            query: select_company,
            variables: { company_idx: this.company_idx }
          }
        ]
      })
      .toPromise();
    return result.data.company_delete_company_worker;
  }
}
