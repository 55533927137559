import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {


  user: {
    "user_idx": number,
    "user_object_id": any,
    "login_type": "naver" | "kakao" | "facebook" | "local",
    "user_id": any,
    "user_pw": any,
    "social_id": any,
    access_token?: any,
    social_access_token?: string,
    user_name?: string,
    user_email?: string,
    "user_phone": string,
    "user_birthday"?: any,
    "user_gender"?: any,
    "push_alarm_onoff": number,
    "ph_authen": number,
    "ph_authen_datetime": string,
    "user_img_url": string,
    "login_count": number,
    "last_login_datetime": string,
    "accept_policy_datetime": string,
    "t_user_droped": number,
    "user_droped_datetime"?: any,
    "regist_datetime": string,
    "update_datetime": string,
    "basket_count": number,
    "emoney": number,
    "point": number,
    "push_count": number,
    is_admin: boolean,
    user_title: string,
    user_profile: string,
    banner_img_url: string,
    request_count: number,
    public_request_count: number,
    total_like_count: number,
    role: any,

}


// 휘발성 데이터 
// window localstorage에 저장하기엔 애매하고, 상태값을 컴포넌트, 페이지간 공유하고자하는 데이터들을 모아놓는다.
// 즉 브라우저가 혹은 앱이 꺼지게되면 날아가는 데이터 이다. 
public volatile_datas: any = {
    // list와 cu_group을 지님
    upload_user_submit: null,
    purchase_point: null,
    ready_order: [],
    coupon_upload_temp: [],
    app_flow_update: {
        download_progress: 0,
        extract_progress: 0
    }
}

constructor(

) {

}


public setLocal(key, target) {
    if (target == null) {
        window.localStorage.removeItem(key);
    }
    else {
        window.localStorage.setItem(key, target);
    }

}

public getLocal(key) {
    return window.localStorage.getItem(key);
}
public removeLocal(key) {
    window.localStorage.removeItem(key);
}


public setRedirect(target) {
    console.log('set Redirect =' + target);
    if (target.startsWith('login-callback')) {
        this.setLocal('redirect', '/');
    }
    else if (target == 'login') {
        this.setLocal('redirect', '/');
    }
    else {
        this.setLocal('redirect', target);
    }

}

public removeRedirect() {
    console.log('removeRedirect');
    this.removeLocal('redirect');
}

public getRedirect() {
    return this.getLocal('redirect');
}

}
