import { Injectable } from '@angular/core';
import { CacheService } from './cache.service';
import { RequestService } from './api/request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import {
  payment_type_list,
  payment_type_hash,
  payment_status_hash,
  item_status_list,
  item_status_hash,
  temp_order_status_list,
  temp_order_status_hash,
  delivery_status_hash,
  is_open_list
} from './graphql/models';
import { EventManager } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public channel_code = environment.channel_code;

  public package_app_version = environment.package_app_version;

  public navi_title;

  public page_mode: 'default' | 'slide' | 'search' | 'search-cancel' = 'default';
  public $page_mode: Subject<{
    mode: 'default' | 'slide' | 'search' | 'search-cancel';
    data?: any;
  }> = new Subject();

  public payment_type_list = payment_type_list;
  public payment_type_hash = payment_type_hash;
  public payment_status_hash = payment_status_hash;
  public delivery_status_hash = delivery_status_hash;

  public item_status_list = item_status_list;
  public item_status_hash = item_status_hash;
  public temp_order_status_list = temp_order_status_list;
  public temp_order_status_hash = temp_order_status_hash;

  public is_open_list = is_open_list;
  public category_list = [
    [],
    [
      {
        category_name: '대분류',
        category_idx: null
      }
    ],
    [
      {
        category_name: '중분류',
        category_idx: null
      }
    ],
    [
      {
        category_name: '소분류',
        category_idx: null
      }
    ],
    [
      {
        category_name: '세분류',
        category_idx: null
      }
    ]
  ];

  //환불 처리 상태
  public refund_confirm_status_hash = {
    '0': '대기',
    '1': '승인완료',
    '2': '환불완료',
    '3': '환불거절',
    '-1': '오류'
  };

  public order_cancel_filed_hash = {
    order_cancel_idx: 'ID',
    user_idx: 'user_idx',
    order_transaction_idx: '주문번호',
    order_item_idx_list: '상세주문번호',
    create_user_idx: '생성자',
    cancel_type: '취소유형',
    request_type: '요청유형',
    total_cancel_price: '총취소금액',
    total_refund_price: '총환불금액',
    cancel_fee: '취소수수료',
    cancel_user_pay_price: '취소실결제금액',
    refund_user_pay_price: '환불실결제금액',
    cancel_point: '취소포인트',
    refund_point: '환불포인트',
    cancel_status: '취소상태',
    change_status: '교환상태',
    refund_status: '환불상태',
    cancel_reason: '취소사유',
    cancel_message: '상세취소사유',
    cancel_result: '취소결과',
    user_pay_fee: '실결제금액',
    user_pay_fee_confirm_status: '결제환불상태',
    user_pay_fee_confirm_datetime: '결제환불시간',
    user_pay_fee_confirm_user_idx: '결제승인유저',
    item_inspect_status: '검수상태',
    item_inspect_datetime: '검수시간',
    item_inspect_user_idx: '검수유저',
    item_return_invoice_num: '회수송장번호',
    item_return_delivery_company_name: '회수업체',
    item_return_invoice_regist_datetime: '회수송장등록시간',
    item_return_request_datetime: '회수요청시간',
    item_return_request_user_idx: '회수요청유저',
    item_change_invoice_num: '교환송장번호',
    item_change_delivery_company_name: '교환배송업체',
    item_change_invoice_regist_datetime: '교환송장등록시간',
    refund_confirm_user_idx: '환불승인유저',
    refund_confirm_status: '환불승인상태',
    refund_confirm_datetime: '환불승인시간',
    refund_payment_type: '환불결제수단',
    refund_payment_error: '환불오류',
    refund_request_code: '환불응답코드',
    refund_request_message: '환불응답메세지',
    refund_request_response: '환불응답',
    refund_request_tid: '환불tid',
    refund_payment_datetime: '환불지불시간',
    send_cancel_message_status: '환불메세지발송상태',
    send_cancel_mssage_error: '환불메세지에러',
    memo: '메모',
    update_datetime: '수정시간',
    regist_datetime: '등록시간'
  };

  public app_version = environment.app_version;

  public plaform;
  public size = {
    max_width: 700,
    width: 700,
    height: 700,
    header_height: 53,
    footer_height: 0,
    content_height: 700,
    grid_height: 660,
    gallery_height: 560,
    pager_height: 48,
    gallery_item_size: 200,
    detail_gallery_image_height: 400,
    scroll_bar_height: 60,
    search_bar_max_height: 96,
    grid_min_height: 400,
    grid_max_height: 800
  };
  public pageable = {
    pageSize: 20,
    buttonCount: 10,
    pageSizes: [10, 20, 50, 100, 200, 300, 500, 1000, 2000, 3000]
  };

  private titleSubject = new BehaviorSubject('푸미');

  constructor(private eventManager: EventManager, private _cache: CacheService, private _request: RequestService, private _router: Router, private _route: ActivatedRoute, private _user: UserService) {
    this.changeWindowSize();
    this.eventManager.addGlobalEventListener('window', 'resize', this.onResize.bind(this));
  }

  getTitleChangeListener(): Observable<any> {
    return this.titleSubject.asObservable();
  }

  setTitle(title: string) {
    this.titleSubject.next(title);
  }

  private onResize(event: UIEvent) {
    this.changeWindowSize();
  }

  public changeWindowSize() {
    this.size.width = window.innerWidth;
    this.size.height = window.innerHeight;

    this.size.content_height = this.size.height - this.size.header_height - this.size.footer_height;
    this.size.grid_height = this.size.content_height - 60;
    this.size.gallery_height = this.size.grid_height - this.size.pager_height + 12;
    this.size.grid_min_height = this.size.content_height - this.size.scroll_bar_height;
    this.size.grid_max_height = this.size.content_height - this.size.scroll_bar_height - this.size.search_bar_max_height;
  }

  get cache() {
    return this._cache;
  }
  get request() {
    return this._request;
  }
  get user() {
    return this._user;
  }

  public getEnv() {
    return environment;
  }

  public go(url) {
    this._router.navigateByUrl(url);
  }

  public async goReplace(url) {
    this._router.navigate([url], { replaceUrl: true });
  }
  public smartGo(url) {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      window.open(url, '_blank');
    } else {
      this.go(url);
    }
  }

  public reorderList(array, indexA, indexB) {
    const tmp = array[indexA];
    array[indexA] = array[indexB];
    array[indexB] = tmp;
    return array;
  }

  public redirectLogin() {
    if (this._router) {
      if (this._router.url) {
        if (this._router.url != '/public/login' && this._router.url != 'public/login') {
          this.cache.setRedirect(this._router.url);
        }
      }
    }
    window.localStorage.removeItem('token');
    this.go('/public/login');
  }

  public setRedirectLogin(url) {
    this.cache.setRedirect(url);
    this.go('/public/login');
  }

  public setAfterLogin(url) {
    this.cache.setLocal('afterLogin', url);
  }

  public popAfterLogin() {
    const result = this.cache.getLocal('afterLogin');
    this.cache.removeLocal('afterLogin');
    return result;
  }

  public async getUserIdx() {
    const temp = await this.user.currentUser();
    if (temp) {
      return temp.user_idx;
    } else {
      return null;
    }
  }

  public getUserEndPoint() {
    return environment.user_endpoint;
  }

  public getTodayString() {
    const renamedKey = moment().format('YYYY') + '_' + moment().format('MM') + '_' + moment().format('DD');
    return renamedKey;
  }

  public toDateFormat(source: Date) {
    if (source) {
      return moment(source).format('YYYY-MM-DD HH:mm:ss');
    }
    return null;
  }

  public goKakaoLogin() {
    const callBackUrl = environment.user_endpoint + '/public/login-callback';
    const state = encodeURIComponent(callBackUrl);
    console.log(' environment.api_server_url = ' + environment.api_server_url);
    const targetUrl = environment.api_server_url + '/auth/kakao?state=' + state;
    console.log('target = ' + targetUrl);
    this.goExternalLocal(targetUrl);
  }

  public goExternalLocal(fullUrl) {
    window.location.replace(fullUrl);
  }

  public goExternal(fullUrl) {
    window.open(fullUrl, '_blank');
  }

  public goNewWindow(url) {
    if (!url.startsWith('/')) {
      url += '/';
    }
    const fullUrl = environment.user_endpoint + url;
    window.open(fullUrl, '_blank');
  }

  public goExternalInside(fullUrl) {
    window.open(fullUrl);
  }

  public goBack() {
    window.history.back();
  }

  base64ToFile(base64Image: string): Blob {
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }

  public filterObjects(targetObject: any, filters: Array<string>) {
    const result = {};
    for (let i = 0; i < filters.length; i++) {
      if (targetObject[filters[i]] == null || targetObject[filters[i]] == undefined) {
        result[filters[i]] = null;
      } else {
        result[filters[i]] = targetObject[filters[i]];
      }
    }
    return result;
  }

  public getPaneSize(portion: number) {
    return this.size.width * portion + 'px';
  }

  public getCurrentDate(): string {
    const date = new Date();
    const fileName = `${date.getFullYear()}${date.getMonth() + 1 < 10 ? 0 : ''}${date.getMonth() + 1}${date.getDate() < 10 ? 0 : ''}${date.getDate()}`;
    return fileName;
  }

  public setLocal(key, target) {
    if (target == null) {
      window.localStorage.removeItem(key);
    } else {
      window.localStorage.setItem(key, target);
    }
  }

  public getLocal(key) {
    return window.localStorage.getItem(key);
  }
  public removeLocal(key) {
    window.localStorage.removeItem(key);
  }
}
